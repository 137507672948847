import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Alert,
  Link,
  Stack,
  TextField,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  List,
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Loading from '../../components/loading';
import { fDate } from '../../utils/formatTime';

import { getReq, patchReq, postReq } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';

export default function EmpSalarySec() {
  const { userId } = useParams();
  const { salaryId } = useParams();
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(true);
  const [salaryData, setSalaryData] = useState({});
  const [salaryHistory, setSalaryHistory] = useState([]);
  const [state, setState] = useState({});

  const getData = async () => {
    if (!salaryId) return;
    setLoading(true);

    // const res = await getReq({ url: `/user/currentsalary/${salaryId}` });
    const res = await getReq({ url: `/user/salary/${userId}` });

    if (!res.error) {
      // console.log(res.data);
      setSalaryHistory(res.data);
      setNoData(false);
    }
    setLoading(false);
  };
  // console.log(leaveDetails.ticketReq);
  useEffect(() => {
    getData();
  }, []);

  const addSalary = async () => {
    setLoading(true);

    const totalSalary =
      Number(state.basic || 0) +
      Number(state.hra || 0) +
      Number(state.tellA || 0) +
      Number(state.travA || 0) +
      Number(state.transportA || 0) +
      Number(state.otherA || 0);

    const data = {
      basic: state.basic,
      hra: state.hra,
      tellA: state.tellA,
      travA: state.travA,
      transportA: state.transportA,
      otherA: state.otherA,
      gross: totalSalary,
      employee: userId,
      startingDate: state.startingDate,
    };

    const res = await postReq({ url: `/user/salary`, data });

    if (!res.error) {
      console.log(res.data);
      setNoData(false);
    }

    setLoading(false);
  };

  const onChange = (e) => {
    console.log(e.target.name, e.target.value);
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const CurrentSalary = () => {
    if (noData) return <Typography variant="h6">No Salary Data Found</Typography>;
    return (
      <div className="dashbord-card">
        <h3>Current Salary Details</h3>
        <table>
          <tbody>
            <tr>
              <td>Basic Salary</td>
              <td>{salaryData?.basic}</td>
            </tr>
            <tr>
              <td>HRA.</td>
              <td>{salaryData?.hra}</td>
            </tr>
            <tr>
              <td>Tell A.</td>
              <td>{salaryData?.tellA}</td>
            </tr>
            <tr>
              <td>Travel A.</td>
              <td>{salaryData?.travA}</td>
            </tr>
            <tr>
              <td>Transport A.</td>
              <td>{salaryData?.transportA}</td>
            </tr>
            <tr>
              <td>Other A.</td>
              <td>{salaryData?.otherA}</td>
            </tr>
            <tr>
              <td>Gross</td>
              <td>{salaryData?.gross}</td>
            </tr>
            <tr>
              <td>From</td>
              <td>{salaryData?.startingDate ? fDate(salaryData?.startingDate) : fDate(Date())}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const grossSalary =
    Number(state.basic) +
    Number(state.hra) +
    Number(state.tellA) +
    Number(state.travA) +
    Number(state.transportA) +
    Number(state.otherA);

  const AddSalary = () => (
    <div className="dashbord-card">
      <Typography variant="h4" marginBottom={2}>
        Add Salary Details
      </Typography>
      <Alert severity="info">
        <p>Salary appraisal / Increment / Bonus / Deduction / New Salary Details can be added here.</p>
      </Alert>
      <Alert severity="warning">
        <p>Note: Its Not Salary edit option. If you want to edit salary details, please contact technical team.</p>
      </Alert>
      <div>
        <div>
          <Stack spacing={3} marginTop={5}>
            <TextField type="number" fullWidth label="Basic Salary" name="basic" onChange={onChange} />
            <TextField type="number" fullWidth label="HRA" name="hra" onChange={onChange} />
            <TextField type="number" fullWidth label="Tell A." name="tellA" onChange={onChange} />
            <TextField type="number" fullWidth label="Travel A." name="travA" onChange={onChange} />
            <TextField type="number" fullWidth label="Transport A." name="transportA" onChange={onChange} />
            <TextField type="number" fullWidth label="Other A." name="otherA" onChange={onChange} />
            Gross Salary: {Number.isNaN(grossSalary) ? 0 : grossSalary}
            <TextField fullWidth label="Effective From" name="startingDate" type="date" onChange={onChange} />{' '}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" mt={5}>
            <LoadingButton variant="contained" type="submit" color="primary" loading={loading} onClick={addSalary}>
              Add Salary Details
            </LoadingButton>
          </Stack>
        </div>
      </div>
    </div>
  );

  const SalaryHistorySec = () => (
    <div className="dashbord-card">
      <Typography variant="h4" marginBottom={2}>
        Salary History
      </Typography>

      <div>
        {salaryHistory.map((item, index) => (
          <div key={index} className="history-div">
            <h4>Effective From: {item.startingDate ? fDate(item.startingDate) : fDate(Date())}</h4>
            <table>
              <tbody>
                <tr>
                  <td>Basic Salary</td>
                  <td>{item.basic}</td>
                </tr>
                <tr>
                  <td>HRA.</td>
                  <td>{item.hra}</td>
                </tr>
                <tr>
                  <td>Tell A.</td>
                  <td>{item.tellA}</td>
                </tr>
                <tr>
                  <td>Travel A.</td>
                  <td>{item.travA}</td>
                </tr>
                <tr>
                  <td>Transport A.</td>
                  <td>{item.transportA}</td>
                </tr>
                <tr>
                  <td>Other A.</td>
                  <td>{item.otherA}</td>
                </tr>
                <tr>
                  <td>Gross</td>
                  <td>{item.gross}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <Page title={`Salary Details`}>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="end" mb={1}>
            <Button
              variant="text"
              component={RouterLink}
              to={`/dashboard/employees/view/${userId}`}
              startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
              color={'primary'}
            >
              Employee Overview
            </Button>
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              {AddSalary()}
            </Grid>
            <Grid item xs={12} md={6}>
              <SalaryHistorySec />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
