// import "./App.css";
import * as React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import {
    Stack,
    Checkbox,
    Typography,
    Container,
    Grid,
    Box,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Card,
    Tabs,
    Tab,
    CardMedia,
    Item,
    Button,
  } from '@mui/material';
  import Page from '../../components/Page';
  import OldRequest from './OldRequest';
  import RaiseRequest from './RaiseRequest';


function ItRequest() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  return (
    <div className="App">
      <h1>Request Overview</h1>
      <div
        style={{
          margin: '16px',
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: '10px',
        }}
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Raise Request" value="1" />
                <Tab label="Old Request" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1"><RaiseRequest /></TabPanel>
            <TabPanel value="2"><OldRequest /></TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>

  );
}

export default ItRequest;
