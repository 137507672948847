import { Link as RouterLink, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import { max } from 'lodash';

import {
  Card,
  Stack,
  Avatar,
  Button,
  Container,
  Typography,
  Grid,
  Item,
  Box,
  Link,
  Divider,
  CardHeader,
} from '@mui/material';

import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';
import Loading from '../../components/loading';

import { getReq } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';

export default function News() {
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const params = useParams();

  const getData = async () => {
    setLoading(true);

    const res = await getReq({ url: `/news/news/${params.id}` });
    if (!res.error) {
      setNewsList(res.data);
    }
    console.log(res.data);

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <Page title="News Details">
        <Loading />
      </Page>
    );
  }

  return (
    <Page title="News Details">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
          <Button
            variant="text"
            component={RouterLink}
            to={`/dashboard/announcements`}
            startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
            color={'primary'}
          >
            List News
          </Button>
        </Stack>

        <Grid container>
          <Grid item xs={12}>
            <Card sx={{ paddingRight: 5, paddingLeft: 5 }}>
              <Stack spacing={2}>
                <Grid container spacing={2} m={2}>
                  <Grid item xs={12}>
                    <Typography variant="h3" gutterTop mb={5}>
                      {newsList.title}
                    </Typography>
                    <div
                      className="user-img1"
                      style={{
                        backgroundImage: `url(${API_URL.img_url}/api/uploads/images/${newsList?.image?.ogUrl})`,
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} mt={5} textAlign={'start'}>
                    <p className="news-desc" dangerouslySetInnerHTML={{ __html: newsList.description }} />
                    {/* <div dangerouslySetInnerHTML={{__html: newsList.description}}/> */}
                  </Grid>
                </Grid>
              </Stack>

              <Grid item xs={12}>
                {newsList?.company?.companyName ? (
                  <>
                    <Grid item xs={12} sx={{ backgroundColor: '#e9f2f7', mt: '2px' }}>
                      <Typography sx={{ textAlign:'center', fontSize: '15px',padding:'6px' }} gutterTop>
                        {newsList?.company?.companyName}
                     <br/>
                        {newsList?.category}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
