import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TextField,
  Pagination,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getReq } from '../../data/ApiReq';
import Loading from '../../components/loading';

export default function LeaveRequests() {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialState = {
    page: 1,
    limit: 20,
    totalCount: 0,
    totalPages: 0,
    hasNextPage: false,
    searchText: '',
    sort: '',
    qEdit: false,
  };

  const [leaveList, setLeaveList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(initialState);
  const [filterState, setFilterState] = useState({});

  const getData = async (pg, lm, isSearch, filter) => {
    const userId = searchParams.get('user');

    setLoading(true);

    const p = pg || state.page;
    const l = lm || state.limit;

    let query = `page=${p}&limit=${l}`;
    if (isSearch) {
      query = `page=${p}&limit=${l}&searchText=${isSearch}`;
      if (filter) {
        query += `&${filter}`;
      }
      setFilterState({ ...filterState, searchText: isSearch });
    }

    if (userId) {
      query += `&user=${userId}`;
    }

    if (filter) {
      query = `page=${p}&limit=${l}&${filter}`;
      if (filterState.searchText) {
        query += `&searchText=${filterState.searchText}`;
      }
    }

    const res = await getReq({ url: `/leave?${query}` });
    // console.log(res.data);
    if (!res.error) {
      setLeaveList(res.data);
      setState({
        ...state,
        page: res.pagination.page,
        limit: res.pagination.limit,
        totalCount: res.pagination.totalCount,
        totalPages: res.pagination.totalPages,
        hasNextPage: res.pagination.hasNextPage,
        qEdit: false,
      });
      // console.log(state);
    }
    setLoading(false);
  };

  const changePage = (event, value) => {
    setState({
      ...state,
      page: value,
    });
    getData(value, state.limit, filterState.searchText, filterState.query);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Page title="Leave Request">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Leave Requests
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-around" gap={2}>
            <Button
              endIcon={<Iconify icon="material-symbols:calendar-month" />}
              color="primary"
              size="large"
              variant="outlined"
              component={RouterLink}
              to="/dashboard/attendance/ur-attendance"
            >
              Your Attendance
            </Button>
            <Button
              endIcon={<Iconify icon="material-symbols:add" />}
              color="primary"
              size="large"
              variant="outlined"
              component={RouterLink}
              to="/dashboard/leaves/apply"
            >
              Apply for Leave
            </Button>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
          {state.totalPages > 1 ? <Pagination count={state?.totalPages} color="primary" onChange={changePage} /> : null}
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.</TableCell>
                    <TableCell>Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Type of Leave</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaveList.map((row, index) => (
                    <TableRow
                      hover
                      key={index}
                      component={RouterLink}
                      to={`/dashboard/leaves/single/${row._id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <TableCell>{(state.page - 1) * state.limit + index + 1}</TableCell>
                      <TableCell>{row.userId.employeeId}</TableCell>
                      <TableCell>
                        {row.userId.firstName} {row.userId.lastName}
                      </TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>
                        <div className={`text-${row?.state} text-pile`}>{row?.state?.toUpperCase()}</div>
                      </TableCell>
                      {/* <TableCell>
                        <Button
                          variant="contained"
                          component={RouterLink}
                          to={`/dashboard/leaves/single/${row._id}`}
                          startIcon={<Iconify icon="carbon:view" />}
                          color="warning"
                        >
                          View
                        </Button>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
