import React from 'react';
import { Typography, Container } from '@mui/material';
import { getUserData } from '../../data/userData';
import AdminDashboard from './adminDashboard';
import ManagerDashboard from './managerDashboard';
import EmployeeDashboard from './employeeDashboard';
import FinanceDashboard from './financeDashboard';
import OfficeAdminDashboard from './officeAdminDashboard';
import ITDashboard from './itDashboard';
import HRDashboard from './hrDashboard';
import Page from '../../components/Page';

import '../../assets/dashboard.css';

export default function Dashboard() {
  const getRole = getUserData();
  const role = getRole?.role;
  const firstName = getRole?.firstName;

  const ManageRoleView = () => {
    switch (role) {
      case 'admin':
        return <AdminDashboard />;
      case 'lineManager':
        return <ManagerDashboard />;
      case 'employee':
        return <EmployeeDashboard />;
      case 'finance':
        return <FinanceDashboard />;
      case 'officeAdmin':
        return <OfficeAdminDashboard />;
      case 'it':
        return <ITDashboard />;
      case 'hr':
        return <HRDashboard />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Page title="Dashboard">
        <Typography variant="h3" sx={{ mb: 2 }}>
          Hi {firstName},
        </Typography>
        <Container maxWidth="xl">
          <ManageRoleView />
        </Container>
      </Page>
    </>
  );
}
