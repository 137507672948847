import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Typography, Container, Stack, Button } from '@mui/material';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';

export default function AllAttendance() {
  return (
    <>
      <Page title="Attendance">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              Attendance
            </Typography>
            <Button
              variant="text"
              component={RouterLink}
              to={`/dashboard/leaves?section=ur_attendence`}
              startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
              color={'primary'}
            >
              Your Attendance
            </Button>
          </Stack>
        </Container>
      </Page>
    </>
  );
}
