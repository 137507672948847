// notification context
import { createContext } from 'react';

const notificationData = {
  notification: {
    title: '',
    body: '',
    isClicked: false,
  },
  setNotification: () => {},
};

export const NotificationContext = createContext(notificationData);
