import Cookies from 'js-cookie';
import axios from './axios';
import { getUserToken, cookieConst } from './userData';

const Signin = async ({ employeeId, password }) => {
  // console.log('employeeId, password -->');
  try {
    const data = {
      employeeId,
      password,
    };
    const response = await axios.post('/user/signin', data).catch((error) => ({
      status: error.response?.status,
      msg: 'User not found',
      error: true,
    }));

    if (response.data?.statusCode === 200) {
      // console.log('response.data  -->!@#@#$434');
      // console.log(response.data.data);
      const companyData = response.data?.data?.company;

      delete response.data?.data?.company;

      const userData = {
        _id: response.data?.data?._id,
        employeeId: response.data?.data?.employeeId,
        firstName: response.data?.data?.firstName,
        lastName: response.data?.data?.lastName,
        email: response.data?.data?.email,
        role: response.data?.data?.role,
        designation: response.data?.data?.designation,
        image: response.data?.data?.image,
      };

      Cookies.set(cookieConst.token, JSON.stringify(response.data?.data?.accessToken), { expires: 6 });
      Cookies.set(cookieConst.userData, JSON.stringify(userData), { expires: 6 });
      Cookies.set(cookieConst.userRole, JSON.stringify(response.data?.data?.role), { expires: 6 });
      Cookies.set(cookieConst.company, JSON.stringify(companyData), { expires: 6 });
      return {
        auth: true,
        error: false,
        message: response.data?.message,
      };
    }
    return {
      auth: false,
      error: true,
      message: response.data?.message,
    };
  } catch (error) {
    // console.log(error);
    return {
      auth: false,
      error: true,
      message: 'Something went wrong',
    };
  }
};
export default Signin;

export const SignOut = async () => {
  try {
    const token = getUserToken();
    const config = {
      headers: {
        'x-access-token': token,
      },
    };

    const response = await axios.delete('/user/signout', config).catch((error) => ({
      status: error.response?.status,
      msg: 'User not found',
      error: true,
    }));

    if (response.data) {
      // console.log(response.data);
      Cookies.remove(cookieConst.token);
      Cookies.remove(cookieConst.userData);
      Cookies.remove(cookieConst.userRole);
      return true;
    }
    return false;
  } catch (error) {
    // console.log(error);
    return false;
  }
};
