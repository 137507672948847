import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableHead,
  TextField,
  TableCell,
  Container,
  Typography,
  TableContainer,
  MenuItem,
  TablePagination,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';

import Iconify from '../../components/Iconify';
// components

import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getReq } from '../../data/ApiReq';
import Loading from '../../components/loading';
import { getUserRole } from '../../data/userData';

export default function Employee() {
  const initialState = {
    page: 1,
    limit: 25,
    totalCount: 0,
    totalPages: 0,
    hasNextPage: false,
    searchText: '',
    sort: '',
    qEdit: false,
  };

  const userRole = getUserRole();

  const [empList, setEmpList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(initialState);
  const [filterState, setFilterState] = useState({});
  const [locationData, setLocationData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const getfilterData = async () => {
    const locRes = await getReq({ url: `/location` });
    if (!locRes.error) {
      setLocationData(locRes.data);
    }

    const comRes = await getReq({ url: `/company` });
    if (!comRes.error) {
      setCompanyData(comRes.data);
    }
  };

  const getData = async ({ pg, lm, isSearch, filter }) => {
    // setLoading(true);
    const p = pg || state.page;
    const l = lm || state.limit;

    let query = `page=${p}&limit=${l}`;
    if (isSearch) {
      query = `page=${p}&limit=${l}&searchText=${isSearch}`;
      if (filter) {
        query += `&${filter}`;
      }
      setFilterState({ ...filterState, searchText: isSearch });
    }
    if (filter) {
      query = `page=${p}&limit=${l}&${filter}`;
      if (filterState.searchText) {
        query += `&searchText=${filterState.searchText}`;
      }
    }

    const res = await getReq({ url: `/user/getalluser?${query}` });
    if (!res.error) {
      setEmpList(res.data);
      setState({
        ...state,
        page: res.pagination.page,
        limit: res.pagination.limit,
        totalCount: res.pagination.totalCount,
        totalPages: res.pagination.totalPages,
        hasNextPage: res.pagination.hasNextPage,
        qEdit: false,
      });
      console.log(state);
    }

    // console.log('EMPLOYEELIST=========================>', res.data);

    // setLoading(false);
  };

  const changePage = (event, value) => {
    setState({
      ...state,
      page: value,
    });
    getData({ pg: value, lm: state.limit, isSearch: filterState.searchText, filter: filterState.query });
  };

  useEffect(() => {
    getData({ pg: state.page, lm: state.limit, isSearch: filterState.searchText, filter: filterState.query });
    getfilterData();
  }, []);

  if (loading) {
    return (
      <Page title="Employee">
        <Loading />
      </Page>
    );
  }

  const filterOnChange = (e) => {
    const { name, value } = e.target;
    const [id, nameVal] = value.split('|');
    console.log('name', nameVal, value);
    let query = '';
    if (name === 'location') {
      if (id === 'all') {
        if (filterState.compQ && filterState.compQ !== 'all') {
          query += `&company=${filterState.compQ}`;
        }
        setFilterState({ ...filterState, locQ: 'all', locName: 'all', query });
        getData({ pg: 1, lm: state.limit, isSearch: filterState.searchText, filter: query });
        return;
      }
      query = `location=${id}`;
      if (filterState.compQ && filterState.compQ !== 'all') {
        query += `&company=${filterState.compQ}`;
      }
      setFilterState({ ...filterState, locQ: id, locName: nameVal, query });
    } else if (name === 'company') {
      if (id === 'all') {
        if (filterState.locQ && filterState.locQ !== 'all') {
          query += `&location=${filterState.locQ}`;
        }
        setFilterState({ ...filterState, compQ: 'all', compName: 'all', query });
        getData({ pg: 1, lm: state.limit, isSearch: filterState.searchText, filter: query });
        return;
      }
      query = `company=${id}`;
      if (filterState.locQ && filterState.locQ !== 'all') {
        query += `&location=${filterState.locQ}`;
      }
      setFilterState({ ...filterState, compQ: id, compName: nameVal, query });
    }

    // setFilterState({ ...filterState, query });

    getData({ pg: 1, lm: state.limit, isSearch: filterState.searchText, filter: query });
  };

  const FilterSec = () => {
    if (!showFilter) return null;
    return (
      <>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
            select
            label={'Company'}
            style={{ width: '200px' }}
            onChange={filterOnChange}
            name="company"
            value={filterState.compQ ? `${filterState.compQ}|${filterState.compName}` : 'all|all'}
          >
            <MenuItem value="all|all">
              <em>All</em>
            </MenuItem>
            {companyData.map((option, key) => (
              <MenuItem key={key} value={`${option._id}|${option.companyName}`}>
                {option.companyName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label={'Location'}
            style={{ width: '200px' }}
            onChange={filterOnChange}
            name="location"
            value={filterState.locQ ? `${filterState.locQ}|${filterState.locName}` : 'all|all'}
          >
            <MenuItem value="all|all">
              <em>All</em>
            </MenuItem>
            {locationData.map((option, key) => (
              <MenuItem key={key} value={`${option._id}|${option.office}`}>
                {option.office}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </>
    );
  };
  return (
    <Page title="Employee">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Employee List
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Button onClick={() => setShowFilter(!showFilter)} variant={showFilter ? 'contained' : ''} color="warning">
              Filters
              <Iconify icon="eva:arrow-ios-downward-outline" />
            </Button>
            {state.totalPages > 1 && <Pagination count={state?.totalPages} color="primary" onChange={changePage} />}

            {userRole === 'hr' || userRole === 'admin' ? (
              <>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to="/dashboard/employees/new"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  New Employee
                </Button>
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" mb={3}>
          <FilterSec />
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.</TableCell>
                    {/* <TableCell>Sl.</TableCell> */}
                    <TableCell>Employee ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {empList.map((row, index) => (
                    <TableRow hover key={index}>
                      <TableCell>{state.limit * state.page + index + 1 - state.limit}</TableCell>
                      {/* <TableCell>
                        <Avatar
                          src={`https://www.b4x.com/android/forum/attachments/1650875759404-png.128458`}
                          alt="photoURL"
                        />
                      </TableCell> */}
                      <TableCell>{row.employeeId}</TableCell>
                      <TableCell>{row.firstName}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.phone}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          component={RouterLink}
                          to={`/dashboard/employees/view/${row._id}`}
                          startIcon={<Iconify icon="carbon:view" />}
                          color="warning"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
