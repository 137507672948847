import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import { CKEditor } from 'ckeditor4-react';
import { Icon } from '@iconify/react';

// @mui
import { styled } from '@mui/material/styles';
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert,
  Container,
  Typography,
  MenuItem,
  Card,
  Box,
  CardActions,
  Button,
  CardContent,
  Grid,
} from '@mui/material';

import { postReq, getReq, patchReq, deleteRequest } from '../../data/ApiReq';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import ApiUrl from '../../data/ApiUrl';
import Loading from '../../components/loading';
import { getUserData } from '../../data/userData';

const queryString = require('query-string');

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function AddOfficeTiming() {
  const initialState = {
    title: '',
    description: '',
    needAttendance: '',
    mondayCheckIn: '',
    mondayCheckOut: '',
    tuesdayCheckIn: '',
    tuesdayCheckOut: '',
    wednesdayCheckIn: '',
    wednesdayCheckOut: '',
    thursdayCheckIn: '',
    thursdayCheckOut: '',
    fridayCheckIn: '',
    fridayCheckOut: '',
    saturdayCheckIn: '',
    saturdayCheckOut: '',
    type: 'new',
  };
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };
  const params = useParams();
  const { userId } = useParams();
  const location = useLocation();
  const [state, setState] = useState(initialState);
  const [apiResult, setApiResult] = useState(initialResult);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);

    setState({
      ...state,
      [event.target.name]: event.target.value,
      needAttendance: event.target.checked,
    });
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const getRole = getUserData();
  const role = getRole?.role;

  const getData = async () => {
    if (params.id) {
      setState({
        ...state,
        type: 'edit',
        title: state.title,
        needAttendance: state.needAttendance,
        description: state.description,
        mondayCheckIn: state.mondayCheckIn,
        mondayCheckOut: state.mondayCheckOut,
        tuesdayCheckIn: state.tuesdayCheckIn,
        tuesdayCheckOut: state.tuesdayCheckOut,
        wednesdayCheckIn: state.wednesdayCheckIn,
        wednesdayCheckOut: state.wednesdayCheckOut,
        thursdayCheckIn: state.thursdayCheckIn,
        thursdayCheckOut: state.thursdayCheckOut,
        fridayCheckIn: state.fridayCheckIn,
        fridayCheckOut: state.fridayCheckOut,
        saturdayCheckIn: state.saturdayCheckIn,
        saturdayCheckOut: state.saturdayCheckOut,
      });
    } else {
      return null;
    }
    setLoading(true);

    const response = await getReq({ url: `/officetimings/${params.id}` });

    if (response.data) {
      const { data } = response;
      setState(data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const createOfficeTimings = async (e) => {
    setLoading(true);
    setApiResult({ ...apiResult, loading: true, alert: true });
    e.preventDefault();
    const data = {
      title: state.title,
      needAttendance: !state.needAttendance,
    };
    //  if (!state.needAttendance) data.needAttendance = false;
    if (state.description) data.description = state.description;
    if (state.mondayCheckIn) data.mondayCheckIn = state.mondayCheckIn;
    if (state.mondayCheckOut) data.mondayCheckOut = state.mondayCheckOut;
    if (state.tuesdayCheckIn) data.tuesdayCheckIn = state.tuesdayCheckIn;
    if (state.tuesdayCheckOut) data.tuesdayCheckOut = state.tuesdayCheckOut;
    if (state.wednesdayCheckIn) data.wednesdayCheckIn = state.wednesdayCheckIn;
    if (state.wednesdayCheckOut) data.wednesdayCheckOut = state.wednesdayCheckOut;
    if (state.thursdayCheckIn) data.thursdayCheckIn = state.thursdayCheckIn;
    if (state.thursdayCheckOut) data.thursdayCheckOut = state.thursdayCheckOut;
    if (state.fridayCheckIn) data.fridayCheckIn = state.fridayCheckIn;
    if (state.fridayCheckOut) data.fridayCheckOut = state.fridayCheckOut;
    if (state.saturdayCheckIn) data.saturdayCheckIn = state.saturdayCheckIn;
    if (state.saturdayCheckOut) data.saturdayCheckOut = state.saturdayCheckOut;

    const response = await postReq({
      url: '/officetimings',
      data,
    });

    if (!response.error) {
      setState(initialState);
      setApiResult({
        ...apiResult,
        error: false,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.success(response.message);
    } else {
      setApiResult({
        ...apiResult,
        error: true,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.error(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      // window.location.reload(false);
    }, 3000);

    setLoading(false);
  };

  const { id } = useParams();
  // data.id = userId;

  const updateOfficeTimings = async (e) => {
    setLoading(true);
    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const data = { timingId: params.id, needAttendance: state.needAttendance };
    if (state.title) data.title = state.title;
    if (state.description) data.description = state.description;
    if (state.mondayCheckIn) data.mondayCheckIn = state.mondayCheckIn;
    if (state.mondayCheckOut) data.mondayCheckOut = state.mondayCheckOut;
    if (state.tuesdayCheckIn) data.tuesdayCheckIn = state.tuesdayCheckIn;
    if (state.tuesdayCheckOut) data.tuesdayCheckOut = state.tuesdayCheckOut;
    if (state.wednesdayCheckIn) data.wednesdayCheckIn = state.wednesdayCheckIn;
    if (state.wednesdayCheckOut) data.wednesdayCheckOut = state.wednesdayCheckOut;
    if (state.thursdayCheckIn) data.thursdayCheckIn = state.thursdayCheckIn;
    if (state.thursdayCheckOut) data.thursdayCheckOut = state.thursdayCheckOut;
    if (state.fridayCheckIn) data.fridayCheckIn = state.fridayCheckIn;
    if (state.fridayCheckOut) data.fridayCheckOut = state.fridayCheckOut;
    if (state.saturdayCheckIn) data.saturdayCheckIn = state.saturdayCheckIn;
    if (state.saturdayCheckOut) data.saturdayCheckOut = state.saturdayCheckOut;
    if (state.type) data.type = state.type;

    // only for making slug remove this
    data.slug = state.title;

    const response = await patchReq({
      url: `/officetimings/${id}`,
      data,
    });

    if (!response.error) {
      setApiResult({
        ...apiResult,
        error: false,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.success(response.message);
    } else {
      setApiResult({
        ...apiResult,
        error: true,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.error(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      setState(initialState);
    }, 3000);

    setLoading(false);
  };

  const deleteOfficeTimings = async (e) => {
    setLoading(true);
    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const response = await deleteRequest({ url: `/officetimings/delete?id=${params.id}` });
    // console.log('id--', params.id);
    if (!response.error) {
      toast.success(response.data.message);
      setApiResult({ redirect: true });
    } else {
      toast.error(response.data.message);
      setApiResult({
        ...apiResult,
        error: true,
        alert: true,
        message: response.data.message,
        loading: false,
      });
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
    }, 3000);
    setLoading(false);
  };

  const timingSchema = Yup.object().shape({
    title: Yup.string().min(3, 'Please enter title').required('Title is required'),
    // date: Yup.date('Please enter valid date').required('Date is required'),
  });

  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    validationSchema: timingSchema,
    onChange: (e) => {
      console.log(e);
    },
    onSubmit: (values, { resetForm }) => {
      // console.log(values);

      updateOfficeTimings(values, resetForm);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  if (loading) {
    return (
      <Page title="Add Office Timing">
        <Loading />
      </Page>
    );
  }

  return (
    <Page title="Add Office Timing">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography
            variant="h4"
            gutterBottom
            sm="4"
            title={params.id ? 'Edit Office Timing' : 'Add Office Timing'}
            subtitle=" "
            className="text-sm-left"
          >
            {params.id ? 'Edit Office Timing' : 'Add Office Timing'}
          </Typography>

          <Button
            variant="text"
            component={RouterLink}
            to={`/dashboard/settings/getallofficetimings`}
            startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
            color={'primary'}
          >
            List Office Timings
          </Button>
        </Stack>
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={7}>
            <Card>
              <CardContent>
                <div>
                  <FormikProvider>
                    <Stack spacing={3}>
                      <Typography variant="h5" gutterBottom>
                        Basic Details
                      </Typography>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%', marginBottom: '10px' }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Title"
                            {...getFieldProps('title')}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%', marginBottom: '10px' }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Description"
                            {...getFieldProps('description')}
                            error={Boolean(touched.description && errors.description)}
                            helperText={touched.description && errors.description}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ justifyContent: 'center' }}>
                        <Stack spacing={2} style={{ marginBottom: '10px', marginTop: '10px' }}>
                          DO NOT TRACK ATTENDANCE
                        </Stack>
                        <Stack spacing={2} style={{ marginBottom: '10px' }}>
                          <Checkbox {...label} checked={state.needAttendance} onChange={handleCheckboxChange}>
                            .
                          </Checkbox>
                        </Stack>
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Monday Check In"
                          {...getFieldProps('mondayCheckIn')}
                          error={Boolean(touched.mondayCheckIn && errors.mondayCheckIn)}
                          helperText={touched.mondayCheckIn && errors.mondayCheckIn}
                          onChange={handleChange}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Monday Check Out"
                          {...getFieldProps('mondayCheckOut')}
                          error={Boolean(touched.mondayCheckOut && errors.mondayCheckOut)}
                          helperText={touched.mondayCheckOut && errors.mondayCheckOut}
                          onChange={handleChange}
                        />
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Tuesday Check In"
                          {...getFieldProps('tuesdayCheckIn')}
                          error={Boolean(touched.tuesdayCheckIn && errors.tuesdayCheckIn)}
                          helperText={touched.tuesdayCheckIn && errors.tuesdayCheckIn}
                          onChange={handleChange}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Tuesday Check Out"
                          {...getFieldProps('tuesdayCheckOut')}
                          error={Boolean(touched.tuesdayCheckOut && errors.tuesdayCheckOut)}
                          helperText={touched.tuesdayCheckOut && errors.tuesdayCheckOut}
                          onChange={handleChange}
                        />
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Wednesday Check In"
                          {...getFieldProps('wednesdayCheckIn')}
                          error={Boolean(touched.wednesdayCheckIn && errors.wednesdayCheckIn)}
                          helperText={touched.wednesdayCheckIn && errors.wednesdayCheckIn}
                          onChange={handleChange}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Wednesday Check Out"
                          {...getFieldProps('wednesdayCheckOut')}
                          error={Boolean(touched.wednesdayCheckOut && errors.wednesdayCheckOut)}
                          helperText={touched.wednesdayCheckOut && errors.wednesdayCheckOut}
                          onChange={handleChange}
                        />
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Thursday Check In"
                          {...getFieldProps('thursdayCheckIn')}
                          error={Boolean(touched.thursdayCheckIn && errors.thursdayCheckIn)}
                          helperText={touched.thursdayCheckIn && errors.thursdayCheckIn}
                          onChange={handleChange}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Thursday Check Out"
                          {...getFieldProps('thursdayCheckOut')}
                          error={Boolean(touched.thursdayCheckOut && errors.thursdayCheckOut)}
                          helperText={touched.thursdayCheckOut && errors.thursdayCheckOut}
                          onChange={handleChange}
                        />
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Friday Check In"
                          {...getFieldProps('fridayCheckIn')}
                          error={Boolean(touched.fridayCheckIn && errors.fridayCheckIn)}
                          helperText={touched.fridayCheckIn && errors.fridayCheckIn}
                          onChange={handleChange}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Friday Check Out"
                          {...getFieldProps('fridayCheckOut')}
                          error={Boolean(touched.fridayCheckOut && errors.fridayCheckOut)}
                          helperText={touched.fridayCheckOut && errors.fridayCheckOut}
                          onChange={handleChange}
                        />
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Saturday Check In"
                          {...getFieldProps('saturdayCheckIn')}
                          error={Boolean(touched.saturdayCheckIn && errors.saturdayCheckIn)}
                          helperText={touched.saturdayCheckIn && errors.saturdayCheckIn}
                          onChange={handleChange}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          label="Saturday Check Out"
                          {...getFieldProps('saturdayCheckOut')}
                          error={Boolean(touched.saturdayCheckOut && errors.saturdayCheckOut)}
                          helperText={touched.saturdayCheckOut && errors.saturdayCheckOut}
                          onChange={handleChange}
                        />
                      </Stack>
                    </Stack>
                    {state.type === 'new' ? (
                      <Button
                        className=" brand-buttons"
                        variant="contained"
                        color="primary"
                        onClick={createOfficeTimings}
                        style={{ marginTop: '10px' }}
                      >
                        Submit
                      </Button>
                    ) : (
                      <>
                        <Box textAlign="end" sx={{ marginTop: 2 }}>
                          <Button
                            sx={{ marginRight: 2 }}
                            onClick={deleteOfficeTimings}
                            color="error"
                            className="brand-buttons"
                            variant="contained"
                          >
                            Delete
                          </Button>
                          <Button
                            onClick={updateOfficeTimings}
                            color="warning"
                            className="mr-4 brand-buttons"
                            variant="contained"
                          >
                            Update
                          </Button>
                        </Box>
                      </>
                    )}
                  </FormikProvider>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {loading ? <Loading /> : null}
    </Page>
  );
}
