import React from 'react';
import { Grid } from '@mui/material';
import Iconify from '../../../components/Iconify';
import EventItem from './eventItems';

export default function EventCard({ events, title, type, desc }) {
  return (
    <>
      <div className="dashbord-card">
        <div className="dashboard-card-title">
          <Iconify icon="fe:birthday-cake" /> <span> {title} </span>
        </div>
        <div>
          {!events?.length === 0 && <div className="no-data-events">{desc}</div>}
          {events?.map((item, key) => (
            <EventItem item={item} key={key} type={type} />
          ))}
        </div>
      </div>
    </>
  );
}
