import { Link as RouterLink, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import { max } from 'lodash';
import Paper from '@mui/material/Paper';
import {
  Card,
  Stack,
  Avatar,
  Button,
  Container,
  Typography,
  Grid,
  Label,
  Item,
  Box,
  Link,
  Divider,
  CardHeader,
} from '@mui/material';
import API_URL from '../../data/ApiUrl';

import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';

import { getReq } from '../../data/ApiReq';
import Loading from '../../components/loading';
import { getUserData } from '../../data/userData';

export default function News() {
  const { userId } = useParams();
  const [newsList, setNewsList] = useState([]);
  const [imgAddress, setImgAddress] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);

    const res = await getReq({ url: '/news/news' });
    if (!res.error) {
      setNewsList(res.data);
      console.log(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const getRole = getUserData();
  const role = getRole?.role;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  if (loading) {
    return (
      <Page title="News">
        <Loading />
      </Page>
    );
  }

  return (
    <Page title="News">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            News
          </Typography>

          {role === 'admin' || role === 'hr' ? (
            <>
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/news/addnews"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add News
              </Button>
            </>
          ) : (
            <></>
          )}
        </Stack>

        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4 }}>
          {newsList.map((item, key) => (
            <Grid item xs={2} sm={4} md={4} key={key}>
              <Link
                style={{ textDecoration: 'none' }}
                component={RouterLink}
                to={`/dashboard/news/readMore/${item._id}?news=${item.title}`}
              >
                <Card sx={{ height: '100%' }}>
                  <Stack spacing={2} key={key}>
                    <Grid container spacing={2} m={2}>
                      <Grid item xs={12}>
                        <div
                          className="user-img2"
                          style={{
                            backgroundImage: `url(${API_URL.img_url}/api/uploads/images/${item?.image?.ogUrl})`,
                          }}
                        />
                        <Typography variant="h5" sx={{ width: '98%' }} gutterTop>
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} textAlign={'start'}>
                        <Typography
                          sx={{ width: '90%' }}
                          dangerouslySetInnerHTML={{ __html: item.description.substring(0, 250) }}
                        />
                        <Box sx={{ mr: 20 }}>
                          {role === 'admin' || role === 'hr' ? (
                            <>
                              <Button
                                sx={{ marginTop: 2 }}
                                variant="contained"
                                component={RouterLink}
                                to={`editNews/${item._id}?news=${item.title}`}
                                startIcon={<Iconify icon="carbon:view" />}
                                color="warning"
                              >
                                Edit
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}
