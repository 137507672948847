import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Grid, Typography, Button, Stack } from '@mui/material';
import Chart from 'react-apexcharts';
import ReportModal from './reportModal';
import Iconify from '../../../components/Iconify';
import CompanyCardMd from '../../../components/companyAttendanceMd';
import { postReq, getReq } from '../../../data/ApiReq';
import Loading from '../../../components/loading';


export default function AttendanceOverview() {
  const [loading, setLoading] = useState(false);
  const [overviewData, setOverviewData] = useState([]);
  const [todaysOverview, setTodaysOverview] = useState({
    totalAttendance: 0,
    absence: 0,
    totalLate: 0,
  });

  const getOverviewData = async (url) => {
    if (!url) url = `/attendance/overview/daily`;
    const overViewRes = await getReq({
      url,
    });
    // console.log('_0-0-0-0 Month Cahnge', overViewRes);

    if (!overViewRes.error) {
      setOverviewData(overViewRes.data.attendanceData);
      setTodaysOverview(overViewRes.data.todaysOverview);
    }
  };

  useEffect(() => {
    getOverviewData();
  }, []);

  const onClick = (event) => {
    console.log(event.labels);
    console.log('onlick attendance here....');
  }
  const presentCount = todaysOverview?.totalAttendance - todaysOverview?.totalLate;

  const ChartData = {
    series: [presentCount, todaysOverview?.absence, todaysOverview?.totalLate],
    chartOptions: {
      labels: [
        `Present - ${presentCount}`,
        `Absent - ${todaysOverview?.absence}`,
        `Late - ${todaysOverview?.totalLate}`,
      ],

      colors: ['#45aaf2', '#eb3b5a', '#f7b731'],
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '12px',
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'diagonal2',
          shadeIntensity: 0.5,
          gradientToColors: ['#25CCF7', '#ff6b6b', '#fed330'],

          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 100],
        },
      },
     
    },
    events: {
      dataPointMouseEnter: (event, chartContext, config) => {
        console.log("config.dataPointIndex=====>>"); // Log the index of the selected data point
        console.log(config.dataPointIndex); // Log the index of the selected data point
      }
    },
  };

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1} mt={3}>
          <Typography variant="h6" gutterBottom>
            Attendence Overview
          </Typography>
          <Button
            variant="text"
            component={RouterLink}
            to={`/dashboard/leaves/fulldetails`}
            startIcon={<Iconify icon={'material-symbols:calendar-month-rounded'} />}
            color={'primary'}
          >
            View More
          </Button>
        </Stack>
        <Grid container spacing={3} alignItems={'center'}>
          <Grid item xs={12} md={4} lg={4} mb={2}>
            <div className="dashbord-card" style={{ display: 'grid', placeItems: 'center' }}>
              <Chart options={ChartData.chartOptions} series={ChartData.series} type="donut" height={280} events={ChartData.events} />
              {/* <ReportModal /> */}
            </div>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <div className="">
              <Grid container spacing={3}>
                {overviewData.map((item, key) => (
                  <Grid item xs={12} md={12} lg={6} key={key}>
                    <CompanyCardMd item={item} key={key} />
                    {/* <CompanyCardLg item={item} key={key} /> */}
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
