import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Link as RouterLink, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
// @mui
import { styled } from '@mui/material/styles';
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert,
  Container,
  Typography,
  Card,
  CardActions,
  CardContent,
  Grid,
  MenuItem,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Loading from '../../components/loading';
import ApiUrl from '../../data/ApiUrl';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { postReq, getReq, patchReq } from '../../data/ApiReq';
import { getUserRole } from '../../data/userData';
import { fDate } from '../../utils/formatTime';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function EditRequest() {
  const paramsId = useParams();

  const initialValues = {
    managerResponse: '',
    approvedByManager: '',
  };

  const [userData, setUserData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const UserSchema = Yup.object().shape({
    managerResponse: Yup.string().min(3, 'Please enter a valid reason').required('Reason is required'),
  });

  const formik = useFormik({
    initialValues: userData,
    enableReinitialize: true,
    validationSchema: UserSchema,
    onSubmit: (values, { resetForm }) => {
      // console.log(values);

      updateUser(values, resetForm);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const getUserData = async () => {
    setLoading(true);
    const response = await getReq({ url: `/attendance/${paramsId.id}` });
    if (response.data) {
      const { data } = response;

      setUserData(data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const updateUser = async (values, resetForm) => {
    const data = values;

    data.id = paramsId.id;
    const res = await patchReq({ url: `/attendance/laterequest/employee/${paramsId.id}`, data });
    if (!res.error) {
      toast.success(' Response Updated ');
      resetForm();
      getUserData();
    } else {
      toast.error(res.message);
    }
  };

  if (loading) {
    return (
      <Page title="Response">
        <Loading />
      </Page>
    );
  }

  return (
    <Page title={`Response`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            {userData.firstName} {userData.lastName}
          </Typography>

          <Stack direction="row" alignItems="center" gap={3} mb={3}>
            {/* Sumit formic form */}

            <Button
              variant="text"
              component={RouterLink}
              to={`/dashboard/requests`}
              startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
              color={'primary'}
            >
              Request List
            </Button>
          </Stack>
        </Stack>

        <div>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2} justifyContent="center">
                {/* Basic Details ⬇️ */}
                <Grid item xs={12} md={9}>
                  <Card>
                    <CardContent>
                      <Stack spacing={3}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                          <Typography variant="h6" gutterBottom>
                            {userData?.userId?.firstName} {userData?.userId?.lastName}
                          </Typography>
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                          <Typography variant="h6" gutterBottom>
                            Date : {userData?.date?.dd}/ {userData?.date?.mm}/ {userData?.date?.yyyy}
                          </Typography>
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                          <Typography variant="h6" gutterBottom>
                            Check In Time : {userData?.checkInLocalTime}
                          </Typography>
                        </Stack>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                          <Typography variant="h6" gutterBottom>
                            Reason : {userData?.reason}
                          </Typography>
                        </Stack>

                        {userData?.approvedByManager === 'pending' ? (
                          <div>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                label="Reason"
                                {...getFieldProps('managerResponse')}
                                multiline
                                rows={4}
                                error={Boolean(touched.managerResponse && errors.managerResponse)}
                                helperText={touched.managerResponse && errors.managerResponse}
                              />
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mt: 2 }}>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                select
                                fullWidth
                                label="Status"
                                {...getFieldProps('approvedByManager')}
                                error={Boolean(touched.approvedByManager && errors.approvedByManager)}
                                helperText={touched.approvedByManager && errors.approvedByManager}
                              >
                                <MenuItem value="accepted">Accepted</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="rejected">Rejected</MenuItem>
                              </TextField>
                            </Stack>
                          </div>
                        ) : (
                          <>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                              <Typography variant="h6" gutterBottom>
                                Response : {userData?.managerResponse}
                              </Typography>
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                              <Typography variant="h6" gutterBottom>
                                Status : {userData?.approvedByManager}
                              </Typography>
                            </Stack>
                          </>
                        )}
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                {userData?.approvedByManager === 'pending' ? (
                  <Grid item xs={12} md={4}>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained">
                      Submit
                    </LoadingButton>
                  </Grid>
                ) : (
                  <> </>
                )}
              </Grid>
            </Form>
          </FormikProvider>
        </div>
      </Container>
    </Page>
  );
}
