import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
// import ReactLoading from 'react-loading';
// @mui
import { styled } from '@mui/material/styles';
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  MenuItem,
  Select,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert,
  Container,
  Box,
  Typography,
  Card,
  CardActions,
  Button,
  CardContent,
  Grid,
} from '@mui/material';

import { postReq, getReq, patchReq, deleteRequest } from '../../data/ApiReq';

// components
import Page from '../../components/Page';
import Loading from '../../components/loading';

import Iconify from '../../components/Iconify';
import API_URL from '../../data/ApiUrl';

const queryString = require('query-string');

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function AddBrand() {
  const initialState = {
    companyName: '',
    address: '',
    logo: '',
    type: 'new',
  };
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };
  const params = useParams();
  const location = useLocation();

  const [brandData, setBrand] = useState([]);
  const [state, setState] = useState(initialState);
  const [apiResult, setApiResult] = useState(initialResult);
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value);
  };

  const getData = async () => {
    setLoading(true);

    // const query = queryString.parse(location.search);
    // console.log('Location', query);

    if (params.id) {
      setState({
        ...state,
        type: 'edit',
        companyName: state.companyName,
        address: state.address,
        logo: state.logo,
      });
    }

    const response = await getReq({ url: `/company/${params.id}` });

    //  console.log('response--->');
    console.log(response.data);

    if (response.data) {
      const { data } = response;
      setState(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (params.id) {
      getData();
    }
  }, []);

  const [imgAddress, setImgAddress] = useState(null);
  const demoImg = 'https://amdmediccentar.rs/wp-content/plugins/uix-page-builder/includes/uixpbform/images/no-logo.png';

  const imageUpload = async (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const data = new FormData();
    data.append('image', file);
    data.append('type', 'logo');

    const response = await postReq({
      url: 'image/upload',
      data,
    });
    // console.log('image response', response);
    // if check
    if (!response.error) {
      const logo = response.data._id;
      const imageData = response.data;
      setImgAddress(imageData.ogUrl);
      setState({
        ...state,
        logo,
        imgUrl: imageData.ogUrl,
      });
      toast.success(response.message);
      console.log(response.data.ogUrl);
    } else {
      toast.error(response.message);
    }
  };

  const AssetSchema = Yup.object().shape({
    companyName: Yup.string().min(3, 'Please enter company name').required('Company name is required'),
    address: Yup.string().min(3, 'Please enter an address').required('address is required'),
    // date: Yup.date('Please enter valid date').required('Date is required'),
  });

  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    validationSchema: AssetSchema,
    onChange: (e) => {
      console.log(e);
    },
    onSubmit: (values, { resetForm }) => {
      // console.log(values);

      updateBrand(values, resetForm);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const createBrand = async (e) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true, alert: true });
    e.preventDefault();
    const data = {
      companyName: state.companyName,
      address: state.address,
      logo: state.logo,
      type: 'company',
    };
    if (state.companyName) {
      data.companyName = state.companyName;
    }
    if (state.address) {
      data.address = state.address;
    }
    if (state.logo) data.logo = state.logo;

    const response = await postReq({
      url: '/company',
      data,
    });

    if (!response.error) {
      toast.success(response.message);
      setState(initialState);
      setApiResult({
        ...apiResult,
        error: false,
        message: response.message,
        alert: true,
        loading: false,
      });
    } else {
      toast.error(response.message);
      setApiResult({
        ...apiResult,
        error: true,
        message: response.message,
        alert: true,
        loading: false,
      });
      console.log(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      // window.location.reload(false);
    }, 3000);

    setLoading(false);
  };

  const updateBrand = async (e) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const data = {
      companyId: params.id,
      companyName: state.companyName,
      address: state.address,
      logo: state.logo,
      //   type:"companyDetails",
    };

    // data.id = userId;
    if (state.companyName) data.companyName = state.companyName;

    if (state.address) data.address = state.address;

    if (state.logo) data.logo = state.logo;

    // only for making slug remove this
    data.slug = state.companyName;

    const response = await patchReq({
      url: `/company/respond/${params.id}`,
      data,
    });

    if (!response.error) {
      toast.success(response.message);
      setApiResult({
        ...apiResult,
        error: false,
        message: response.message,
        alert: true,
        loading: false,
      });
    } else {
      toast.error(response.message);
      setApiResult({
        ...apiResult,
        error: true,
        message: response.message,
        alert: true,
        loading: false,
      });
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      setState(initialState);
    }, 3000);

    setLoading(false);
  };

  const deleteCompany = async (e) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    console.log('id--', params.id);

    const response = await deleteRequest({ url: `company/delete?id=${params.id}` });
    console.log(response.data.message);

    if (!response.error) {
      toast.success(response.data.message);
      setApiResult({ redirect: true });
    } else {
      toast.error(response.data.message);
      setApiResult({
        ...apiResult,
        error: true,
        alert: true,
        message: response.data.message,
        loading: false,
      });
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
    }, 3000);

    setLoading(false);
  };

  if (loading) {
    return (
      <Page title={params.id ? 'Edit Company' : 'Add Company'}>
        <Loading />
      </Page>
    );
  }

  return (
    <Page title={params.id ? 'Edit Company' : 'Add Company'}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography
            variant="h4"
            gutterBottom
            sm="4"
            title={params.id ? 'Edit Company' : 'Add Company'}
            subtitle=" "
            className="text-sm-left"
          >
            {params.id ? 'Edit Company' : 'Add Company'}
          </Typography>
          <Button
            variant="text"
            component={RouterLink}
            to={`/dashboard/settings/getallcompany`}
            startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
            color={'primary'}
          >
             Company List
          </Button>

        </Stack>
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={7}>
            <Card>
              <CardContent>
                <div>
                  <FormikProvider>
                    {/* <Form autoComplete="off" noValidate > */}
                    <Stack spacing={3}>
                      <Typography variant="h5" gutterBottom>
                        Basic Details
                      </Typography>
                      <Stack justifyContent="center" alignItems="center">
                        {imgAddress ? (
                          <>
                            <Stack
                              spacing={2}
                              justifyContent="center"
                              alignItems="center"
                              sx={{ width: '100%', height: '300px', objectFit: 'contain' }}
                            >
                              <div
                                className="img-upload-pre"
                                style={{
                                  backgroundImage: `url(${
                                    imgAddress ? `${API_URL.img_url}/api/uploads/images/${imgAddress}` : demoImg
                                  })`,
                                }}
                              >
                                {state.type === 'new' && (
                                  <Button
                                    sx={{ marginTop: 2 }}
                                    variant="contained"
                                    component="label"
                                    className="imgupload-company"
                                  >
                                    Change
                                    <Icon icon="uil:image-upload" />
                                    <input type="file" hidden onChange={imageUpload} />
                                  </Button>
                                )}
                                {params.id && (
                                  <Button
                                    sx={{ margin: 2 }}
                                    variant="contained"
                                    component="label"
                                    className="imgupload-company"
                                  >
                                    Change <Icon icon="uil:image-upload" />
                                    <input type="file" hidden onChange={imageUpload} />
                                  </Button>
                                )}
                              </div>
                            </Stack>
                          </>
                        ) : (
                          <>
                            {params.id ? (
                              <>
                                <Stack
                                  spacing={2}
                                  sx={{ width: '100%', height: '200px', objectFit: 'contain' }}
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <div
                                    className="img-upload-pre"
                                    style={{
                                      backgroundImage: `url(${
                                        state.logo
                                          ? `${API_URL.img_url}/api/uploads/images/${state.logo.ogUrl}`
                                          : demoImg
                                      })`,
                                    }}
                                  >
                                    <Button
                                      sx={{ margin: 1 }}
                                      variant="contained"
                                      component="label"
                                      className="imgupload-company"
                                    >
                                      Change <Icon icon="uil:image-upload" />
                                      <input type="file" hidden onChange={imageUpload} />
                                    </Button>
                                  </div>
                                </Stack>
                              </>
                            ) : (
                              <Button variant="contained" component="label" className="imgupload-company">
                                Upload Image <Icon icon="uil:image-upload" />
                                <input type="file" hidden onChange={imageUpload} />
                              </Button>
                            )}
                          </>
                        )}
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%', paddingRight: '12px' }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Company Name"
                            {...getFieldProps('companyName')}
                            error={Boolean(touched.companyName && errors.companyName)}
                            helperText={touched.companyName && errors.companyName}
                            onChange={handleChange}
                          />

                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Address"
                            {...getFieldProps('address')}
                            error={Boolean(touched.address && errors.address)}
                            helperText={touched.address && errors.address}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                    {state.type === 'new' ? (
                      <Box textAlign="center" sx={{ marginTop: 2 }}>
                        <Button className=" brand-buttons" variant="contained" color="primary" onClick={createBrand}>
                          Submit
                        </Button>
                      </Box>
                    ) : (
                      <>
                        <Box textAlign="end">
                          <Button
                            sx={{ marginTop: 2, marginRight: 2 }}
                            onClick={deleteCompany}
                            color="error"
                            className="mr-4 brand-buttons"
                            variant="contained"
                          >
                            Delete
                          </Button>

                          <Button
                            sx={{ marginTop: 2 }}
                            onClick={updateBrand}
                            color="warning"
                            className="mr-4 brand-buttons"
                            variant="contained"
                          >
                            Update
                          </Button>
                        </Box>
                      </>
                    )}
                    {/* </Form> */}
                  </FormikProvider>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
