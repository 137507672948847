/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, Fragment } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { useNavigate } from 'react-router';

import { toast } from 'react-toastify';
// @mui

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  Link,
  Stack,
  TextField,
  Container,
  Typography,
  TableCell,
  Card,
  TableRow,
  CardContent,
  Grid,
  Table,
  Button,
  List,
  Box,
  TableContainer,
  ListItem,
  ListItemButton,
  TableBody,
  ListItemIcon,
  TableHead,
  ListItemText,
  Divider,
  Input,
  Slide,
  IconButton,
  InputAdornment,
  ClickAwayListener,
  Avatar,
  Hidden,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { styled, alpha } from '@mui/material/styles';
import Scrollbar from '../../components/Scrollbar';

import Searchbar from '../../layouts/dashboard/Searchbar';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Loading from '../../components/loading';

import { getReq, postReq, patchReq } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';

const AssetSec = ({ handleSearch, handleChange, createAssetAssign, userData, state }) => (
  <div>
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
      <Stack spacing={3} style={{ width: '100%', paddingTop: '14px' }}>
        <TextField
          id="outlined-number"
          label="Date Of Assigning"
          type="date"
          name="dateOfAssigning"
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={state.dateOfAssigning}
          value={state.dateOfAssigning}
        />
        <TextField
          fullWidth
          label="Remarks"
          name="remarks"
          onChange={handleChange}
          defaultValue={state.remarks}
          value={state.remarks}
        />

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          spacing={2}
          marginTop={4}
        >
          <Button
            variant="contained"
            component={RouterLink}
            to={`/dashboard/asset/single/${userData?._id}`}
            color={'primary'}
            onClick={createAssetAssign}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Stack>
  </div>
);

export default function AssetOverView() {
  const params = useParams();

  const [userData, setUserData] = useState({});
  const [assetData, setAssetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchName, setSearchName] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [currentlyUsingUserState, setCurrentlyUsingUserState] = useState([]);

  const getData = async () => {
    setLoading(true);

    const res = await getReq({ url: `/asset/${params.id}` });
    if (!res.error) {
      setUserData(res.data);
    }

    const res1 = await getReq({ url: `/asset/list/${params.id}` });

    if (!res1.error) {
      setAssetData(res1.data);
    }
    const resArr = res1.data;
    const currentlyusingUser = resArr.filter((item) => item.currentlyUsing);
    setCurrentlyUsingUserState(currentlyusingUser[0]);

    setLoading(false);
  };

  useEffect(() => {
    getData();
    handleClose();
  }, []);

  const initialState = {
    employee: '',
    dateOfAssigning: '',
    asset: '',
    remark: '',
    currentlyUsing: '',
    state: '',
  };
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };
  const [state, setState] = useState(initialState);
  const [apiResult, setApiResult] = useState(initialResult);
  const [showText, setShowText] = useState(false);
  const [selectSec, setSelectSec] = useState(true);
  const navigate = useNavigate();

  const handleClicks = () => {
    setShowText(!showText);
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    console.log(state);
  };

  const handleClick = (e) => {
    setState({
      ...state,
      employee: e._id,
      employeeName: e.firstName,
      employeeLastName: e.lastName,
      employeeId: e.employeeId,
      employeeImg: e.image.ogUrl,
      employeeDesignation: e.designation,
    });
    setShowText(!showText);
    setSelectSec(!selectSec);
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = async (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText.length < 3) return null;

    const res = await getReq({ url: `/user/getalluser?search=${searchText}` });

    if (!res.error) {
      setSearchResult(res.data);
    }
  };
  const createAssetAssign = async (e) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true, alert: true });
    e.preventDefault();
    const data = {
      asset: userData._id,
      employee: state.employee,
      dateOfAssigning: state.dateOfAssigning,
      remarks: state.remarks,
    };

    const response = await postReq({
      url: `/asset/assign`,
      data,
    });

    if (!response.error) {
      toast.success(response.message);
      setState(initialState);
      navigate(0);
      setApiResult({
        ...apiResult,
        error: false,
        message: response.message,
        alert: true,
        loading: false,
      });
    } else {
      toast.error(response.message);
      setApiResult({
        ...apiResult,
        error: true,
        message: response.message,
        alert: true,
        loading: false,
      });
      console.log(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      // window.location.reload(false);
    }, 3000);

    setLoading(false);
  };

  const DeleteAssetAssign = async (e) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const data = {
      currentlyUsing: 'false',
    };
    const response = await patchReq({ url: `/asset/retrieve?id=${currentlyUsingUserState._id}`, data });
    console.log(response.message);
    if (!response.error) {
      toast.success(response.message);
      setApiResult({ redirect: true });
      navigate(0);
    } else {
      toast.error(response.message);
      setApiResult({
        ...apiResult,
        error: true,
        alert: true,
        message: response.message,
        loading: false,
      });
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
    }, 3000);

    setLoading(false);
  };

  // const words = assetData;

  // const assetTrue = words.filter(word => word.currentlyUsing === true);

  const EmployeeResult = () => {
    if (currentlyUsingUserState)
      return (
        <div>
          {/* <Typography variant="subtitle1" sx={{ color: 'text.primary', textAlign: 'start' }}>
            Employee Found
          </Typography> */}
          <div>
            <Grid container justifyContent="center" mb={3}>
              <Grid item xs={12} md={12}>
                {/* // <RouterLink to={`/dashboard/employees/view/${item._id}`} key={key} style={{ textDecoration: 'none' }}> */}
                <div className="asset-search-sec">
                  {/* {assetData.map((item, key) => ( */}
                  <div className="search-text">
                    {/* <Avatar src={`${API_URL.img_url}/api/uploads/images/${currentlyUsingUserState?.employee?.image?.ogUrl}`} alt={currentlyUsingUserState?.employee?.firstName} /> */}
                    <Box>
                      <Typography variant="subtitle1" sx={{ color: 'text.primary', textAlign: 'start' }}>
                        {currentlyUsingUserState?.employee?.firstName} {currentlyUsingUserState?.employee?.lastName}
                      </Typography>
                      <p className="search-desc">
                        {currentlyUsingUserState?.employee?.employeeId?.toUpperCase()} |{' '}
                        {currentlyUsingUserState?.employee?.designation?.toUpperCase()}
                      </p>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        justifyContent="end"
                        alignItems="end"
                        spacing={2}
                        marginLeft={16}
                      >
                        <Button
                          variant="contained"
                          component={RouterLink}
                          to={`/dashboard/asset/single/${assetData?._id}`}
                          color={'error'}
                          onClick={DeleteAssetAssign}
                        >
                          Remove
                        </Button>
                      </Stack>
                    </Box>
                  </div>
                  {/* ))} */}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    return (
      <div>
        <SearchResult />

        {/* <AssetSec
          // handleSearch={handleSearch}
          handleChange={handleChange}
          state={state}
          createAssetAssign={createAssetAssign}
          userData={userData}
        /> */}
        {AssetSec({
          handleChange,
          state,
          createAssetAssign,
          userData}
        )}
      </div>
    );
  };

  const SearchResult = () => {
    if (searchResult?.length === 0 || searchText.length < 3)
      return (
        <div>
          {/* <Typography variant="subtitle1" sx={{ color: 'text.primary', textAlign: 'start' }}>
            Employee Not found
          </Typography> */}
        </div>
      );
    return (
      <div>
        {selectSec && (
          <Grid container justifyContent="center" mb={3}>
            <Grid item xs={12} md={12}>
              {searchResult.map((item, key) => (
                // <RouterLink to={`/dashboard/employees/view/${item._id}`} key={key} style={{ textDecoration: 'none' }}>
                <div className="asset-search-sec" key={key}>
                  <div className="search-text">
                    <Avatar src={`${API_URL.img_url}/api/uploads/images/${item?.image?.ogUrl}`} alt={item?.firstName} />
                    <Box sx={{ ml: 2 }} onClick={() => handleClick(item)}>
                      <Typography variant="subtitle1" sx={{ color: 'text.primary', textAlign: 'start' }}>
                        {item?.firstName} {item?.lastName}
                      </Typography>
                      <p className="search-desc">
                        {item?.employeeId?.toUpperCase()} | {item?.designation?.toUpperCase()}
                      </p>
                    </Box>
                  </div>
                </div>
              ))}
            </Grid>
          </Grid>
        )}
      </div>
    );
  };

  return (
    <Page title={`${userData?.deviceName} - Overview`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            {userData?.firstName} {userData?.lastName}
          </Typography>
          <Button
            variant="text"
            component={RouterLink}
            to={`/dashboard/asset`}
            startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
            color={'primary'}
          >
            Asset List
          </Button>
        </Stack>

        <Grid container spacing={3} justifyContent="center" mb={3}>
          <Grid item xs={12} md={8}>
            <div className="dashbord-card">
              <div>
                <div className="dashboard-card-title">
                  {/* Attendence Icon */}
                  <Iconify icon="mdi:user" /> <span>Basic Details</span>
                </div>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center">
                  <Stack spacing={2} style={{ width: '100%' }}>
                    <table className="emp-data-table">
                      <tr>
                        <td>Device Name</td>
                        <td>
                          <Typography variant="subtitle1"> {userData?.deviceName}</Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>Device ID</td>
                        <td>
                          {' '}
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.serialNo}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td> Remarks </td>
                        <td>
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.remarks}
                          </Typography>
                        </td>
                      </tr>
                    </table>
                  </Stack>
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center">
                  Description{' '}
                  <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '7px', marginLeft: '14px' }}>
                    : {userData?.description}
                  </Typography>
                </Stack>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div className="dashbord-card">
              <div className="dashboard-card-title">
                <Iconify icon="wi:stars" /> <span>Assign to User</span>
              </div>

              {/* <div>
      <button onClick={handleClicks}>Toggle Text</button>
      {showText && 
      <div>
        <div >
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20, marginRight: 2}}
                />
                {state?.employeeName} {state?.employeeLastName}
                <Input onChange={handleSearch}/>
              </div>
              <EmployeeResult />
        </div>
      }
    </div> */}
              {showText && (
                <div>
                  <div className="asset-search-sec">
                    <div className="search-text">
                      <Avatar
                        src={`${API_URL.img_url}/api/uploads/images/${state?.employeeImg}`}
                        alt={state?.employeeName}
                      />
                      <Box sx={{ ml: 2 }}>
                        <Typography variant="subtitle1" sx={{ color: 'text.primary', textAlign: 'start' }}>
                          {state?.employeeName} {state?.employeeLastName}
                        </Typography>
                        <p className="search-desc">
                          {state?.employeeId?.toUpperCase()} | {state?.employeeDesignation?.toUpperCase()}
                        </p>
                      </Box>
                    </div>
                  </div>
                </div>
              )}

              <div>
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20, marginRight: 2 }}
                />

                <Input onChange={handleSearch} />
              </div>
              <EmployeeResult />

              {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Stack spacing={3} style={{ width: '100%', marginTop: '8px' }}>
                  <div>
                    <div>
                      <div>
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: 'text.disabled', width: 20, height: 20, marginRight: 2 }}
                        />
                        <Input onChange={handleSearch} />

                      </div>
                    </div>
                  </div>

                  <TextField
                    id="outlined-number"
                    label="Date Of Assigning"
                    type="date"
                    name="dateOfAssigning"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={state.dateOfAssigning}
                    value={state.dateOfAssigning}
                  />
                  <TextField
                    fullWidth
                    label="Remarks"
                    name="remarks"
                    onChange={handleChange}
                    defaultValue={state.remarks}
                    value={state.remarks}
                  />

                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    marginTop={4}
                  >
                    <Button
                      variant="contained"
                      component={RouterLink}
                      to={`/dashboard/asset/single/${userData?._id}`}
                      color={'primary'}
                      onClick={createAssetAssign}
                    >
                      Save
                    </Button>
                  </Stack>
                </Stack>
              </Stack> */}

              {/* <AssetSec
                handleSearch={handleSearch}
                handleChange={handleChange}
                state={state}
                createAssetAssign={createAssetAssign}
                userData={userData}
              /> */}
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell>Date Of Assigning</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>

                    {assetData.map((row, key) => (
                      <TableBody key={key}>
                        <TableCell>
                          {row?.employee?.firstName} {row?.employee?.lastName}
                        </TableCell>
                        <TableCell>
                          <Moment format="YYYY/MM/DD">{row?.dateOfAssigning}</Moment>
                        </TableCell>
                        <TableCell>{row?.currentlyUsing ? 'Currently Using' : 'Not Using'}</TableCell>
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Grid>
        </Grid>

        <div>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="center"
            alignItems="center"
            spacing={2}
            marginTop={4}
          >
            <Button
              variant="outlined"
              component={RouterLink}
              to={`/dashboard/asset/single/${userData?._id}`}
              startIcon={<Iconify icon={'bx:edit-alt'} />}
              color={'primary'}
              size="large"
              fullWidth
            >
              Edit
            </Button>
          </Stack>
        </div>
      </Container>
    </Page>
  );
}
