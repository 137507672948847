import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';

// @mui
import { styled } from '@mui/material/styles';
import {
  Stack,
  TextField,
  MenuItem,
  Container,
  Input,
  Typography,
  Card,
  Icon,
  Box,
  Checkbox,
  Autocomplete,
  Button,
  CardContent,
  Grid,
} from '@mui/material';

import { postReq, getReq, patchReq, deleteRequest } from '../../data/ApiReq';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import ApiUrl from '../../data/ApiUrl';
import Loading from '../../components/loading';
import { getUserData } from '../../data/userData';

const queryString = require('query-string');

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function AddDocumentCategory() {
  const initialState = {
    title: '',
    description: '',
    isPublic: '',
  };
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };

  const params = useParams();
  const { userId } = useParams();
  const location = useLocation();
  const [state, setState] = useState(initialState);
  const [apiResult, setApiResult] = useState(initialResult);
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setState({
      ...state,
      [event.target.name]: event.target.value,
      isPublic: event.target.checked,
    });
  };
  //   const [viewOnly, setViewOnly] = useState(true);



  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const getCategory = async () => {
    const res = await getReq({ url: '/document/category/getallcategory' });
    if (!res.error) {
      setCategoryData(res.data);
    }
  };

  const getRole = getUserData();
  const role = getRole?._id;



  useEffect(() => {
    getCategory();
    if (params.id) {
      getCategory();
    }
  }, []);

  const createDocument = async (e) => {
    setLoading(true);
    setApiResult({ ...apiResult, loading: true, alert: true });
    e.preventDefault();
    const data = {
      title: state.title,
      isPublic: !state.isPublic,
    };

    if (state.description) data.description = state.description;
    // if (state.employee) data.employee = state.employee;

    const response = await postReq({
      url: '/document/documentcategory',
      data,
    });

    if (!response.error) {
      setState(initialState);
      setApiResult({
        ...apiResult,
        error: false,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.success(response.message);
    } else {
      setApiResult({
        ...apiResult,
        error: true,
        // message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.error(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      // window.location.reload(false);
    }, 3000);

    setLoading(false);
  };

  const { id } = useParams();
  // data.id = userId;

  const updateDocument = async (e) => {
    setLoading(true);
    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const data = { depId: params.id };
    if (state.title) data.title = state.title;
    if (state.description) data.description = state.description;
    if (state.isPublic) data.isPublic = state.isPublic;

    // only for making slug remove this
    data.slug = state.title;

    const response = await patchReq({
      url: `/document/documentcategory/respond/${id}`,
      data,
    });

    if (!response.error) {
      setApiResult({
        ...apiResult,
        error: false,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.success(response.message);
    } else {
      setApiResult({
        ...apiResult,
        error: true,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.error(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      setState(initialState);
    }, 3000);

    setLoading(false);
  };

  const documentSchema = Yup.object().shape({
    title: Yup.string().min(3, 'Please enter title').required('Title is required'),
    // date: Yup.date('Please enter valid date').required('Date is required'),
  });

  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    validationSchema: documentSchema,
    onChange: (e) => {
      console.log(e);
    },
    onSubmit: (values, { resetForm }) => {
      // console.log(values);

      updateDocument(values, resetForm);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;


  if (loading) {
    return (
      <Page title="Add Document Category">
        <Loading />
      </Page>
    );
  }

  return (
    <Page title="Add Document Category">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography
            variant="h4"
            gutterBottom
            sm="4"
            title={params.id ? 'Edit Document Category' : 'Add Document Category'}
            subtitle=" "
            className="text-sm-left"
          >
            {params.id ? 'Edit Document Category' : 'Add Document Category'}
          </Typography>

          <Button
            variant="text"
            component={RouterLink}
            to={`/dashboard/documents`}
            startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
            color={'primary'}
          >
            List Documents
          </Button>
        </Stack>
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={7}>
            <Card>
              <CardContent>
                <div>
                  <FormikProvider>
                    <Stack spacing={3}>
                      <Typography variant="h5" gutterBottom>
                        Basic Details
                      </Typography>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ justifyContent: 'center' }}>
                        <Stack spacing={2} style={{ marginBottom: '10px', marginTop: '10px' }}>
                          Public
                        </Stack>
                        <Stack spacing={2} style={{ marginBottom: '10px' }}>
                          <Checkbox {...label} checked={state.isPublic} onChange={handleCheckboxChange}>
                            .
                          </Checkbox>
                        </Stack>
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%', marginBottom: '10px' }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Title"
                            {...getFieldProps('title')}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%', marginBottom: '10px' }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Description"
                            {...getFieldProps('description')}
                            error={Boolean(touched.description && errors.description)}
                            helperText={touched.description && errors.description}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Stack>
                    </Stack>

                    <Button
                      className=" brand-buttons"
                      variant="contained"
                      color="primary"
                      onClick={createDocument}
                      style={{ marginTop: '10px' }}
                    >
                      Submit
                    </Button>
                  </FormikProvider>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {loading ? <Loading /> : null}
    </Page>
  );
}
