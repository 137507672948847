import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  Box,
  TableHead,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getReq } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';
import Loading from '../../components/loading';

export default function Location() {
  const [loading, setLoading] = useState(false);
  const [empList, setEmpList] = useState([]);

  const getData = async () => {
    setLoading(true);

    const res = await getReq({ url: '/location' });
    if (!res.error) {
      setEmpList(res.data);
    }
    setLoading(false);
  };
  console.log(empList);
  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <Page title="Location">
        <Loading />
      </Page>
    );
  }

  return (
    <Page title="Location">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Office Locations
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/location/addLocation"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Location
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.</TableCell>
                    <TableCell>Office</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {empList.map((row, index) => (
                    <TableRow hover key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.office}</TableCell>
                      <TableCell>{row.address}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          component={RouterLink}
                          to={`/dashboard/location/editLocation/${row._id}`}
                          startIcon={<Iconify icon="carbon:view" />}
                          color="warning"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
