/* eslint-disable no-nested-ternary */
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import {
  Stack,
  Checkbox,
  Container,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  TextField,
  MenuItem,
  Select,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Iconify from '../../components/Iconify';
// components

import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getReq } from '../../data/ApiReq';
import { getUserData } from '../../data/userData';
import SmallCalender from '../../components/calenders/smallCalender';
import HeatMap from '../../components/heatmap';

export default function YourAttendence() {
  const [searchParams, setSearchParams] = useSearchParams();
  let userId = searchParams.get('user');
  const userName = searchParams.get('userName');

  const today = new Date();

  const [calLoading, setCalLoading] = useState(false);
  const initState = {
    state: 'loading', // loading, success, error, empty
    month: today.getMonth() + 1,
    year: today.getFullYear(),
    day: today.getDate(),
    attendenceType: 'late',
    dayOfWeek: today.getDay(),
    date: today,
  };

  const [state, setState] = useState(initState);
  const [singleAttendance, setSingleAttendance] = useState({});
  const [heatMapData, setHeatmapData] = useState([]);
  const [selectedData, setSelectedData] = useState({
    data: {},
  });

  const [leaveData, setLeaveData] = useState({
    leaveType: [],
    leaveHistory: [],
    annualLeave: [],
    lopDays: 0,
    state: 'loading', // loading, success, error, empty
  });

  const [annualLeaveData, setAnnualLeaveData] = useState({
    noOfAvailableDays: 0,
    lastLeave: null,
    leaveIsInFuture: false,
  });

  const [attendenceData, setAttendenceData] = useState({
    attendanceData: [],
    underHrData: [],
    calEvents: [],
    checkInLoc: [25.204849, 55.270783],
    checkOutLoc: [25.205849, 55.270783],
    todaysData: {},
    mapLoc: [],
    state: 'loading', // loading, success, error, empty
  });

  const getLeaveData = async () => {
    if (!userId) {
      const userData = getUserData();

      if (!userData) {
        setLeaveData({ ...leaveData, state: 'error' });
        return null;
      }

      userId = userData._id;
    }

    const leaveRes = await getReq({
      url: `/leave/history/${userId}`,
    });

    if (leaveRes.error || leaveRes.statusCode !== 200) {
      setLeaveData({ ...leaveData, state: 'error' });
      return null;
    }

    if (leaveRes.data.length === 0) {
      setLeaveData({ ...leaveData, state: 'empty' });
      return null;
    }

    // filter leave data and remove type annual leave

    const leaveHistory = leaveRes.data.filter((item) => item.type !== 'Annual Leave');

    const annualLeave = leaveRes.data.filter((item) => item.type === 'Annual Leave');

    // get diffrent leave type and count them

    const leaveTypeObj = leaveHistory.reduce((acc, item) => {
      if (acc[item.type]) {
        acc[item.type] += 1;
      } else {
        acc[item.type] = 1;
      }
      return acc;
    }, {});

    // formate to array of object with type and count

    const leaveType = Object.keys(leaveTypeObj).map((item) => ({
      type: item,
      count: leaveTypeObj[item],
    }));

    // count LOP days from leave history

    const lopDays = leaveHistory.reduce((acc, item) => {
      if (item.isLop) {
        acc += 1;
      }
      return acc;
    }, 0);

    setLeaveData({
      leaveType,
      leaveHistory,
      annualLeave,
      lopDays,
      state: 'success',
    });
  };

  const getAnnualLeaveData = async () => {
    // const userData = getUserData();

    // if (!userData) {
    //   setAnnualLeaveData({ ...leaveData, state: 'error' });

    //   return null;
    // }

    // const userId = userData._id;
    let userId = searchParams.get('user');

    if (!userId) {
      const userData = getUserData();

      if (!userData) {
        setLeaveData({ ...leaveData, state: 'error' });
        return null;
      }

      userId = userData._id;
    }
    const annualLeaveRes = await getReq({ url: `/leave/annual/${userId}` });

    if (annualLeaveRes.error || annualLeaveRes.statusCode !== 200) {
      setAnnualLeaveData({ ...leaveData, state: 'error' });
      return null;
    }

    setAnnualLeaveData(annualLeaveRes.data);
  };

  function getTodaysLoc({ attendanceArr, date }) {
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    const todayData = attendanceArr.find(
      (item) => item.date?.dd === dd && item.date?.mm === mm && item.date?.yyyy === yyyy
    );

    const defaultValue = {
      checkInLoc: [25.1141166, 55.2369801],
      checkOutLoc: [25.1197492, 55.2376797],
      mapLoc: [25.116932900000002, 55.2373299, 16],
      todayData,
    };

    const checkInLoc = [todayData?.checkInLat, todayData?.checkInLng];
    const checkOutLoc = [todayData?.checkOutLat, todayData?.checkOutLng];

    // return if check in and check out location is not available

    if (!checkInLoc[0] || !checkInLoc[1] || !checkOutLoc[0] || !checkOutLoc[1]) {
      return defaultValue;
    }

    // find center location of check in and check out

    const mapLoc = [(checkInLoc[0] + checkOutLoc[0]) / 2, (checkInLoc[1] + checkOutLoc[1]) / 2];

    // find zoom level based on distance between check in and check out in linear scale

    // find optimal zoom level based on distance

    return { checkInLoc, checkOutLoc, mapLoc, todayData };
  }

  const getAttendenceData = async ({ month, year }) => {
    let userId = searchParams.get('user');

    if (!userId) {
      const userData = getUserData();

      if (!userData) {
        setAttendenceData({ ...attendenceData, state: 'error' });
        return null;
      }

      userId = userData._id;
    }

    setAttendenceData({ ...attendenceData, state: 'loading' });

    const mm = month || state.month;
    const yyyy = year || state.year;
    const attendenceRes = await getReq({
      url: `/attendance/user/monthly/${userId}?month=${mm}&year=${yyyy}`,
    });

    if (attendenceRes.error || attendenceRes.statusCode !== 200) {
      setAttendenceData({ ...attendenceData, state: 'error' });
      return null;
    }

    if (attendenceRes?.data?.attendanceData?.length === 0) {
      setAttendenceData({ ...attendenceData, state: 'empty' });
      return null;
    }

    const attendanceData = attendenceRes.data.attendanceData;

    // fill the attendance array with empty object if there is no attendance data for that day

    const attendanceArr = [];

    // const noOfDaysInMonth = new Date(state.year, state.month, 0).getDate();

    // get todays date when state date is  current month and year otherwise get last date of month

    const noOfDaysInMonth =
      state.month === today.getMonth() + 1 && state.year === today.getFullYear()
        ? today.getDate()
        : new Date(state.year, state.month, 0).getDate();

    for (let i = 1; i <= noOfDaysInMonth; i += 1) {
      const attendanceObj = attendanceData.find((item) => item.date?.dd === i);

      if (attendanceObj) {
        attendanceArr.push({
          ...attendanceObj,
          cDate: new Date(state.year, state.month - 1, i).toISOString(),
        });
      } else {
        attendanceArr.push({
          cDate: new Date(state.year, state.month - 1, i).toISOString(),
          checkIn: '',
          checkOut: '',
          checkInMins: 0,
          checkOutMins: 0,
          totalMins: 0,
          absent: true,
        });
      }
    }

    // formate attendance data to calender events { date: '2023/01/12', theame: 'blue'}

    const calEvents = attendanceArr.map((item) => {
      let type = 'green';
      const date = new Date(item.cDate);
      const dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      const mm = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const yyyy = date.getFullYear();

      if (item.absent) {
        type = 'red';
      } else if (item.checkInMins > 550 || item.checkOutMins < 1080) {
        type = 'yellow';
      }

      return {
        date: `${yyyy}/${mm}/${dd}`,
        theame: type,
      };
    });

    // get todays data from attendance data

    const { checkInLoc, checkOutLoc, mapLoc, todayData } = getTodaysLoc({ attendanceArr, date: today });

    // find under hour data ie check in time is after 9:10 am and check out time is before 6:00 pm or total time is less than 9 hours

    const underHrData = attendanceData.filter((item) => {
      if (item.checkInMins > 550 || item.checkOutMins < 1080) {
        return true;
      }

      return false;
    });

    setAttendenceData({
      attendanceData: attendanceArr.reverse(),
      underHrData,
      calEvents,
      state: 'success',
      checkInLoc,
      checkOutLoc,
      mapLoc,
      todayData,
    });
  };

  const onDateChange = (date) => {
    const dd = date?.dateObj?.dd;
    const mm = date?.dateObj?.mm;
    const yyyy = date?.dateObj?.yyyy;

    const newDate = new Date(yyyy, mm - 1, dd);

    setSelectedData(newDate);
    setState({
      ...state,
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
      day: newDate.getDate(),
      dayOfWeek: newDate.getDay(),
    });

    getSingleData({ dd, mm, yyyy });
  };

  const getHeatMapData = async () => {
    let userId = searchParams.get('user');

    if (!userId) {
      const userData = getUserData();
      userId = userData._id;
    }
    const heatmapRes = await getReq({ url: `/attendance/fullyear/single/${userId}` });

    if (heatmapRes.error || heatmapRes.statusCode !== 200) {
      return null;
    }

    setHeatmapData(heatmapRes.data);
  };

  const getSingleData = async ({ mm, dd, yyyy }) => {
    let userId = searchParams.get('user');

    if (!userId) {
      const userData = getUserData();
      userId = userData._id;
    }
    const month = mm || state.month;
    const day = dd || state.day;
    const year = yyyy || state.year;
    const dateStr = `${month}-${day}-${year}`;
    const singleRes = await getReq({ url: `/attendance/${dateStr}/${userId}` });

    if (singleRes.error || singleRes.statusCode !== 200) {
      return null;
    }

    setSingleAttendance(singleRes.data);
  };
  useEffect(() => {
    getLeaveData();
    getAttendenceData({});
    getAnnualLeaveData();
    getHeatMapData();
    getSingleData({});
  }, []);

  const formateDate = (date) => {
    const weekArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const d = new Date(date);

    // format date in dd/mm/yyyy if date is single digit then add 0 before it
    const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();

    const dayOfWeek = weekArr[d.getDay()];

    return ` ${dayOfWeek}, ${day}/${month}/${year}`;
  };

  const handleAttendancebtn = () => {
    if (state.attendenceType === 'late') {
      setState({ ...state, attendenceType: 'all' });
      return null;
    }

    setState({ ...state, attendenceType: 'late' });
    return null;
  };

  const attendanceList = state.attendenceType === 'late' ? attendenceData.underHrData : attendenceData.attendanceData;
  let totalTime = Math.round((singleAttendance?.totalTime / 60) * 100) / 100;

  if (Number.isNaN(totalTime)) {
    totalTime = 0;
  }

  const dayArr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const monthArr = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <>
      <Page title="Your Attendence">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              {userName ? `${userName}'s` : 'Your'} Attendence
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-around" gap={2}>
              <Button
                endIcon={<Iconify icon="mdi:document-sign" />}
                color="primary"
                size="large"
                variant="outlined"
                component={RouterLink}
                to="/dashboard/attendance/request"
              >
                Leave Requests
              </Button>
              <Button
                endIcon={<Iconify icon="material-symbols:add" />}
                color="primary"
                size="large"
                variant="outlined"
                component={RouterLink}
                to="/dashboard/leaves/apply"
              >
                Apply for Leave
              </Button>
            </Stack>
          </Stack>
          <Grid container spacing={3} mt={3} justifyContent="center" alignItems={'center'}>
            <Grid item xs={12} md={2}>
              <div
                className="sm-info-cards"
                style={{
                  textAlign: 'center',
                }}
              >
                <h1>
                  {monthArr[state?.month - 1]} {state?.day}
                </h1>
                <h2>
                  {dayArr[state?.dayOfWeek]}, {state.year}
                </h2>
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <div className="attendence-info-item">
                <div className="sm-info-card">
                  <h2>{singleAttendance?.checkInLocalTime}</h2>
                  <h5>Check In </h5>
                </div>
                <div className="sm-info-card">
                  <h2>{singleAttendance?.checkOutLocalTime}</h2>
                  <h5>Check Out </h5>
                </div>

                <div className="sm-info-card">
                  <h2>{totalTime} Hr</h2>
                  <h5>Total Time </h5>
                </div>

                <div className="sm-info-card">
                  <h4>{singleAttendance?.device}</h4>
                  <h5>Device </h5>
                </div>

                <div className="sm-info-card">
                  <h4>{singleAttendance?.office}</h4>
                  <h5>Check In Location </h5>
                </div>

                <div className="sm-info-card">
                  <h4>{singleAttendance?.checkOutOffice}</h4>
                  <h5> Check Out Location </h5>
                </div>
              </div>
            </Grid>
          </Grid>
          <HeatMap data={heatMapData} handleClick={onDateChange} />

          <Typography variant="h5" gutterBottom component="div">
            Leave History
          </Typography>
          <Grid container spacing={3} mb={5}>
            <Grid item xs={12} md={6}>
              <div className="dashbord-card fixed-h-table">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl.</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Loss of pay </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leaveData.leaveHistory.map((item, index) => (
                      <TableRow hover key={index} className={item.isLop ? 'box-red' : ''}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.type}</TableCell>
                        <TableCell>{formateDate(item?.leaveDates)}</TableCell>
                        <TableCell>{item.isLop ? 'LOP' : 'Paid'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack gap={3}>
                <div className="dashbord-card">
                  <List>
                    {leaveData.leaveType.map((item, index) => (
                      <div key={index}>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              {item?.type === 'Sick Leave' ? (
                                <Iconify icon="material-symbols:sick-outline" />
                              ) : (
                                <Iconify icon="mdi:medical-bag" />
                              )}
                            </ListItemIcon>
                            <ListItemText primary={item?.type} /> {item?.count}
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                      </div>
                    ))}

                    <ListItem disablePadding className="bg-red-gr">
                      <ListItemButton>
                        <ListItemIcon>
                          <Iconify icon="mdi:cash-remove" style={{ color: '#802626' }} />
                        </ListItemIcon>
                        <ListItemText primary="Loss Of Pay" />
                        {leaveData.lopDays}
                      </ListItemButton>
                    </ListItem>
                  </List>
                </div>
                <div className="dashbord-card">
                  <h4>Annual Leaves Details</h4>
                  <div className="annual-leave-details">
                    <span>
                      {annualLeaveData?.leaveIsInFuture ? (
                        <div style={{ textAlign: 'center' }}>
                          <h3>
                            {annualLeaveData?.lastLeave?.fromDateString} - {annualLeaveData?.lastLeave?.endDateString}{' '}
                          </h3>
                          <h4> {annualLeaveData?.lastLeave?.noOfDays} Days Approved</h4>
                        </div>
                      ) : (
                        <div>
                          <h2> {annualLeaveData?.noOfAvailableDays} Days</h2>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </Stack>
            </Grid>
          </Grid>

          {/* Attendance history */}

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            mb={3}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant="h5" gutterBottom component="div">
              Attendance History
            </Typography>

            <Button variant="outlined" color="primary" onClick={handleAttendancebtn}>
              {state.attendenceType === 'late' ? 'View All Data' : 'Late & Early Check Out'}
            </Button>
          </Stack>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={12}>
              <div className="dashbord-card">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl.</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Check In</TableCell>
                      <TableCell>Check Out </TableCell>
                      <TableCell>Loaction </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attendanceList.map((item, index) => {
                      if (item.absent) {
                        return (
                          <TableRow hover key={index} className="bg-red-gr">
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{formateDate(item?.cDate)}</TableCell>
                            <TableCell>ABSENT</TableCell>
                            <TableCell>ABSENT</TableCell>
                            <TableCell>ABSENT</TableCell>
                          </TableRow>
                        );
                      }
                      return (
                        <TableRow hover key={index} className={item.totalTime > 540 ? 'bg-green-gr' : ''}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{formateDate(item.checkInTime)}</TableCell>
                          <TableCell className={item.checkInMins > 550 ? 'bg-yellow-gr' : ''}>
                            {item?.checkInLocalTime}
                          </TableCell>
                          <TableCell
                            className={
                              !item.checkOutMins ? 'bg-red-gr' : item.checkOutMins < 1080 ? 'bg-yellow-gr' : ''
                            }
                          >
                            {item?.checkOutLocalTime}{' '}
                          </TableCell>
                          <TableCell>
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${item.checkInLat},${item.checkInLng}`}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: '#000', textDecoration: 'none' }}
                              // show help text
                              title="Click to open in Google Maps"
                            >
                              {item?.office?.slice(0, 35)}...
                            </a>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </Grid>
          </Grid>
          <div className="info-wrap">
            <div className="info-wrap">
              <div className="cal-info-green-dot" />
              <div className="cal-info-red">Work Time Completed </div>
            </div>
            <div className="info-wrap">
              <div className="cal-info-yellow-dot" />
              <div className="cal-info-red">Late </div>
            </div>
            <div className="info-wrap">
              <div className="cal-info-red-dot" />
              <div className="cal-info-red">No Data </div>
            </div>
          </div>
        </Container>
      </Page>
    </>
  );
}
