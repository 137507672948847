import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { formatDate } from '@fullcalendar/react';

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Container,
  Typography,
  TextField,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getReq } from '../../data/ApiReq';
import 'moment-timezone';
import Loading from '../../components/loading'


export default function Asset() {

  const initialState = {
    page: 1,
    limit: 20,
    totalCount: 0,
    totalPages: 0,
    hasNextPage: false,
    searchText: '',
    sort: '',
    qEdit: false,
  };


  // const [assetList, setAssetList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(initialState);
  const [filterState, setFilterState] = useState({});
  const [assetData, setAssetData] = useState([]);





  const formateDate = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const time = newDate.toLocaleTimeString();
    return (
      <div>
        {day}-{month}-{year} <br />
      </div>
    );
  };

  const getData = async (pg, lm, isSearch, filter) => {
    // setLoading(true);

    const p = pg || state.page;
    const l = lm || state.limit;

    let query = `page=${p}&limit=${l}`;
    if (isSearch) {
      query = `page=${p}&limit=${l}&searchText=${isSearch}`;
      if (filter) {
        query += `&${filter}`;
      }
      setFilterState({ ...filterState, searchText: isSearch });
    }
    if (filter) {
      query = `page=${p}&limit=${l}&${filter}`;
      if (filterState.searchText) {
        query += `&searchText=${filterState.searchText}`;
      }
    }
    const response = await getReq({ url: `/asset?${query}` });
    if (!response.error) {
      setAssetData(response.data);
      setState({
        ...state,
        page: response.pagination.page,
        limit: response.pagination.limit,
        totalCount: response.pagination.totalCount,
        totalPages: response.pagination.totalPages,
        hasNextPage: response.pagination.hasNextPage,
        qEdit: false,
      });
      // setLoading(false);
    }
  };

  const changePage = (event, value) => {
    setState({
      ...state,
      page: value,
    });
    getData(value, state.limit, filterState.searchText, filterState.query);
  };

  useEffect(() => {
    // getData();
    getData();
  }, []);

  if(loading){
    return(
      <Page title="Asset">
        <Loading/>
      </Page>
    )
  }

  const assetList = assetData;

  return (
    <Page title="Asset">
      <Container>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
      <Pagination count={state?.totalPages} color="primary" onChange={changePage} />

        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Assets
          </Typography>
          <Stack direction="row" alignItems="center">
          <TextField
          sx={{ mb: 3, mt: 3, width: '100%' }}
          id="outlined-basic"
          label="Search"
          size="small"
          variant="outlined"
          onChange={(e) =>
            // setState({ ...state, searchText: e.target.value })
            getData(1, state.limit, e.target.value)
          }
        />
          <Button
          sx={{marginLeft: 2, width: '60%'}}
            variant="contained"
            component={RouterLink}
            to="/dashboard/asset/addasset"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Asset
          </Button>
          </Stack>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.</TableCell>
                    <TableCell>Device Name</TableCell>
                    <TableCell>Device ID</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assetList.map((row, index) => (
                      <TableRow hover key={index}>
                        <TableCell>{state.limit * state.page + index+ 1 - state.limit}</TableCell>
                        <TableCell>{row.deviceName}</TableCell>
                        <TableCell>{row.serialNo}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            component={RouterLink}
                            // to={`/dashboard/asset/single/${row._id}`}
                            to={`/dashboard/asset/singleasset/${row._id}`}
                            startIcon={<Iconify icon="carbon:view" />}
                            color="warning"
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>                
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
