import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
// @mui
import { styled } from '@mui/material/styles';
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert,
  Container,
  Typography,
  Modal,
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  MenuItem,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Loading from '../../components/loading';
import ApiUrl from '../../data/ApiUrl';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { postReq, getReq, patchReq, deleteRequest } from '../../data/ApiReq';
import { getUserRole } from '../../data/userData';
import { fDate } from '../../utils/formatTime';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function EditEmployee() {
  const navigate = useNavigate();
  const userRole = getUserRole();
  const { userId } = useParams();
  const initialValues = {
    firstName: '',
    lastName: '',
    employeeId: '',
    image: '',
    email: '',
    phone: '',
    password: '',
    company: '',
    location: '',
    role: '',
    lineManager: '',
    department: '',
    remember: true,
    gender: 'Male',
    educationalAttainment: '',
    functions: '',
    officeTiming: '',
    languagesSpoken: '',
    landlineNumber: '',
    landlineHome: '',
    mobileHome: '',
    homeCountryAddress: '',
    placeOfBirth: '',
    religion: '',
    father: '',
    mother: '',
    spouse: '',
    noOfChildren: '',
    disability: ' ',
    medicalSupervision: '',
    offerLetterDate: '',
    medical: '',
    visaUID: '',
    visaSponsor: '',
    copyProvide: '',
    matStatus: 'Unmarried',
  };
  const initialState = {
    firstName: '',
    lastName: '',
    employeeId: '',
    image: '',
    email: '',
    phone: '',
    password: '',
    company: '',
    location: '',
    role: '',
    lineManager: '',
    department: '',
    gender: 'Male',
    educationalAttainment: '',
    functions: '',
    officeTiming: '',
    languagesSpoken: '',
    landlineNumber: '',
    landlineHome: '',
    mobileHome: '',
    homeCountryAddress: '',
    placeOfBirth: '',
    religion: '',
    father: '',
    mother: '',
    spouse: '',
    noOfChildren: '',
    disability: ' ',
    medicalSupervision: '',
    offerLetterDate: '',
    medical: '',
    visaUID: '',
    visaSponsor: '',
    copyProvide: '',
    matStatus: 'Unmarried',

    remember: true,
  };
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };
  const [state, setState] = useState(initialState);
  const [viewOnly, setViewOnly] = useState(true);
  const [userData, setUserData] = useState(initialValues);
  const [imgAddress, setImgAddress] = useState(null);
  const [newImgId, setNewImgId] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [officeTimingData, setOfficeTimingData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [managersData, setManagersData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [apiResult, setApiResult] = useState(initialResult);

  const updateUserState = async (e, resetForm) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const data = {
      status: state.status,
    };
    data.id = userId;
    if (state.status) data.status = state.status;
    const response = await deleteRequest({
      url: `/user/disableuser?id=${userId}`,
      data,
    });

    if (!response.error) {
      setOpen(false);
      setState(initialValues);
      setApiResult({
        ...apiResult,
        error: false,
        message: response.message,
        alert: true,
        loading: false,
      });
      toast.success('User Disabled');
    } else {
      toast.error(response.message);
      setApiResult({
        ...apiResult,
        error: true,
        message: response.message,
        alert: true,
        loading: false,
      });
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      setState(initialValues);
    }, 3000);

    setLoading(false);
  };

  const disableUser = async (e) => {
    e.preventDefault();
    const response = await patchReq({
      url: `/user/offboarding/${userId}`,
    });

    if (!response.error) {
      toast.success('User Disabled');
      // redirect to users page
      navigate('/dashboard/employees', { replace: true });
    } else {
      toast.error(response.message);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const UserSchema = Yup.object().shape({
    firstName: Yup.string().min(3, 'Please enter a valid name').required('First Name is required'),
    lastName: Yup.string().min(3, 'Please enter a valid name').required('Last Name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    employeeId: Yup.string().min(3, 'Please enter a valid Employee ID').required('Employee ID is required'),
    phone: Yup.string().min(9, 'Please enter a valid Phone No').required('Phone Number is required'),
  });

  const formik = useFormik({
    initialValues: userData,
    enableReinitialize: true,
    validationSchema: UserSchema,
    // onChange: (e) => {
    //   console.log(e);
    // },
    onSubmit: (values, { resetForm }) => {
      // console.log(values);

      updateUser(values, resetForm);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const getUserData = async () => {
    setLoading(true);

    const response = await getReq({ url: `/user/getsingleuser?id=${userId}` });

    if (response.data) {
      const { data } = response;
      // const today = new Date();

      // Formating the date for the date picker
      if (data.dob) data.dob = moment(new Date(data.dob)).format('YYYY-MM-DD');
      if (data.doj) data.doj = moment(new Date(data.doj)).format('YYYY-MM-DD');
      if (data.passportValidity) data.passportValidity = moment(new Date(data.passportValidity)).format('YYYY-MM-DD');
      if (data.EIdValidity) data.EIdValidity = moment(new Date(data.EIdValidity)).format('YYYY-MM-DD');
      if (data.molCardValidity) data.molCardValidity = moment(new Date(data.molCardValidity)).format('YYYY-MM-DD');
      if (data.drLicenceValidity)
        data.drLicenceValidity = moment(new Date(data.drLicenceValidity)).format('YYYY-MM-DD');
      if (data.visaValidity) data.visaValidity = moment(new Date(data.visaValidity)).format('YYYY-MM-DD');
      if (data.insValidity) data.insValidity = moment(new Date(data.insValidity)).format('YYYY-MM-DD');
      if (data.company) data.company = data.company._id;
      if (data.location) data.location = data.location._id;
      if (data.officeTiming) data.officeTiming = data.officeTiming._id;
      if (data.department) data.department = data.department._id;
      if (data.image) setImgAddress(`${ApiUrl.AWS_Bucket_url}/public/employee/${data?.image?.ogUrl}`);

      if (data.lineManager) data.lineManager = data?.lineManager?._id;

      setUserData(data);
    }

    const response2 = await getReq({ url: `/location` });
    if (!response2.error) {
      setLocationData(response2.data);
    }

    const response3 = await getReq({ url: `/user/getalluser?role=admin,lineManager` });
    if (!response3.error) {
      setManagersData(response3.data);
    }

    const response4 = await getReq({ url: `/company` });
    if (!response4.error) {
      setCompanyData(response4.data);
    }

    const response5 = await getReq({ url: `/department` });
    if (!response5.error) {
      setDepartmentData(response5.data);
    }

    const response6 = await getReq({ url: `/officetimings` });
    if (!response6.error) {
      setOfficeTimingData(response6.data);
    }

    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
    setState(initialValues);
  };

  const handleOpen = () => setOpen(true);

  useEffect(() => {
    getUserData();
    setImgAddress(`/static/user-avatar/user_avatar_${randomNo()}.png`);
  }, []);

  function removeEmpty(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v !== ''));
  }

  const updateUser = async (values, resetForm) => {
    const data = values;
    data.id = userId;
    delete data.image;
    // console.log('valuess-->', data);

    if (newImgId) data.image = newImgId;

    const cleanedData = removeEmpty(data);
    // console.log('cleanedData', cleanedData);

    const res = await patchReq({ url: '/user', data: cleanedData });

    // console.log('updateuseer====================>', res);
    if (!res.error) {
      toast.success(' User Updated ');
      resetForm();
      getUserData();
      setViewOnly(true);
    } else {
      toast.error(res.message);
    }
  };

  const fileUpload = async (e) => {
    const file = e.target.files[0];
    const data = new FormData();
    data.append('image', file);
    data.append('type', 'employee');

    const response = await postReq({
      url: 'image/upload',
      data,
    });
    // console.log('image response', response);
    // if check
    if (!response.error) {
      const image = response.data._id;
      const imageData = response.data;
      setImgAddress(URL.createObjectURL(file));
      setState({
        ...state,
        image,
        imgUrl: imageData.ogUrl,
      });
      setNewImgId(image);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  function randomNo() {
    // min and max included
    const max = 8;
    const min = 1;
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  if (loading) {
    return (
      <Page title="Add New Employee">
        <Loading />
      </Page>
    );
  }

  return (
    <Page title={`${userData.firstName}`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            {userData.firstName} {userData.lastName}
          </Typography>

          <Stack direction="row" alignItems="center" gap={3} mb={3}>
            {/* Sumit formic form */}
            <Button fullWidth type="submit" variant="contained" disabled={viewOnly} onClick={handleSubmit}>
              Update
            </Button>
            <Button
              variant="contained"
              onClick={() => setViewOnly(!viewOnly)}
              startIcon={<Iconify icon={viewOnly ? 'bx:edit-alt' : 'icons8:cancel'} />}
              color={viewOnly ? 'primary' : 'warning'}
              fullWidth
            >
              {viewOnly ? 'Edit' : 'Cancel'}
            </Button>
          </Stack>
        </Stack>

        <div>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2} justifyContent="center">
                {/* Basic Details ⬇️ */}
                <Grid item xs={12} md={7}>
                  <Card>
                    <CardContent>
                      <Stack spacing={3}>
                        <Typography variant="h5" gutterBottom>
                          Basic Details
                        </Typography>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                          <div className="img-pre-wrap">
                            <img className="img-upload-pre" src={imgAddress} alt="employee avathar" />
                            {viewOnly ? null : (
                              <Button
                                variant="contained"
                                component="label"
                                onChange={fileUpload}
                                className="upload-btn"
                              >
                                Change Image
                                <input required hidden type="file" />
                              </Button>
                            )}
                          </div>
                          <Stack spacing={2} style={{ width: '80%' }}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              disabled={viewOnly}
                              fullWidth
                              label="First name"
                              {...getFieldProps('firstName')}
                              error={Boolean(touched.firstName && errors.firstName)}
                              helperText={touched.firstName && errors.firstName}
                            />

                            <TextField
                              InputLabelProps={{ shrink: true }}
                              disabled={viewOnly}
                              fullWidth
                              label="Last name"
                              {...getFieldProps('lastName')}
                              error={Boolean(touched.lastName && errors.lastName)}
                              helperText={touched.lastName && errors.lastName}
                            />
                            {userRole === 'admin' || userRole === 'hr' ? (
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                disabled={viewOnly}
                                fullWidth
                                type="text"
                                label="Employee ID"
                                {...getFieldProps('employeeId')}
                                error={Boolean(touched.employeeId && errors.employeeId)}
                                helperText={touched.employeeId && errors.employeeId}
                              />
                            ) : null}
                          </Stack>
                        </Stack>

                        {userRole === 'admin' || userRole === 'hr' ? (
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              disabled={viewOnly}
                              select
                              fullWidth
                              label="Company"
                              {...getFieldProps('company')}
                              error={Boolean(touched.company && errors.company)}
                              helperText={touched.company && errors.company}
                            >
                              {companyData.map((option, key) => (
                                <MenuItem key={key} value={option._id}>
                                  {option.companyName}
                                </MenuItem>
                              ))}
                            </TextField>

                            <TextField
                              InputLabelProps={{ shrink: true }}
                              disabled={viewOnly}
                              fullWidth
                              select
                              label="Location"
                              {...getFieldProps('location')}
                              error={Boolean(touched.location && errors.location)}
                              helperText={touched.location && errors.location}
                            >
                              {locationData.map((option, key) => (
                                <MenuItem key={key} value={option._id}>
                                  {option.office}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Stack>
                        ) : null}
                        {userRole === 'admin' || userRole === 'hr' ? (
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              disabled={viewOnly}
                              fullWidth
                              select
                              label="Manager"
                              {...getFieldProps('lineManager')}
                              error={Boolean(touched.lineManager && errors.lineManager)}
                              helperText={touched.lineManager && errors.lineManager}
                            >
                              {managersData.map((option, key) => (
                                <MenuItem key={key} value={option._id}>
                                  {option.firstName} {option.lastName}
                                </MenuItem>
                              ))}
                            </TextField>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              disabled={viewOnly}
                              fullWidth
                              select
                              label="Department"
                              {...getFieldProps('department')}
                              error={Boolean(touched.department && errors.department)}
                              helperText={touched.department && errors.department}
                            >
                              {departmentData.map((option, key) => (
                                <MenuItem key={key} value={option._id}>
                                  {option.title}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Stack>
                        ) : null}
                        {userRole === 'admin' || userRole === 'hr' ? (
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              disabled={viewOnly}
                              select
                              fullWidth
                              label="Role"
                              {...getFieldProps('role')}
                              error={Boolean(touched.role && errors.role)}
                              helperText={touched.role && errors.role}
                            >
                              <MenuItem value="lineManager">Line Manager</MenuItem>

                              <MenuItem value="hr">HR</MenuItem>
                              <MenuItem value="admin">Admin</MenuItem>
                              <MenuItem value="finance">Finance</MenuItem>
                              <MenuItem value="employee">Employee</MenuItem>
                              <MenuItem value="it">IT</MenuItem>
                              <MenuItem value="officeAdmin">Office Admin</MenuItem>
                            </TextField>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              disabled={viewOnly}
                              fullWidth
                              type="text"
                              label="Access ID"
                              {...getFieldProps('accessId')}
                            />
                          </Stack>
                        ) : null}
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={viewOnly}
                            fullWidth
                            type="email"
                            label="Email address"
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                          />

                          <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={viewOnly}
                            fullWidth
                            type="number"
                            label="Phone Number"
                            {...getFieldProps('phone')}
                            error={Boolean(touched.phone && errors.phone)}
                            helperText={touched.phone && errors.phone}
                          />
                        </Stack>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="UAE Address"
                          {...getFieldProps('uaeAddress')}
                          multiline
                          rows={4}
                          error={Boolean(touched.uaeAddress && errors.uaeAddress)}
                          helperText={touched.uaeAddress && errors.uaeAddress}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Other Details ⬇️ */}
                <Grid item xs={12} md={5}>
                  <Card style={{ minHeight: '100%' }}>
                    <CardContent>
                      <Stack spacing={2}>
                        <Typography variant="h5" gutterBottom>
                          Other Details
                        </Typography>
                        {userRole === 'admin' || userRole === 'hr' ? (
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={viewOnly}
                            type="date"
                            label="Date of joining"
                            {...getFieldProps('doj')}
                            error={Boolean(touched.doj && errors.doj)}
                            helperText={touched.doj && errors.doj}
                          />
                        ) : null}
                        {userRole === 'admin' || userRole === 'hr' ? (
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={viewOnly}
                            fullWidth
                            label="Designation"
                            {...getFieldProps('designation')}
                            error={Boolean(touched.designation && errors.designation)}
                            helperText={touched.designation && errors.designation}
                          />
                        ) : null}
                        {userRole === 'admin' || userRole === 'hr' ? (
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={viewOnly}
                            fullWidth
                            label="Functions (Job Description)"
                            {...getFieldProps('functions')}
                            error={Boolean(touched.functions && errors.functions)}
                            helperText={touched.functions && errors.functions}
                          />
                        ) : null}
                        {userRole === 'admin' || userRole === 'hr' ? (
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              disabled={viewOnly}
                              select
                              fullWidth
                              label="Office Timings"
                              {...getFieldProps('officeTiming')}
                              error={Boolean(touched.officeTiming && errors.officeTiming)}
                              helperText={touched.officeTiming && errors.officeTiming}
                            >
                              {officeTimingData.map((option, key) => (
                                <MenuItem key={key} value={option._id}>
                                  {option.title}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Stack>
                        ) : null}
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Nationality"
                          {...getFieldProps('nationality')}
                          error={Boolean(touched.nationality && errors.nationality)}
                          helperText={touched.nationality && errors.nationality}
                        />

                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Educational Attainment"
                          {...getFieldProps('educationalAttainment')}
                          error={Boolean(touched.educationalAttainment && errors.educationalAttainment)}
                          helperText={touched.educationalAttainment && errors.educationalAttainment}
                        />

                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Languages Spoken"
                          {...getFieldProps('languagesSpoken')}
                          error={Boolean(touched.languagesSpoken && errors.languagesSpoken)}
                          helperText={touched.languagesSpoken && errors.languagesSpoken}
                        />

                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Landline Number"
                          {...getFieldProps('landlineNumber')}
                          error={Boolean(touched.landlineNumber && errors.landlineNumber)}
                          helperText={touched.landlineNumber && errors.landlineNumber}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Landline (Home Country)"
                          {...getFieldProps('landlineHome')}
                          error={Boolean(touched.landlineHome && errors.landlineHome)}
                          helperText={touched.landlineHome && errors.landlineHome}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Mobile (Home Country)"
                          {...getFieldProps('mobileHome')}
                          error={Boolean(touched.mobileHome && errors.mobileHome)}
                          helperText={touched.mobileHome && errors.mobileHome}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Personal Details ⬇️` */}
                <Grid item xs={12} md={4}>
                  <Card style={{ minHeight: '100%' }}>
                    <CardContent>
                      <Stack spacing={2}>
                        <Typography variant="h5" gutterBottom>
                          Personal Details
                        </Typography>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          type="date"
                          label="Date of Birth"
                          // value="1998-05-29"
                          {...getFieldProps('dob')}
                          error={Boolean(touched.dob && errors.dob)}
                          helperText={touched.dob && errors.dob}
                        />

                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          select
                          label="Gender"
                          {...getFieldProps('gender')}
                          error={Boolean(touched.gender && errors.gender)}
                          helperText={touched.gender && errors.gender}
                        >
                          <MenuItem value="Male">Male</MenuItem>

                          <MenuItem value="Female">Female</MenuItem>
                        </TextField>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          select
                          label="Maritial Status"
                          {...getFieldProps('matStatus')}
                          error={Boolean(touched.matStatus && errors.matStatus)}
                          helperText={touched.matStatus && errors.matStatus}
                        >
                          <MenuItem value="Married">Married</MenuItem>
                          <MenuItem value="Unmarried">Unmarried</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </TextField>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Personal Email ID"
                          {...getFieldProps('persEmail')}
                          error={Boolean(touched.persEmail && errors.persEmail)}
                          helperText={touched.persEmail && errors.persEmail}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Address (Home Country)"
                          {...getFieldProps('homeCountryAddress')}
                          multiline
                          rows={4}
                          error={Boolean(touched.homeCountryAddress && errors.homeCountryAddress)}
                          helperText={touched.homeCountryAddress && errors.homeCountryAddress}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Place of Birth"
                          {...getFieldProps('placeOfBirth')}
                          error={Boolean(touched.placeOfBirth && errors.placeOfBirth)}
                          helperText={touched.placeOfBirth && errors.placeOfBirth}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Religion/Belief"
                          {...getFieldProps('religion')}
                          error={Boolean(touched.religion && errors.religion)}
                          helperText={touched.religion && errors.religion}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Father`s Name"
                          {...getFieldProps('father')}
                          error={Boolean(touched.father && errors.father)}
                          helperText={touched.father && errors.father}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Mother`s Name"
                          {...getFieldProps('mother')}
                          error={Boolean(touched.mother && errors.mother)}
                          helperText={touched.mother && errors.mother}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Name of Spouse"
                          {...getFieldProps('spouse')}
                          error={Boolean(touched.spouse && errors.spouse)}
                          helperText={touched.spouse && errors.spouse}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="No of Children"
                          {...getFieldProps('noOfChildren')}
                          error={Boolean(touched.noOfChildren && errors.noOfChildren)}
                          helperText={touched.noOfChildren && errors.noOfChildren}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Document Details ⬇️ */}
                <Grid item xs={12} md={8}>
                  <Card style={{ minHeight: '100%' }}>
                    <CardContent>
                      <Stack spacing={2}>
                        <Typography variant="h5" gutterBottom>
                          Document Details
                        </Typography>
                        <Stack direction={'row'} spacing={2}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={viewOnly}
                            fullWidth
                            label="Passport No"
                            {...getFieldProps('passportNo')}
                            error={Boolean(touched.passportNo && errors.passportNo)}
                            helperText={touched.passportNo && errors.passportNo}
                            style={{ width: '60%' }}
                          />
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={viewOnly}
                            type="date"
                            label="Passport Validity"
                            {...getFieldProps('passportValidity')}
                            error={Boolean(touched.passportValidity && errors.passportValidity)}
                            helperText={touched.passportValidity && errors.passportValidity}
                            style={{ width: '40%' }}
                          />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={viewOnly}
                            fullWidth
                            label="Emirates ID No"
                            {...getFieldProps('EIdNo')}
                            error={Boolean(touched.EIdNo && errors.EIdNo)}
                            helperText={touched.EIdNo && errors.EIdNo}
                            style={{ width: '60%' }}
                          />
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={viewOnly}
                            type="date"
                            label="Emirates ID Validity"
                            {...getFieldProps('EIdValidity')}
                            error={Boolean(touched.EIdValidity && errors.EIdValidity)}
                            helperText={touched.EIdValidity && errors.EIdValidity}
                            style={{ width: '40%' }}
                          />
                        </Stack>

                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          type="date"
                          fullWidth
                          label="Driving Licence Validity"
                          {...getFieldProps('drLicenceValidity')}
                          error={Boolean(touched.drLicenceValidity && errors.drLicenceValidity)}
                          helperText={touched.drLicenceValidity && errors.drLicenceValidity}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Bank Name"
                          {...getFieldProps('bankName')}
                          error={Boolean(touched.bankName && errors.bankName)}
                          helperText={touched.bankName && errors.bankName}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Bank Account No."
                          {...getFieldProps('bankAccNo')}
                          error={Boolean(touched.bankAccNo && errors.bankAccNo)}
                          helperText={touched.bankAccNo && errors.bankAccNo}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="IBAN No."
                          {...getFieldProps('ibanNo')}
                          error={Boolean(touched.ibanNo && errors.ibanNo)}
                          helperText={touched.ibanNo && errors.ibanNo}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Medical Insurance"
                          {...getFieldProps('medical')}
                          error={Boolean(touched.medical && errors.medical)}
                          helperText={touched.medical && errors.medical}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Visa UID"
                          {...getFieldProps('visaUID')}
                          error={Boolean(touched.visaUID && errors.visaUID)}
                          helperText={touched.visaUID && errors.visaUID}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Visa Sponsor (RN/Self)"
                          {...getFieldProps('visaSponsor')}
                          error={Boolean(touched.visaSponsor && errors.visaSponsor)}
                          helperText={touched.visaSponsor && errors.visaSponsor}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Copy Provide"
                          {...getFieldProps('copyProvide')}
                          error={Boolean(touched.copyProvide && errors.copyProvide)}
                          helperText={touched.copyProvide && errors.copyProvide}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Emergency Contact - UAE ⬇️ */}
                <Grid item xs={12} md={6}>
                  <Card style={{ minHeight: '100%' }}>
                    <CardContent>
                      <Stack spacing={2}>
                        <Typography variant="h5" gutterBottom>
                          Emergency Contact - UAE
                        </Typography>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          type="text"
                          label="Contact Person Name"
                          {...getFieldProps('contactNameUae')}
                          error={Boolean(touched.contactNameUae && errors.contactNameUae)}
                          helperText={touched.contactNameUae && errors.contactNameUae}
                        />

                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={viewOnly}
                            fullWidth
                            label="Relationship"
                            {...getFieldProps('relationShipUae')}
                            error={Boolean(touched.relationShipUae && errors.relationShipUae)}
                            helperText={touched.relationShipUae && errors.relationShipUae}
                          />

                          <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={viewOnly}
                            fullWidth
                            type="number"
                            label="Phone Number"
                            {...getFieldProps('contactUaeNo')}
                            error={Boolean(touched.contactUaeNo && errors.contactUaeNo)}
                            helperText={touched.contactUaeNo && errors.contactUaeNo}
                          />
                        </Stack>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Address"
                          {...getFieldProps('contactUaeAddress')}
                          multiline
                          rows={4}
                          error={Boolean(touched.contactUaeAddress && errors.contactUaeAddress)}
                          helperText={touched.contactUaeAddress && errors.contactUaeAddress}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Emergency Contact - Home Country⬇️ */}
                <Grid item xs={12} md={6}>
                  <Card style={{ minHeight: '100%' }}>
                    <CardContent>
                      <Stack spacing={2}>
                        <Typography variant="h5" gutterBottom>
                          Emergency Contact - Home Country
                        </Typography>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          type="text"
                          label="Contact Person Name"
                          {...getFieldProps('contactNameHome')}
                          error={Boolean(touched.contactNameHome && errors.contactNameHome)}
                          helperText={touched.contactNameHome && errors.contactNameHome}
                        />

                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={viewOnly}
                            fullWidth
                            label="Relationship"
                            {...getFieldProps('relationShipHome')}
                            error={Boolean(touched.relationShipHome && errors.relationShipHome)}
                            helperText={touched.relationShipHome && errors.relationShipHome}
                          />

                          <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={viewOnly}
                            fullWidth
                            type="number"
                            label="Phone Number"
                            {...getFieldProps('contactHomeNo')}
                            error={Boolean(touched.contactHomeNo && errors.contactHomeNo)}
                            helperText={touched.contactHomeNo && errors.contactHomeNo}
                          />
                        </Stack>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Address"
                          {...getFieldProps('contactHomeAddress')}
                          multiline
                          rows={4}
                          error={Boolean(touched.contactHomeAddress && errors.contactHomeAddress)}
                          helperText={touched.contactHomeAddress && errors.contactHomeAddress}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                {userRole === 'admin' || userRole === 'hr' ? (
                  <>
                    {/* Employement Details Section ⬇️ */}
                    <Grid item xs={12} md={6}>
                      <Card style={{ minHeight: '100%' }}>
                        <CardContent>
                          <Stack spacing={2}>
                            <Typography variant="h5" gutterBottom>
                              Employement Details
                            </Typography>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                              {/* <TextField
                                InputLabelProps={{ shrink: true }}
                                disabled={viewOnly}
                                fullWidth
                                label="Company"
                                {...getFieldProps('company')}
                                error={Boolean(touched.company && errors.company)}
                                helperText={touched.company && errors.company}
                              /> */}

                              <TextField
                                InputLabelProps={{ shrink: true }}
                                disabled={viewOnly}
                                fullWidth
                                label="Type of Contract"
                                {...getFieldProps('typeOfContract')}
                                error={Boolean(touched.typeOfContract && errors.typeOfContract)}
                                helperText={touched.typeOfContract && errors.typeOfContract}
                              />
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                disabled={viewOnly}
                                fullWidth
                                label="MOL Card No."
                                {...getFieldProps('molCardNo')}
                                error={Boolean(touched.molCardNo && errors.molCardNo)}
                                helperText={touched.molCardNo && errors.molCardNo}
                              />

                              <TextField
                                InputLabelProps={{ shrink: true }}
                                disabled={viewOnly}
                                type="date"
                                fullWidth
                                label="MOL Card Validity"
                                {...getFieldProps('molCardValidity')}
                                error={Boolean(touched.molCardValidity && errors.molCardValidity)}
                                helperText={touched.molCardValidity && errors.molCardValidity}
                              />
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                disabled={viewOnly}
                                fullWidth
                                label="Visa Status"
                                {...getFieldProps('visaStatus')}
                                error={Boolean(touched.visaStatus && errors.visaStatus)}
                                helperText={touched.visaStatus && errors.visaStatus}
                              />

                              <TextField
                                InputLabelProps={{ shrink: true }}
                                type="date"
                                disabled={viewOnly}
                                fullWidth
                                label="Visa Validity"
                                {...getFieldProps('visaValidity')}
                                error={Boolean(touched.visaValidity && errors.visaValidity)}
                                helperText={touched.visaValidity && errors.visaValidity}
                              />
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                disabled={viewOnly}
                                fullWidth
                                label="Ins. Card No."
                                {...getFieldProps('insCardNo')}
                                error={Boolean(touched.insCardNo && errors.insCardNo)}
                                helperText={touched.insCardNo && errors.insCardNo}
                              />

                              <TextField
                                InputLabelProps={{ shrink: true }}
                                disabled={viewOnly}
                                type="date"
                                fullWidth
                                label="Insurance Validity"
                                {...getFieldProps('insValidity')}
                                error={Boolean(touched.insValidity && errors.insValidity)}
                                helperText={touched.insValidity && errors.insValidity}
                              />
                            </Stack>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                    {/* Salary section ⬇️ */}
                    <Grid item xs={12} md={6}>
                      <Card style={{ minHeight: '100%' }}>
                        <CardContent>
                          <Stack spacing={2}>
                            <Typography variant="h5" gutterBottom>
                              Salary Details
                            </Typography>

                            <table>
                              <tbody>
                                <tr>
                                  <td>Basic Salary</td>
                                  <td>{userData?.salary?.basic}</td>
                                </tr>
                                <tr>
                                  <td>HRA.</td>
                                  <td>{userData?.salary?.hra}</td>
                                </tr>
                                <tr>
                                  <td>Tell A.</td>
                                  <td>{userData?.salary?.tellA}</td>
                                </tr>
                                <tr>
                                  <td>Travel A.</td>
                                  <td>{userData?.salary?.travA}</td>
                                </tr>
                                <tr>
                                  <td>Transport A.</td>
                                  <td>{userData?.salary?.transportA}</td>
                                </tr>
                                <tr>
                                  <td>Other A.</td>
                                  <td>{userData?.salary?.otherA}</td>
                                </tr>
                                <tr>
                                  <td>Gross</td>
                                  <td>{userData?.salary?.gross}</td>
                                </tr>
                                <tr>
                                  <td>From</td>
                                  <td>
                                    {userData?.salary?.startingDate
                                      ? fDate(userData?.salary?.startingDate)
                                      : fDate(Date())}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <Button
                              variant="text"
                              component={RouterLink}
                              to={`/dashboard/employees/salary/${userId}/${userData?.salary?._id}`}
                              startIcon={<Iconify icon={'majesticons:edit-pen-2'} />}
                              color={'primary'}
                            >
                              Edit Salary
                            </Button>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                  </>
                ) : null}

                <Grid item xs={12} md={12}>
                  <Card style={{ minHeight: '100%' }}>
                    <CardContent>
                      <Stack spacing={2}>
                        {/* <Typography variant="h5" gutterBottom>
                              Salary Details
                            </Typography> */}

                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Do you consider yourself to have a disability?"
                          {...getFieldProps('disability')}
                          error={Boolean(touched.disability && errors.disability)}
                          helperText={touched.disability && errors.disability}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          label="Are you on any medication or medical supervision?"
                          {...getFieldProps('medicalSupervision')}
                          error={Boolean(touched.medicalSupervision && errors.medicalSupervision)}
                          helperText={touched.medicalSupervision && errors.medicalSupervision}
                        />

                        <TextField
                          InputLabelProps={{ shrink: true }}
                          disabled={viewOnly}
                          fullWidth
                          type="date"
                          label="Offer Letter Date"
                          {...getFieldProps('offerLetterDate')}
                          error={Boolean(touched.offerLetterDate && errors.offerLetterDate)}
                          helperText={touched.offerLetterDate && errors.offerLetterDate}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                  <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={viewOnly}>
                    Update
                  </LoadingButton>
                </Grid>
                <Grid md={12}>
                  <Grid item xs={12} md={2}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      variant="contained"
                      disabled={viewOnly}
                      color="error"
                      onClick={handleOpen}
                    >
                      Disable User
                    </LoadingButton>
                  </Grid>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <div>
                        <div>
                          <h3>WANT TO DISABLE THE USER?</h3>
                        </div>
                        <Box sx={{ marginTop: 2 }}>
                          <Button sx={{ marginRight: 1 }} onClick={handleClose}>
                            NO
                          </Button>
                          <Button onClick={disableUser} variant="contained" color="error">
                            YES
                          </Button>
                        </Box>
                      </div>
                    </Box>
                  </Modal>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </div>
      </Container>
    </Page>
  );
}
