import React from 'react';
import ReactLoading from 'react-loading';

const Example = () => (
  <div className="react-loading">
    <ReactLoading type={'spin'} color="#2065D1" />
  </div>
);

export default Example;
