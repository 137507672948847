import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import { max } from 'lodash';

import {
  Card,
  Stack,
  Avatar,
  Button,
  Container,
  Typography,
  Grid,
  Item,
  Box,
  Link,
  Divider,
  CardHeader,
} from '@mui/material';

import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';
import Loading from '../../components/loading';
import { getUserData } from '../../data/userData';

import { getReq } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';

export default function News() {
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const getRole = getUserData();
  const role = getRole?.role;

  const getData = async () => {
    setLoading(true);

    const res = await getReq({ url: '/news/companyDetails' });
    if (!res.error) {
      setNewsList(res.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <Page title="Company Details">
        <Loading />
      </Page>
    );
  }

  return (
    <Page title="Company Details">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Company Details
          </Typography>

          {role === 'admin' || role === 'hr' ? (
            <>
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/companyDetails/addCompanyDetails"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Company Details
              </Button>
            </>
          ) : (
            <></>
          )}
        </Stack>

        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {newsList.map((item, key) => (
            <Grid item xs={12} key={key}>
              <Link
                style={{ textDecoration: 'none' }}
                component={RouterLink}
                to={`/dashboard/companyDetails/readMore/${item._id}?companyDetails=${item.title}`}
              >
                <Card sx={{ paddingRight: 5 }}>
                  <Stack spacing={2} key={key}>
                    <Grid container spacing={2} m={2}>
                      <Grid item xs={2}>
                        <Typography variant="h5" gutterTop mb={1}>
                          {item.title}
                        </Typography>
                        <div
                          className="user-img"
                          style={{
                            backgroundImage: `url(${API_URL.img_url}/api/uploads/images/${item?.image?.ogUrl})`,
                          }}
                        />
                      </Grid>
                      <Grid item xs={10} mt={5} textAlign={'start'}>
                        <Typography
                          sx={{ overflowX: 'hidden' }}
                          dangerouslySetInnerHTML={{ __html: item.description.substring(0, 250) }}
                        />

                        {/* <Typography gutterBottom>{item.description}</Typography> */}
                        {/* <div dangerouslySetInnerHTML={{__html: item.description}}/> */}
                        <Box sx={{ mr: 20, mt: 2 }}>
                          {role === 'admin' || role === 'hr' ? (
                            <>
                              <Button
                                variant="contained"
                                component={RouterLink}
                                to={`editCompanyDetails/${item._id}?companyDetails=${item.title}`}
                                startIcon={<Iconify icon="carbon:view" />}
                                color="warning"
                              >
                                Edit
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}
