import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';

import { set, sub } from 'date-fns';
import { noCase } from 'change-case';
import { faker } from '@faker-js/faker';
import { useState, useRef, useEffect, useContext } from 'react';
// import io from 'socket.io-client';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';

// notification context
import { NotificationContext } from '../../context/notificationContext';

// utils
import { fToNow } from '../../utils/formatTime';
// components
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { getReq, deleteRequest } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';
import { getUserToken } from '../../data/userData';

// ----------------------------------------------------------------------

// const socket = io(API_URL.socket_url, {
//   extraHeaders: {
//     'x-access-token': getUserToken(),
//   },
// });

export default function NotificationsPopover() {
  const anchorRef = useRef(null);

  const [notifications, setNotifications] = useState([]);
  // const [isConnected, setIsConnected] = useState(socket.connected);
  const [lastPong, setLastPong] = useState(null);

  const totalUnRead = notifications.filter((item) => item.isOpen !== true).length;

  const [open, setOpen] = useState(null);

  // useEffect(() => {
  //   getData();
  //   socket.on('connect', () => {
  //     setIsConnected(true);
  //     console.log('socket connected');
  //   });

  //   socket.on('disconnect', () => {
  //     setIsConnected(false);
  //   });

  //   socket.on('notification', (newData) => {
  //     console.log('notification', newData);
  //     getData();
  //   });

  //   return () => {
  //     socket.off('connect');
  //     socket.off('disconnect');
  //     socket.off('notification');
  //   };
  // }, []);

  const notificationValue = useContext(NotificationContext);

  // console.log('notificationValue from nav bar', notificationValue);

  // call getdata function when notificationValue changes

  useEffect(() => {
    getData();
  }, [notificationValue]);

  const getData = async () => {
    // console.log('get data called');
    const res = await getReq({ url: `/notifications` });
    // console.log(res);
    if (!res.error) {
      setNotifications(res.data);
    }
  };

  const clearOne = async (id) => {
    const res = await deleteRequest({ url: `/notifications?id=${id}` });
    if (!res.error) {
      getData();
    }
  };

  const clearAll = async () => {
    const res = await deleteRequest({ url: `/notifications/clear` });
    if (!res.error) {
      getData();
    }
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Clear all">
              <IconButton color="error" onClick={clearAll}>
                <Iconify icon="ic:round-delete-sweep" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: '60vh' } }}>
          <List disablePadding>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                clearOne={clearOne}
                handleClose={handleClose}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={clearAll}>
            Clear All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    _id: PropTypes.string,
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
  }),
  clearOne: PropTypes.func,
};

function NotificationItem({ notification, clearOne, handleClose }) {
  const { title } = renderContent(notification);
  console.log('notification', notification);
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.isOpen && {
          bgcolor: 'action.selected',
        }),
      }}
      component={RouterLink}
      to={`/dashboard/leaves?section=requests`}
      onClick={() => {
        clearOne(notification._id);
        handleClose();
      }}
    >
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar> */}
      <ListItemText
        primary={title}
        secondary={
          <>
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: 'text.disabled',
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.disabled',
                }}
              >
                <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                {fToNow(notification.createdAt)}
              </Typography>
              <Tooltip title=" Clear">
                <IconButton color="error" onClick={() => clearOne(notification._id)}>
                  <Iconify icon="ic:round-delete-forever" width={20} height={20} />
                </IconButton>
              </Tooltip>
            </Typography>
          </>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title} <br />
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        {notification.description}
      </Typography>
    </Typography>
  );

  // if (notification.type === 'order_placed') {
  //   return {
  //     avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'order_shipped') {
  //   return {
  //     avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'mail') {
  //   return {
  //     avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
  //     title,
  //   };
  // }
  return {
    // avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
