import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

// @mui
import { styled } from '@mui/material/styles';
import {
  Stack,
  TextField,
  MenuItem,
  Container,
  Input,
  Typography,
  Card,
  Icon,
  Box,
  Autocomplete,
  Button,
  CardContent,
  Grid,
} from '@mui/material';

import { postReq, getReq, patchReq, deleteRequest } from '../../data/ApiReq';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import ApiUrl from '../../data/ApiUrl';
import Loading from '../../components/loading';
import { getUserData } from '../../data/userData';

const queryString = require('query-string');

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function AddDepartment() {
  const initialState = {
    file: '',
    title: '',
    description: '',
    type: '',
    folder: '',
    employee: '',
  };
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };

  const params = useParams();
  const { userId } = useParams();
  const location = useLocation();
  const [state, setState] = useState(initialState);
  const [apiResult, setApiResult] = useState(initialResult);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [type, setType] = useState('');
  const [showText, setShowText] = useState(false);
  const [selectSec, setSelectSec] = useState(true);
  const [employee, setEmployee] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  //   const [viewOnly, setViewOnly] = useState(true);
  const navigate = useNavigate();

  const options = searchResult.map((option) => {
    // const firstLetter = option.title[0].toUpperCase();
    const firstLetter = option.firstName[0].toUpperCase();
    const secondLetter = option.lastName[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      secondLetter: /[0-9]/.test(secondLetter) ? '0-9' : secondLetter,
      ...option,
    };
  });

  const handleChange = (e) => {
    setType(e.target.value);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const getCategory = async () => {
    const res = await getReq({ url: '/document/category/getallcategory' });
    if (!res.error) {
      setCategoryData(res.data);
    }
  };

  const getRole = getUserData();
  const role = getRole?._id;

  const handleSearch = async (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText.length < 3) return null;

    const res = await getReq({ url: `/user/getalluser?search=${searchText}` });

    if (!res.error) {
      setSearchResult(res.data);
    }
  };

  const handleClick = (e) => {
    setEmployee(e);
    setType(e);
    setState({
      ...state,
      employee: e._id,
      // [e.target.name]: e.target.value,
    });
    setShowText(!showText);
    setSelectSec(!selectSec);
  };

  useEffect(() => {
    getCategory();
    if (params.id) {
      getCategory();
    }
  }, []);

  const createDocument = async (e) => {
    setLoading(true);
    setApiResult({ ...apiResult, loading: true, alert: true });
    e.preventDefault();
    const data = {
      file: state.file,
      title: state.title,
      type: state.type,
      folder: state.folder,
      employee: employee._id,
    };

    if (state.description) data.description = state.description;
    // if (state.employee) data.employee = state.employee;

    const response = await postReq({
      url: '/document',
      data,
    });

    if (!response.error) {
      setState(initialState);
      setApiResult({
        ...apiResult,
        error: false,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.success(response.message);
    } else {
      setApiResult({
        ...apiResult,
        error: true,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.error(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      // window.location.reload(false);
    }, 3000);

    setLoading(false);
  };

  const { id } = useParams();
  // data.id = userId;

  const updateDocument = async (e) => {
    setLoading(true);
    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const data = { depId: params.id };
    if (state.title) data.title = state.title;
    if (state.description) data.description = state.description;
    if (state.type) data.type = state.type;
    if (state.folder) data.folder = state.folder;
    if (state.employee) data.employee = employee._id;

    // only for making slug remove this
    data.slug = state.title;

    const response = await patchReq({
      url: `/department/respond/${id}`,
      data,
    });

    if (!response.error) {
      setApiResult({
        ...apiResult,
        error: false,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.success(response.message);
    } else {
      setApiResult({
        ...apiResult,
        error: true,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.error(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      setState(initialState);
    }, 3000);

    setLoading(false);
  };

  const RemoveUser = async (e) => {
    navigate(0);

    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
    }, 3000);
  };

  const documentSchema = Yup.object().shape({
    title: Yup.string().min(3, 'Please enter title').required('Title is required'),
    // date: Yup.date('Please enter valid date').required('Date is required'),
  });

  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    validationSchema: documentSchema,
    onChange: (e) => {
      console.log(e);
    },
    onSubmit: (values, { resetForm }) => {
      // console.log(values);

      updateDocument(values, resetForm);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const fileUpload = async (e) => {
    // console.log(e.target.files[0]);
    const file = e.target.files[0];
    const data = new FormData();
    data.append('file', file);
    data.append('type', 'documents');

    const response = await postReq({
      url: 'file/upload',
      data,
    });
    // console.log('file response', response);
    // if check
    if (!response.error) {
      setState({
        ...state,
        file: response.data._id,
      });
      toast.success('File Uploaded.');
    } else {
      toast.error(response.message);
    }
  };

  const EmployeeResult = () => {
    if (searchResult)
      return (
        <div>
          {selectSec && (
            <div>
              <Grid container justifyContent="center" mb={3}>
                <Grid item xs={12} md={12}>
                  {searchResult.map((item, key) => (
                    <div className="asset-search-sec" key={key}>
                      <div className="search-text">
                        <Box sx={{ ml: 2 }} onClick={() => handleClick(item)}>
                          <Typography variant="subtitle1" sx={{ color: 'text.primary', textAlign: 'start' }}>
                            {item?.firstName} {item?.lastName}
                          </Typography>
                        </Box>
                      </div>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      );
    return <div>{/* <SearchResult /> */}</div>;
  };

  if (loading) {
    return (
      <Page title="Add Document">
        <Loading />
      </Page>
    );
  }

  return (
    <Page title="Add Document">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography
            variant="h4"
            gutterBottom
            sm="4"
            title={params.id ? 'Edit Document' : 'Add Document'}
            subtitle=" "
            className="text-sm-left"
          >
            {params.id ? 'Edit Document' : 'Add Document'}
          </Typography>

          <Button
            variant="text"
            component={RouterLink}
            to={`/dashboard/documents`}
            startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
            color={'primary'}
          >
            List Documents
          </Button>
        </Stack>
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={7}>
            <Card>
              <CardContent>
                <div>
                  <FormikProvider>
                    <Stack spacing={3}>
                      <Typography variant="h5" gutterBottom>
                        Basic Details
                      </Typography>

                      <Stack justifyContent="center" alignItems="center">
                        <>
                          <Button variant="contained" component="label" className="imgupload-company">
                            Upload File <Icon icon="uil:image-upload" />
                            <input type="file" hidden onChange={fileUpload} />
                          </Button>
                        </>
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%', marginBottom: '10px' }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Title"
                            {...getFieldProps('title')}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%', marginBottom: '10px' }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Description"
                            {...getFieldProps('description')}
                            error={Boolean(touched.description && errors.description)}
                            helperText={touched.description && errors.description}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%', marginBottom: '10px' }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            //  disabled={viewOnly}
                            select
                            fullWidth
                            label="Type"
                            {...getFieldProps('type')}
                            onChange={handleChange}
                            error={Boolean(touched.type && errors.type)}
                            helperText={touched.type && errors.type}
                          >
                            <MenuItem value="passport">Passport</MenuItem>
                            <MenuItem value="visa">Visa Document</MenuItem>
                            <MenuItem value="education">Educational Document</MenuItem>
                            <MenuItem value="driving">Driving License</MenuItem>
                            <MenuItem value="insurance">Insurance Document</MenuItem>
                            <MenuItem value="bank">Bank Document</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                          </TextField>
                        </Stack>
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%', marginBottom: '4px' }}>
                          <TextField
                            fullWidth
                            select
                            label="Category"
                            name="folder"
                            value={state?.folder}
                            onChange={handleChange}
                            type="text"
                          >
                            {categoryData.map((option, key) => (
                              <MenuItem key={key} value={option._id}>
                                {option.title}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Stack>
                      </Stack>

                      {employee?.firstName && (
                        <div>
                          <Grid container spacing={1} textAlign={'center'} className="asset-search-sec">
                            <Grid item xs={10} className="search-text">
                              <Typography variant="subtitle1" sx={{ color: 'text.primary', textAlign: 'start' }}>
                                {employee?.firstName} {employee?.lastName}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              {/* {<Iconify icon={'carbon:close-outline'} />} */}
                              <Button
                                variant="contained"
                                component={RouterLink}
                                // to={`/dashboard/asset/single/${assetData?._id}`}
                                color={'error'}
                                onClick={RemoveUser}
                              >
                                Remove
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      )}

                      <div>
                        {selectSec && (
                          <>
                            <Iconify
                              icon="eva:search-fill"
                              sx={{ color: 'text.disabled', width: 20, height: 20, marginRight: 2 }}
                            />

                            <Input sx={{ width: '90%' }} onChange={handleSearch} />
                          </>
                        )}
                      </div>

                      <EmployeeResult />
                    </Stack>

                    <Button
                      className=" brand-buttons"
                      variant="contained"
                      color="primary"
                      onClick={createDocument}
                      style={{ marginTop: '10px' }}
                    >
                      Submit
                    </Button>
                  </FormikProvider>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {loading ? <Loading /> : null}
    </Page>
  );
}
