/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unreachable */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';

import { toast } from 'react-toastify';
import 'moment-timezone';

import {
  Stack,
  TextField,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Loading from '../../components/loading';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import { getReq, postReq } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';

const zxcvbn = require('zxcvbn');
const validator = require('email-validator');

export default function CreateEmployee() {
  const initialState = {
    firstName: '',
    lastName: '',
    employeeId: '',
    image: '',
    email: '',
    phone: '',
    password: '',
    company: '',
    location: '',
    department: '',
    role: '',
    officeTiming: '',
    lineManager: '',
  };
  const validationInitState = {
    firstName: false,
    lastName: false,
    employeeId: false,
    email: false,
    phone: false,
    password: false,
    company: false,
    location: false,
    department: false,
    role: false,
    officeTiming: false,
    lineManager: false,
  };
  const [state, setState] = useState(initialState);
  const [imgAddress, setImgAddress] = useState('/static/user-avatar/user_avatar_1.png');
  const [showPassword, setShowPassword] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [officeTimingData, setOfficeTimingData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [managersData, setManagersData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [touched, setTouch] = useState(validationInitState);
  const [validationErr, setValidationErr] = useState(validationInitState);
  const [isRedirect, setRedirect] = useState(false);

  const getData = async () => {
    setLoading(true);
    const response = await getReq({ url: `/company` });
    if (!response.error) {
      setCompanyData(response.data);
    }

    const response2 = await getReq({ url: `/location` });
    if (!response2.error) {
      setLocationData(response2.data);
    }

    // console.log('locationData=====================>', locationData);
    const response3 = await getReq({ url: `/user/getalluser?role=lineManager,admin` });
    if (!response3.error) {
      setManagersData(response3.data);
    }

    const response4 = await getReq({ url: `/department` });
    if (!response4.error) {
      setDepartmentData(response4.data);
    }

    const response5 = await getReq({ url: `/officetimings` });
    if (!response5.error) {
      setOfficeTimingData(response5.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
    setImgAddress(`/static/user-avatar/user_avatar_${randomNo()}.png`);
  }, []);

  const empSchema = {
    firstName: {
      type: 'string',
      required: true,
      msg: 'First Name is requred',
      minlength: 3,
    },
    lastName: {
      type: 'string',
      required: true,
      msg: 'Last Name is requred',
      minlength: 1,
    },
    employeeId: {
      type: 'string',
      required: true,
      msg: 'Employee ID is requred',
      minlength: 3,
    },
    lineManager: {
      type: 'string',
      required: true,
      msg: 'Choose one manager from the list',
    },
    email: {
      type: 'email',
      required: true,
      msg: 'Enter a valid email',
    },
    phone: {
      type: 'phone',
      required: true,
      msg: 'Enter a valid Phone No',
    },
    company: {
      type: 'string',
      required: true,
      msg: 'Please choose one',
    },
    department: {
      type: 'string',
      required: true,
      msg: 'Please choose one',
    },

    location: {
      type: 'string',
      required: true,
      msg: 'Please choose one',
    },

    role: {
      type: 'string',
      required: true,
      msg: 'Please choose one',
    },
    password: {
      type: 'password',
      required: true,
      minlength: 8,
      msg: 'Password must be at least 8 characters long.',
    },
  };

  const validationSuccess = (name) => {
    setTouch({
      ...touched,
      [name]: false,
    });
    setValidationErr({
      ...validationErr,
      [name]: false,
    });
  };

  const validationFailed = (name) => {
    setValidationErr({
      ...validationErr,
      [name]: true,
    });
    setTouch({
      ...touched,
      [name]: true,
    });
  };

  const validateField = ({ name, value }) => {
    // console.log('from validation-->', name, value);
    const singleSchema = empSchema[name];
    if (!singleSchema) return 0;

    // check for requerd
    if (singleSchema.required) {
      if (!value) {
        validationFailed(name);
        return 1;
      }
      validationSuccess(name);
    }

    // Check for min length

    // console.log('value--> ', value, value.length < singleSchema.minlength, value.length, singleSchema.minlength);
    if (singleSchema.minlength) {
      if (value.length < singleSchema.minlength) {
        validationFailed(name);
      } else {
        validationSuccess(name);
      }
    }

    // For Email

    if (singleSchema.type === 'email') {
      const isValidEmail = validator.validate(value);
      if (isValidEmail) {
        validationSuccess(name);
      } else {
        validationFailed(name);
      }
    }

    if (singleSchema.type === 'phone') {
      const pattern = /^(?:\+)?[0-9]{9,14}$/;
      const isValidPhone = pattern.test(value);
      // console.log('Phone check -->', isValidPhone);
      if (isValidPhone) {
        validationSuccess(name);
      } else {
        validationFailed(name);
      }
    }
    return 1;
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    validateField({ name, value });
    setState({
      ...state,
      [name]: value,
    });
    // console.log(e.target.value);
  };

  const onTouch = (e) => {
    const { name, value } = e.target;
    // console.log('from on touch-->', name, value);
    validateField({ name, value });
    setTouch({
      ...touched,
      [name]: true,
    });
  };
  const submitData = async () => {
    const data = {
      firstName: state.firstName,
      lastName: state.lastName,
      employeeId: state.employeeId,
      email: state.email,
      phone: state.phone,
      password: state.password,
      company: state.company,
      department: state.department,
      location: state.location,
      role: state.role,
      lineManager: state.lineManager,
      accessId: state.accessId ? state.accessId : state.employeeId,
      officeTiming: state.officeTiming,
      remember: true,
    };
    if (state.image) data.image = state.image;

    const emptyFields = [];

    for (const key of Object.keys(data)) {
      // console.log(key, data[key]);

      if (!data[key] || validationErr[key]) {
        emptyFields.push(key);
      }
    }

    const reqFields = Object.keys(empSchema);

    // console.log('submit data emptyFields');
    // console.log(emptyFields, reqFields);

    let haveError = false;
    const validationobj = {};

    for (const field of reqFields) {
      // console.log('fields', field);
      const isEmpty = emptyFields.includes(field);
      // console.log('Is Empty-->', isEmpty);

      if (isEmpty) {
        haveError = true;
        // validateField({ name: field, value: '' });
        // validationFailed(field);
        validationobj[field] = true;
      }
    }

    // console.log(validationobj);
    setValidationErr(validationobj);
    setTouch(validationobj);

    if (haveError) return 0;

    setLoading(true);

    const res = await postReq({ url: '/user', data });
    console.log(res);
    if (!res.error) {
      toast.success(' Employee Added ');
      setState(initialState);
      setRedirect(true);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const fileUpload = async (e) => {
    // console.log(e.target.files[0]);s
    const file = e.target.files[0];
    const data = new FormData();
    data.append('image', file);
    data.append('type', 'employee');

    const response = await postReq({
      url: 'image/upload',
      data,
    });
    // console.log('image response', response);
    // if check
    if (!response.error) {
      const image = response.data._id;
      const imageData = response.data;
      setImgAddress(URL.createObjectURL(file));
      setState({
        ...state,
        image,
        imgUrl: imageData.url,
      });
      toast.success(response.message);
      // console.log(response.data.url);
    } else {
      toast.error(response.message);
    }
  };

  function randomNo() {
    // min and max included
    const max = 8;
    const min = 1;
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  if (loading) {
    return (
      <Page title="Add Employee">
        <Loading />
      </Page>
    );
  }

  if (isRedirect) {
    return <Navigate to="/dashboard/employees" />;
  }
  return (
    <Page title="Add Employee">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Add Employee
          </Typography>
        </Stack>
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <div>
                  <div>
                    <Stack spacing={3}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2}>
                        <div className="img-pre-wrap">
                          <img className="img-upload-pre" src={imgAddress} alt="employee avathar" />
                          <Button variant="contained" component="label" onChange={fileUpload} className="upload-btn">
                            Upload Image
                            <input required hidden type="file" />
                          </Button>
                        </div>
                        <Stack spacing={2} style={{ width: '100%' }}>
                          <TextField
                            fullWidth
                            label="First Name"
                            name="firstName"
                            onChange={onChange}
                            onClick={onTouch}
                            type="text"
                            error={Boolean(touched.firstName && validationErr.firstName)}
                            helperText={touched.firstName && empSchema.firstName.msg}
                          />
                          <TextField
                            fullWidth
                            label="Last Name"
                            name="lastName"
                            onChange={onChange}
                            type="text"
                            onClick={onTouch}
                            error={Boolean(touched.lastName && validationErr.lastName)}
                            helperText={touched.lastName && empSchema.lastName.msg}
                          />
                          <TextField
                            fullWidth
                            label="Employee ID"
                            name="employeeId"
                            onChange={onChange}
                            onClick={onTouch}
                            type="text"
                            error={Boolean(touched.employeeId && validationErr.employeeId)}
                            helperText={touched.employeeId && empSchema.employeeId.msg}
                          />
                        </Stack>
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          fullWidth
                          label="Email Address"
                          name="email"
                          onChange={onChange}
                          type="text"
                          onClick={onTouch}
                          error={Boolean(touched.email && validationErr.email)}
                          helperText={touched.email && empSchema.email.msg}
                        />
                        <TextField
                          fullWidth
                          label="Phone Number"
                          name="phone"
                          onChange={onChange}
                          type="number"
                          // defaultValue="971"
                          onClick={onTouch}
                          error={Boolean(touched.phone && validationErr.phone)}
                          helperText={touched.phone && empSchema.phone.msg}
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          fullWidth
                          select
                          label="Company"
                          name="company"
                          onChange={onChange}
                          type="text"
                          onClick={onTouch}
                          error={Boolean(touched.company && validationErr.company)}
                          helperText={touched.company && empSchema.company.msg}
                        >
                          {companyData.map((option, key) => (
                            <MenuItem key={key} value={option._id}>
                              {option.companyName}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          fullWidth
                          select
                          label="Location"
                          name="location"
                          onChange={onChange}
                          type="text"
                          onClick={onTouch}
                          error={Boolean(touched.location && validationErr.location)}
                          helperText={touched.location && empSchema.location.msg}
                        >
                          {locationData.map((option, key) => (
                            <MenuItem key={key} value={option._id}>
                              {option.office}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        select
                        label="Line Manager"
                        name="lineManager"
                        onChange={onChange}
                        type="text"
                        onClick={onTouch}
                        error={Boolean(touched.lineManager && validationErr.lineManager)}
                        helperText={touched.lineManager && empSchema.lineManager.msg}
                      >
                        {managersData.map((option, key) => (
                          <MenuItem key={key} value={option._id}>
                            {option.firstName} {option.lastName}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        fullWidth
                        select
                        label="Department"
                        name="department"
                        onChange={onChange}
                        type="text"
                        onClick={onTouch}
                        error={Boolean(touched.department && validationErr.department)}
                        helperText={touched.department && empSchema.department.msg}
                      >
                        {departmentData.map((option, key) => (
                          <MenuItem key={key} value={option._id}>
                            {option.title}
                          </MenuItem>
                        ))}
                      </TextField>
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          fullWidth
                          select
                          label="Role"
                          name="role"
                          onChange={onChange}
                          type="text"
                          onClick={onTouch}
                          error={Boolean(touched.role && validationErr.role)}
                          helperText={touched.role && empSchema.role.msg}
                        >
                          <MenuItem value="lineManager">Manager</MenuItem>
                          <MenuItem value="hr">HR</MenuItem>
                          <MenuItem value="admin">Admin</MenuItem>
                          <MenuItem value="finance">finance</MenuItem>
                          <MenuItem value="employee">Employee</MenuItem>
                          <MenuItem value="it">IT</MenuItem>
                          <MenuItem value="officeAdmin">Office Admin</MenuItem>
                        </TextField>
                        <TextField
                          fullWidth
                          label="Access ID"
                          name="accessId"
                          onChange={onChange}
                          // onClick={onTouch}
                          // type="text"
                          // error={Boolean(touched.accessId && validationErr.accessId)}
                          // helperText={touched.accessId && empSchema.accessId.msg}
                        />
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          fullWidth
                          select
                          label="Office Timings"
                          name="officeTiming"
                          onChange={onChange}
                          type="text"
                          onClick={onTouch}
                          error={Boolean(touched.officeTiming && validationErr.officeTiming)}
                          helperText={touched.officeTiming && empSchema.officeTiming.msg}
                        >
                          {officeTimingData.map((option, key) => (
                            <MenuItem key={key} value={option._id}>
                              {option.title}
                            </MenuItem>
                          ))}
                        </TextField>
                        
                      </Stack>


                      <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        name="password"
                        onChange={onChange}
                        onClick={onTouch}
                        error={Boolean(touched.password && validationErr.password)}
                        helperText={touched.password && empSchema.password.msg}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                    <br />
                    <LoadingButton fullWidth size="large" type="button" variant="contained" onClick={submitData}>
                      Submit
                    </LoadingButton>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
