import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import 'moment-timezone';

// @mui
import { styled } from '@mui/material/styles';
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert,
  Container,
  Typography,
  Card,
  CardActions,
  Button,
  CardContent,
  Grid,
} from '@mui/material';

import { postReq, getReq, patchReq, deleteRequest } from '../../data/ApiReq';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import ApiUrl from '../../data/ApiUrl';
import Loading from '../../components/loading';

const queryString = require('query-string');
const moment = require('moment-timezone');

moment().format('YYYYMMDD');

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function AddHoliday() {
  const initialState = {
    title: '',
    description: '',
    date: '',
    type: 'new',
  };
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };
  const params = useParams();
  const { userId } = useParams();
  const location = useLocation();
  const [data, setData] = useState('');
  const [state, setState] = useState(initialState);
  const [apiResult, setApiResult] = useState(initialResult);
  const [holidayList, setHolidayList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const getData = async () => {
    setLoading(true);

    // const query = queryString.parse(location.search);
    // console.log('Location', query);

    if (params.id) {
      setState({
        ...state,
        type: 'edit',
        title: state.title,
        description: state.description,
        date: state.date,
      });
    }

    const response = await getReq({ url: `/holiday/${params.id}` });

    console.log(response);

    if (response.data) {
      const { data } = response;
      setState(data);
      if (data.date) data.date = moment(new Date(data.date)).format('YYYY-MM-DD');
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const getHoliday = async () => {
    setLoading(true);

    const res = await getReq({ url: '/holiday' });
    if (!res.error) {
      setHolidayList(res.data);
    }
    setLoading(false);
  };
  console.log('holidaylist================================>', holidayList);

  useEffect(() => {
    getHoliday();
  }, []);

  const createHoliday = async (e) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true, alert: true });
    e.preventDefault();
    const data = {
      title: state.title,
    };
    // if (state.brandDesc) {
    //   data.brandDesc = state.brandDesc;
    // } else {
    //   data.brandDesc = `${state.brandName} Products `;
    // }
    if (state.description) data.description = state.description;
    if (state.date) data.date = state.date;

    const response = await postReq({
      url: '/holiday/submit',
      data,
    });

    if (!response.error) {
      setState(initialState);
      setApiResult({
        ...apiResult,
        error: false,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.success(response.message);
    } else {
      setApiResult({
        ...apiResult,
        error: true,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.error(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      // window.location.reload(false);
    }, 3000);

    setLoading(false);
  };

  const { id } = useParams();
  // data.id = userId;

  const updateHoliday = async (e) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const data = { holidayId: params.id };
    if (state.title) data.title = state.title;

    if (state.description) data.description = state.description;

    if (state.date) data.date = state.date;

    // only for making slug remove this
    data.slug = state.title;

    const response = await patchReq({
      url: `/holiday/${id}`,
      data,
    });

    if (!response.error) {
      setApiResult({
        ...apiResult,
        error: false,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.success(response.message);
    } else {
      setApiResult({
        ...apiResult,
        error: true,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.error(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      setState(initialState);
    }, 3000);
    setLoading(false);
  };

  const deleteHoliday = async (e) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const response = await deleteRequest({ url: `/holiday/delete?id=${params.id}` });
    console.log('id--', params.id);
    if (!response.error) {
      toast.success(response.data.message);
      setApiResult({ redirect: true });
    } else {
      toast.error(response.data.message);
      setApiResult({
        ...apiResult,
        error: true,
        alert: true,
        message: response.data.message,
        loading: false,
      });
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
    }, 3000);
    setLoading(false);
  };

  const HolidaySchema = Yup.object().shape({
    title: Yup.string().min(3, 'Please enter a Title').required('Title is required'),
    description: Yup.string().min(3, 'Please enter Description').required('Description is required'),
    date: Yup.date('Please enter valid date').required('Date is required'),
  });

  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    validationSchema: HolidaySchema,
    onChange: (e) => {
      console.log(e);
    },
    onSubmit: (values, { resetForm }) => {
      // console.log(values);

      updateHoliday(values, resetForm);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  if (loading) {
    return (
      <Page title={params.id ? 'Edit Holiday' : 'Add Holiday'}>
        <Loading />
      </Page>
    );
  }

  return (
    <Page title={params.id ? 'Edit Holiday' : 'Add Holiday'}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography
            variant="h4"
            gutterBottom
            sm="4"
            title={params.id ? 'Edit Holiday' : 'Add Holiday'}
            subtitle=" "
            className="text-sm-left"
          >
            {params.id ? 'Edit Holiday' : 'Add Holiday'}
          </Typography>

          <Button
            variant="text"
            component={RouterLink}
            to={`/dashboard/settings/getAllHolidays`}
            startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
            color={'primary'}
          >
            Holiday List
          </Button>

        </Stack>
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={7}>
            <Card>
              <CardContent>
                <div>
                  <FormikProvider>
                    {/* <Form autoComplete="off" noValidate > */}
                    <Stack spacing={3}>
                      <Typography variant="h5" gutterBottom>
                        Basic Details
                      </Typography>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%', marginBottom: '10px' }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Title"
                            {...getFieldProps('title')}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                            onChange={handleChange}
                          />
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Description"
                            {...getFieldProps('description')}
                            error={Boolean(touched.description && errors.description)}
                            helperText={touched.description && errors.description}
                            onChange={handleChange}
                          />
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Date"
                            type={'date'}
                            {...getFieldProps('date')}
                            // defaultValue={state.date}
                            error={Boolean(touched.date && errors.date)}
                            helperText={touched.date && errors.date}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                    {state.type === 'new' ? (
                      <Button
                        className=" brand-buttons"
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={createHoliday}
                        style={{ marginTop: '10px' }}
                      >
                        Submit
                      </Button>
                    ) : (
                      <>
                        <Button
                          sx={{ marginRight: 2 }}
                          onClick={deleteHoliday}
                          color="error"
                          className="brand-buttons"
                          variant="contained"
                        >
                          Delete
                        </Button>
                        <Button
                          onClick={updateHoliday}
                          color="warning"
                          className="mr-4 brand-buttons"
                          variant="contained"
                        >
                          Update
                        </Button>
                      </>
                    )}
                    {/* </Form> */}
                  </FormikProvider>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
