import { createContext } from 'react';

const today = new Date();

const initData = {
  date: {
    month: today.getMonth(),
    day: today.getDate(),
    year: today.getFullYear(),
    isClicked: false,
  },
  setDate: () => {},
};

export const DateContext = createContext(initData);
