import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from './firebase';
import { postReq } from '../data/ApiReq';
import { getUserData } from '../data/userData';

const VAPID = 'BPQlJwprVfgXjA4S5c0v5DR8mJ_CTzOSg-uhTcvi4WpKXXN4QLbVI64aD0Li8xA7Mn9m2Myx5PyUHKEak2kX3R8';

export const saveFCMToken = async (FCMToken) => {
  const userRole = getUserData().role;

  const data = {
    token: FCMToken,
    role: userRole,
  };

  const res = await postReq({ url: '/notifications/fcmtoken', data });

  if (!res.error) {
    console.log('FCM Token saved successfully');
  }
};

export const requestForToken = () =>
  getToken(messaging, { vapidKey: VAPID })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
        saveFCMToken(currentToken);
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log('payload', payload);
      resolve(payload);
    });
  });
