import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';

// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';

import { SignOut } from '../../data/Auth';
// ----------------------------------------------------------------------
import { getUserData } from '../../data/userData';
import API_URL from '../../data/ApiUrl';

// ----------------------------------------------------------------------

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));
const getRole = getUserData();
const role = getRole?.role;

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const userData = getUserData();

  const MENU_OPTIONS = [
    {
      label: 'Home',
      icon: 'eva:home-fill',
      linkTo: '/',
    },
    {
      label: 'Account Settings',
      icon: 'eva:person-fill',
      // linkTo: `/dashboard/employees/view/${userData._id}`,
      linkTo: `/dashboard/employees/passwordChange/${userData._id}`,
    },
    // {
    //   label: 'Settings',
    //   icon: 'eva:settings-2-fill',
    //   linkTo: '/dashboard/settings',
    // },
  ];

  const MENU_OPTION = [
    {
      label: 'Home',
      icon: 'eva:home-fill',
      linkTo: '/',
    },
    {
      label: 'Account Settings',
      icon: 'eva:person-fill',
      // linkTo: `/dashboard/employees/view/${userData._id}`,
      linkTo: `/dashboard/employees/passwordChange/${userData._id}`,
    },
  ];

  const handleSignOut = async () => {
    const response = await SignOut();
    if (response) {
      navigate('/login');
    }
    // console.log(response);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              // borderRadius: '10px',
              position: 'absolute',
              // bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
        <Box>
          {/* <Link underline="none" component={RouterLink} to="#"> */}
          <div className="account-sec">
            <Avatar src={`${API_URL.AWS_Bucket_url}/public/employee/${userData?.image?.ogUrl}`} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary', textAlign: 'start' }}>
                {userData?.firstName?.toUpperCase()}
              </Typography>
              <p className="accound-designation">
                {userData?.designation?.toUpperCase()}{' '}
                {userData?.role === 'employee' ? '' : ` | ${userData?.role?.toUpperCase()}`}
              </p>
            </Box>
          </div>
          {/* </Link> */}
        </Box>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userData?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userData?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}
        {role === 'admin' || role === 'hr' ? (
          <>
            <Stack sx={{ p: 1 }}>
              {MENU_OPTIONS.map((option) => (
                <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                  {option.label}
                </MenuItem>
              ))}
            </Stack>
          </>
        ) : (
          <>
            <Stack sx={{ p: 1 }}>
              {MENU_OPTION.map((option) => (
                <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                  {option.label}
                </MenuItem>
              ))}
            </Stack>
          </>
        )}
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleSignOut} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
