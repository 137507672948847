import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableHead,
  Box,
  TableCell,
  TextField,
  Pagination,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { getUserRole } from '../../data/userData';
import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getReq } from '../../data/ApiReq';
import Loading from '../../components/loading';

export default function OldRequest() {
  const initialState = {
    page: 1,
    limit: 20,
    totalCount: 0,
    totalPages: 0,
    hasNextPage: false,
    searchText: '',
    sort: '',
    qEdit: false,
  };

  const [requestList, setRequestList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(initialState);
  const [filterState, setFilterState] = useState({});

  const getData = async (pg, lm, isSearch, filter) => {
    setLoading(true);

    const p = pg || state.page;
    const l = lm || state.limit;

    let query = `page=${p}&limit=${l}`;
    if (isSearch) {
      query = `page=${p}&limit=${l}&searchText=${isSearch}`;
      if (filter) {
        query += `&${filter}`;
      }
      setFilterState({ ...filterState, searchText: isSearch });
    }
    if (filter) {
      query = `page=${p}&limit=${l}&${filter}`;
      if (filterState.searchText) {
        query += `&searchText=${filterState.searchText}`;
      }
    }

    const res = await getReq({ url: `/request?${query}` });
    if (!res.error) {
      setRequestList(res.data);
      setState({
        ...state,
        page: res.pagination.page,
        limit: res.pagination.limit,
        totalCount: res.pagination.totalCount,
        totalPages: res.pagination.totalPages,
        hasNextPage: res.pagination.hasNextPage,
        qEdit: false,
      });
      // console.log(state);
    }
    setLoading(false);
  };

  const changePage = (event, value) => {
    setState({
      ...state,
      page: value,
    });
    getData(value, state.limit, filterState.searchText, filterState.query);
  };

  useEffect(() => {
    getData();
  }, []);

  const formateDate = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    return (
      <div>
        {day}-{month}-{year}
      </div>
    );
  };
  const role = getUserRole();

  return (
    <div>
      <Container>
        
        <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
          <Pagination count={state?.totalPages} color="primary" onChange={changePage} />
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>

                {role === 'it' ? (
                  <TableBody>
                    {requestList.map((row, index) => (
                      <TableRow
                        hover
                        key={index}
                        component={RouterLink}
                        to={`/dashboard/it/single/${row._id}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <TableCell>{(state.page - 1) * state.limit + index + 1}</TableCell>
                        <TableCell>
                          {row?.userId?.firstName} {row?.userId?.lastName}
                        </TableCell>
                        <TableCell>{row.subject}</TableCell>
                        <TableCell> {formateDate(row.createdAt)}</TableCell>
                        <TableCell>
                          <div className={`text-${row?.state} text-pile`}>{row?.state?.toUpperCase()}</div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <>
                    <TableBody>
                      {requestList.map((row, index) => (
                        <TableRow hover key={index} style={{ textDecoration: 'none' }}>
                          <TableCell>{(state.page - 1) * state.limit + index + 1}</TableCell>
                          <TableCell>
                            {row?.userId?.firstName} {row?.userId?.lastName}
                          </TableCell>
                          <TableCell>{row.subject}</TableCell>
                          <TableCell> {formateDate(row.createdAt)}</TableCell>
                          <TableCell>
                            <div className={`text-${row?.state} text-pile`}>{row?.state?.toUpperCase()}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </div>
  );
}
