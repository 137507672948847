/* eslint-disable import/no-unresolved */
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDfLtA3JIXprLsno0gFGn-20ODOJT1fqMI',
  authDomain: 'ninja-resource.firebaseapp.com',
  projectId: 'ninja-resource',
  storageBucket: 'ninja-resource.appspot.com',
  messagingSenderId: '756289026324',
  appId: '1:756289026324:web:808aa602efb4b2ecb4ea01',
  measurementId: 'G-KB3PWY23KJ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export { messaging };
