import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableHead,
  TextField,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';

import Iconify from '../../components/Iconify';
// components

import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getReq } from '../../data/ApiReq';
import Loading from '../../components/loading';

export default function GetAllOfficeTimings() {
  const initialState = {
    page: 1,
    limit: 25,
    totalCount: 0,
    totalPages: 0,
    hasNextPage: false,
    searchText: '',
    sort: '',
    qEdit: false,
  };

  const [timingList, setTimingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(initialState);
  const [filterState, setFilterState] = useState({});

  const getData = async (pg, lm, isSearch, filter) => {
    // setLoading(true);
    const p = pg || state.page;
    const l = lm || state.limit;

    let query = `page=${p}&limit=${l}`;
    if (isSearch) {
      query = `page=${p}&limit=${l}&searchText=${isSearch}`;
      if (filter) {
        query += `&${filter}`;
      }
      setFilterState({ ...filterState, searchText: isSearch });
    }
    if (filter) {
      query = `page=${p}&limit=${l}&${filter}`;
      if (filterState.searchText) {
        query += `&searchText=${filterState.searchText}`;
      }
    }

    const res = await getReq({ url: `/officetimings?${query}` });
    if (!res.error) {
      setTimingList(res.data);
      setState({
        ...state,
        page: res.pagination.page,
        limit: res.pagination.limit,
        totalCount: res.pagination.totalCount,
        totalPages: res.pagination.totalPages,
        hasNextPage: res.pagination.hasNextPage,
        qEdit: false,
      });
    }
    // console.log('EMPLOYEELIST=========================>', res.data);

    // setLoading(false);
  };

  const changePage = (event, value) => {
    setState({
      ...state,
      page: value,
    });
    getData(value, state.limit, filterState.searchText, filterState.query);
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <Page title="Office Timing">
        <Loading />
      </Page>
    );
  }
  return (
    <Page title="Office Timing">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Office Timing List
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            {state.totalPages > 1 && <Pagination count={state?.totalPages} color="primary" onChange={changePage} />}
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/officetimings/new"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Office Timing
            </Button>
          </Stack>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timingList.map((row, index) => (
                    <TableRow hover key={index}>
                      <TableCell>{state.limit * state.page + index + 1 - state.limit}</TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          component={RouterLink}
                          to={`/dashboard/officetimings/single/${row._id}?title=${row.title}`}
                          startIcon={<Iconify icon="carbon:view" />}
                          color="warning"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
