import React from 'react';
import API_URL from '../../../data/ApiUrl';

const getMonthName = (month) => {
  const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return monthArr[month - 1];
};

export default function EventItems({ item, type }) {
  let title;
  let desc;
  let date;
  let dateDiff;
  let image = `${API_URL.AWS_Bucket_url}/public/employee/${item?.image?.ogUrl}`;
  let clsName = 'event-item-date';

  if (type === 'eid') {
    title = `${item.firstName} ${item.lastName}`;
    date = new Date(item?.EIdValidity).toLocaleDateString();
    dateDiff = Math.floor((new Date(item?.EIdValidity) - new Date()) / 86400000);
    desc = `Emirates ID is going to expire in  ${dateDiff}  days`;
  } else if (type === 'visa') {
    title = `${item.firstName} ${item.lastName}`;
    date = new Date(item?.passportValidity).toLocaleDateString();
    dateDiff = Math.floor((new Date(item?.passportValidity) - new Date()) / 86400000);
    desc = `Passport is going to expire in  ${dateDiff}  days`;
  } else if (type === 'birthday') {
    title = `${item.firstName} ${item.lastName}'s Birthday`;
    image = `${API_URL.AWS_Bucket_url}/public/employee/${item?.image[0]?.ogUrl}`;
    date = `${getMonthName(item?.month)} ${item?.day}`;

    const age = item?.age;

    desc = `${age}th Birthday`;
    // date = 'March 15';
    clsName = 'event-item-date anniversary ';
  } else if (type === 'anniversary') {
    title = `${item.firstName} ${item.lastName}'s Work Anniversary`;
    date = `${getMonthName(item?.month)} ${item?.day}`;
    const anniversary = item?.aniversary;
    image = `${API_URL.AWS_Bucket_url}/public/employee/${item?.image[0]?.ogUrl}`;

    // desc = `${anniversary}th Anniversary`;
    // date = 'March 17';
    clsName = 'event-item-date anniversary';
  }

  // console.log('item', item);
  return (
    <>
      <div className="event-item">
        <div className="event-item-img">
          <img src={image} alt={title} className="event-user-img" />
        </div>
        <div className="event-item-content" style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div>
              <div className="event-item-title">{title}</div>

              {/* <div className="event-item-desc">{desc}</div> */}
            </div>
            <div className={clsName}>{date}</div>
          </div>
        </div>
      </div>
    </>
  );
}
