import Cookies from 'js-cookie';

export const cookieConst = {
  userData: 'fdfKJHBI79JKdsfASFf44gg',
  token: 'saasfKJNOPO0898KJBujdwdk',
  userRole: 'sfdfeflmpTNBQjmT72Ll1',
  company: 'dasduerowyKCJNBKLn834cpo',
};

export const getUserData = () => {
  const userData = Cookies.get(cookieConst.userData) ? JSON.parse(Cookies.get(cookieConst.userData)) : false;
  return userData;
};
export const getUserToken = () => {
  const token = Cookies.get(cookieConst.token) ? JSON.parse(Cookies.get(cookieConst.token)) : false;
  return token;
};
export const getUserRole = () => {
  const role = Cookies.get(cookieConst.userRole) ? JSON.parse(Cookies.get(cookieConst.userRole)) : false;
  return role;
};
export const getCompanyData = () => {
  try {
    const companyData = Cookies.get(cookieConst.company) ? JSON.parse(Cookies.get(cookieConst.company)) : false;
    return companyData;
  } catch (error) {
    return false;
  }
};
