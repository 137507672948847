import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
// material
import { styled, alpha } from '@mui/material/styles';
import {
  Input,
  Slide,
  Button,
  IconButton,
  InputAdornment,
  ClickAwayListener,
  Avatar,
  Box,
  Typography,
} from '@mui/material';
// component
import Iconify from '../../components/Iconify';
import { getReq } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';

import account from '../../_mock/account';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const SearchResultStyle = styled('div')(({ theme }) => ({
  top: APPBAR_MOBILE + 40,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  backdropFilter: 'blur(16px)',
  WebkitBackdropFilter: 'blur(16px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: '#ebeef2f1',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2, 5),
  },
  color: '#2065d1',
  borderRadius: '10px',
  border: '1px solid #2065d140',
}));
// ----------------------------------------------------------------------

export default function Searchbar() {
  const location = useLocation();

  // find the route change
  useEffect(() => {
    handleClose();
  }, [location]);

  const [isOpen, setOpen] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchText, setSearchText] = useState('');

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = async (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText.length < 3) return null;

    const res = await getReq({ url: `/user/getalluser?search=${searchText}` });

    console.log(res.data);
    if (!res.error) {
      setSearchResult(res.data);
    }
  };

  const SearchResult = () => {
    if (searchResult?.length === 0 || searchText.length < 3)
      return (
        <Typography variant="subtitle1" sx={{ color: 'text.primary', textAlign: 'start' }}>
          No result found
        </Typography>
      );
    return (
      <div>
        {searchResult.map((item, key) => (
          <RouterLink to={`/dashboard/employees/view/${item._id}`} key={key} style={{ textDecoration: 'none' }}>
            <div className="emp-search-sec">
              <div className="search-text">
                <Avatar src={`${API_URL.img_url}/api/uploads/images/${item?.image?.ogUrl}`} alt={item?.firstName} />

                <Box sx={{ ml: 2 }}>
                  <Typography variant="subtitle1" sx={{ color: 'text.primary', textAlign: 'start' }}>
                    {item?.firstName} {item?.lastName}
                  </Typography>
                  <p className="search-desc">
                    {item?.employeeId?.toUpperCase()} | {item?.designation?.toUpperCase()}
                  </p>
                </Box>
              </div>
              <Button variant="contained">View Profile</Button>
            </div>
          </RouterLink>
        ))}
      </div>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" width={20} height={20} />
          </IconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              onChange={handleSearch}
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
          </SearchbarStyle>
        </Slide>
        {searchText.length > 2 && (
          <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
            <SearchResultStyle>
              <SearchResult />
            </SearchResultStyle>
          </Slide>
        )}
      </div>
    </ClickAwayListener>
  );
}
