/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Stack,
  Checkbox,
  Container,
  Grid,
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Tabs,
  Tab,
  CardMedia,
  Item,
  Button,
} from '@mui/material';

import API_URL from '../../data/ApiUrl';

import CompanyCardLg from '../../components/companyAttendance';

export default function AttendanceViewSec({ attendanceData, todaysOverview, DateDiv }) {
  // const overAllAttendance = (todaysData?.present / userCount) * 100;

  let attendancePercentage =
    Math.round((todaysOverview?.totalAttendance / todaysOverview?.totalEmployees) * 100 * 10) / 10;

  if (Number.isNaN(attendancePercentage)) {
    attendancePercentage = 0;
  }

  return (
    <>
      <Container>
        <Grid container spacing={3} mb={5} justifyContent="center" alignItems={'center'}>
          
          <Grid item xs={12} alignItems="center" justifyContent={'center'}>
            <Stack
              // spacing={1}
              direction={{
                xs: 'column',
                sm: 'row',
              }}
            >
              {/* <Stack spacing={1}>{DateDiv}</Stack> */}

              <div className="card-dx-title-wrap  bg-gray-gr">
                <h4 className="card-dx-sub-title">Overall </h4>
                <h2 className="card-dx-title">{attendancePercentage}% </h2>
              </div>

              <div className="card-dx-title-wrap  bg-green-gr">
                <h4 className="card-dx-sub-title">Present </h4>
                <h2 className="card-dx-title"> {todaysOverview?.totalAttendance}</h2>
              </div>

              <div className="card-dx-title-wrap bg-red-gr">
                <h4 className="card-dx-sub-title ">Absent </h4>
                <h2 className="card-dx-title"> {todaysOverview?.absence}</h2>
              </div>

              <div className="card-dx-title-wrap  bg-yellow-gr">
                <h4 className="card-dx-sub-title">Late </h4>
                <h2 className="card-dx-title"> {todaysOverview?.totalLate}</h2>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

AttendanceViewSec.defaultProps = {
  attendanceData: [
    {
      _id: '',
      totalEmployees: 0,
      attendance: 0,
      late: 0,
      absences: 0,
      company: '',
      companyAddrs: '',
      logo: '',
    },
  ],
  todaysOverview: {
    totalAttendance: 0,
    totalEmployees: 0,
    totalLate: 0,
    absence: 0,
  },
};
