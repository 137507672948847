import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TextField,
  Pagination,
  Container,
  Typography,
  CardContent,
  Grid,
  Box,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Loading from '../../components/loading';
import { postReq } from '../../data/ApiReq';




export default function RaiseRequest() {
    const initialState = {
        subject: '',
        reason: '',
      };
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };

  const [loading, setLoading] = useState(false);
  const [apiResult, setApiResult] = useState(initialResult);
  const [state, setState] = useState(initialState);

  const requestSubmit = async (e) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true, alert: true });
    e.preventDefault();
    const data = {
      subject: state.subject,
      reason: state.reason,
    };
    const response = await postReq({
      url: '/request/submit',
      data,
    });

    if (!response.error) {
      toast.success(response.message);
      setState(initialState);
      setApiResult({
        ...apiResult,
        error: false,
        message: response.message,
        alert: true,
        loading: false,
      });
    } else {
      toast.error(response.message);
      setApiResult({
        ...apiResult,
        error: true,
        message: response.message,
        alert: true,
        loading: false,
      });
      console.log(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      // window.location.reload(false);
    }, 3000);

    setLoading(false);
  };
  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Container>

          <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={7}>
            <Card>
              <CardContent>
                <div>
                  <FormikProvider>
                    {/* <Form autoComplete="off" noValidate > */}
                    <Stack spacing={3}>
                      <Typography variant="h5" gutterBottom>
                        Basic Details
                      </Typography>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={5} style={{ width: '100%' }}>
                          <TextField
                            fullWidth
                            label="Subject"
                            name="subject"
                            onChange={onChange}
                            value={state.subject}
                          />

                        

                          <TextField
                            fullWidth
                            label="Reason"
                            name="reason"
                            multiline
                            rows={6}
                            onChange={onChange}
                            value={state.reason}
                          />

                         
                        </Stack>
                      </Stack>
                    </Stack>
                  
                      <Box textAlign="center" sx={{ marginTop: 2}}>
                        <Button className=" brand-buttons" variant="contained" color="primary" sx={{width:'100%'}} onClick={requestSubmit}>
                          Submit
                        </Button>
                      </Box>

                    {/* </Form> */}
                  </FormikProvider>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        
      </Container>
    </div>
  );
}
