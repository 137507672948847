import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import { CKEditor } from 'ckeditor4-react';
import { Icon } from '@iconify/react';

// @mui
import { styled } from '@mui/material/styles';
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert,
  Container,
  Typography,
  MenuItem,
  Card,
  Box,
  CardActions,
  Button,
  CardContent,
  Grid,
} from '@mui/material';

import { postReq, getReq, patchReq, deleteRequest } from '../../data/ApiReq';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import ApiUrl from '../../data/ApiUrl';
import Loading from '../../components/loading';
import { getUserData } from '../../data/userData';

const queryString = require('query-string');

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function AddBrand() {
  const initialState = {
    title: '',
    description: '',
    company: '',
    category: '',
    image: '',
    type: 'new',
  };
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };
  const params = useParams();
  const { userId } = useParams();
  const location = useLocation();
  const [state, setState] = useState(initialState);
  const [apiResult, setApiResult] = useState(initialResult);
  const [newsList, setNewsList] = useState([]);
  const [descList, setDescList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ckEditorText, setCkEditorText] = useState('');
  const [companyData, setCompanyData] = useState([]);
  const [category, setCategory] = useState('');
  const [company, setCompany] = useState('');

  const handleChange = (e) => {
    setCompany(e.target.value);
    setCategory(e.target.value);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const getRole = getUserData();
  const role = getRole?.role;

  const onEditorChange = (evt) => {
    setCkEditorText({
      ...ckEditorText,
      description: evt.editor.getData(),
    });
  };

  // console.log(ckEditorText.description)

  const getData = async () => {
    // const query = queryString.parse(location.search);
    // console.log('Location', query);
    setLoading(true);
    if (params.id) {
      setState({
        ...state,
        type: 'edit',
        title: state.title,
        description: descList.description,
        company: state.company,
        category: state.category,
        image: state.image,
      });
    }

    const response = await getReq({ url: `/news/news/${params.id}` });

    // console.log(response.data);

    if (response.data) {
      const { data } = response;
      setState(data);
    }

    const response1 = await getReq({ url: `/company` });
    if (!response1.error) {
      setCompanyData(response1.data);
    }

    setLoading(false);
  };

  const [imgAddress, setImgAddress] = useState(null);

  const getNews = async () => {
    setLoading(true);

    const res = await getReq({ url: '/news/news' });
    if (!res.error) {
      setNewsList(res.data);
    }
    setLoading(false);
  };

  const getDescription = async () => {
    setLoading(true);
    const res = await getReq({ url: `/news/news/${params.id}` });
    if (!res.error) {
      setDescList(res.data);
    }
    setLoading(false);
  };

  // console.log("========@@@@@@@@@@@@@@@@@",descList.description);

  useEffect(() => {
    if (params.id) {
      getNews();
      getDescription();
    }
  }, []);

  const createNews = async (e) => {
    setLoading(true);
    setApiResult({ ...apiResult, loading: true, alert: true });
    e.preventDefault();
    const data = {
      title: state.title,
      description: ckEditorText.description,
    };
    // if (state.brandDesc) {
    //   data.brandDesc = state.brandDesc;
    // } else {
    //   data.brandDesc = `${state.brandName} Products `;
    // }
    if (state.image) data.image = state.image;
    if (state.type) data.type = 'news';
    if (state.company) data.company = state.company;
    if (state.category) data.category = state.category;

    const response = await postReq({
      url: '/news/submit',
      data,
    });

    if (!response.error) {
      setState(initialState);
      setApiResult({
        ...apiResult,
        error: false,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.success(response.message);
    } else {
      setApiResult({
        ...apiResult,
        error: true,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.error(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      // window.location.reload(false);
    }, 3000);

    setLoading(false);
  };

  const { id } = useParams();
  // data.id = userId;

  const updateNews = async (e) => {
    setLoading(true);
    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const data = { newsId: params.id };
    if (state.title) data.title = state.title;
    if (state.company) data.company = state.company;
    if (state.category) data.category = state.category;

    if (state.description) data.description = ckEditorText.description;

    if (state.image) data.image = state.image;

    if (state.type) data.type = state.type;

    // only for making slug remove this
    data.slug = state.title;

    const response = await patchReq({
      url: `/news/respond/${id}`,
      data,
    });

    if (!response.error) {
      setApiResult({
        ...apiResult,
        error: false,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.success(response.message);
    } else {
      setApiResult({
        ...apiResult,
        error: true,
        message: response.data.message,
        alert: true,
        loading: false,
      });
      toast.error(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      setState(initialState);
    }, 3000);

    setLoading(false);
  };

  const deleteNews = async (e) => {
    setLoading(true);
    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const response = await deleteRequest({ url: `/news/delete?id=${params.id}` });
    // console.log('id--', params.id);
    if (!response.error) {
      toast.success(response.data.message);
      setApiResult({ redirect: true });
    } else {
      toast.error(response.data.message);
      setApiResult({
        ...apiResult,
        error: true,
        alert: true,
        message: response.data.message,
        loading: false,
      });
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
    }, 3000);
    setLoading(false);
  };

  const fileUpload = async (e) => {
    // console.log(e.target.files[0]);
    const file = e.target.files[0];
    const data = new FormData();
    data.append('image', file);
    data.append('type', 'news');

    const response = await postReq({
      url: 'image/upload',
      data,
    });
    // console.log('image response', response);
    // if check
    if (!response.error) {
      const image = response.data._id;
      const imageData = response.data;
      setImgAddress(imageData.ogUrl);
      setState({
        ...state,
        image,
        imgUrl: imageData.ogUrl,
      });
      toast.success(response.message);
      // console.log(response.data.ogUrl);
    } else {
      toast.error(response.message);
    }
  };

  const NewsSchema = Yup.object().shape({
    title: Yup.string().min(3, 'Please enter title').required('Title is required'),
    description: Yup.string().min(3, 'Please enter description').required('description is required'),
    // date: Yup.date('Please enter valid date').required('Date is required'),
  });

  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    validationSchema: NewsSchema,
    onChange: (e) => {
      console.log(e);
    },
    onSubmit: (values, { resetForm }) => {
      // console.log(values);

      updateNews(values, resetForm);
    },
  });

  const demoImg = 'https://amdmediccentar.rs/wp-content/plugins/uix-page-builder/includes/uixpbform/images/no-logo.png';
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  if (loading) {
    return (
      <Page title="Add News">
        <Loading />
      </Page>
    );
  }

  return (
    <Page title="Add News">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography
            variant="h4"
            gutterBottom
            sm="4"
            title={params.id ? 'Edit News' : 'Add News'}
            subtitle=" "
            className="text-sm-left"
          >
            {params.id ? 'Edit News' : 'Add News'}
          </Typography>

          <Button
            variant="text"
            component={RouterLink}
            to={`/dashboard/news`}
            startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
            color={'primary'}
          >
            List News
          </Button>
        </Stack>
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={7}>
            <Card>
              <CardContent>
                <div>
                  <FormikProvider>
                    <Stack spacing={3}>
                      <Typography variant="h5" gutterBottom>
                        Basic Details
                      </Typography>

                      <Stack justifyContent="center" alignItems="center">
                        {imgAddress ? (
                          <>
                            <div
                              className="img-upload-pre"
                              style={{
                                backgroundImage: `url(${
                                  imgAddress ? `${ApiUrl.img_url}/api/uploads/images/${imgAddress}` : demoImg
                                })`,
                              }}
                            >
                              {state.type === 'new' && (
                                <Button
                                  sx={{ marginTop: 2 }}
                                  variant="contained"
                                  component="label"
                                  className="imgupload-company"
                                >
                                  Change <Icon icon="uil:image-upload" />
                                  <input type="file" hidden onChange={fileUpload} />
                                </Button>
                              )}
                              {params.id && (
                                <Button
                                  sx={{ marginTop: 2 }}
                                  variant="contained"
                                  component="label"
                                  className="imgupload-company"
                                >
                                  Change <Icon icon="uil:image-upload" />
                                  <input type="file" hidden onChange={fileUpload} />
                                </Button>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {params.id ? (
                              <>
                                <Stack
                                  spacing={2}
                                  sx={{ width: '100%', height: '200px', objectFit: 'contain' }}
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <div
                                    className="img-upload-pre"
                                    style={{
                                      backgroundImage: `url(${
                                        state.image
                                          ? `${ApiUrl.img_url}/api/uploads/images/${state.image.ogUrl}`
                                          : demoImg
                                      })`,
                                    }}
                                  >
                                    <Button
                                      sx={{ marginTop: 2 }}
                                      variant="contained"
                                      component="label"
                                      className="imgupload-company"
                                    >
                                      Change <Icon icon="uil:image-upload" />
                                      <input type="file" hidden onChange={fileUpload} />
                                    </Button>
                                  </div>
                                </Stack>
                              </>
                            ) : (
                              <Button variant="contained" component="label" className="imgupload-company">
                                Upload Image <Icon icon="uil:image-upload" />
                                <input type="file" hidden onChange={fileUpload} />
                              </Button>
                            )}
                          </>
                        )}
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%', marginBottom: '10px' }}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Title"
                            {...getFieldProps('title')}
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                            onChange={handleChange}
                          />
                        </Stack>
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%', marginBottom: '4px' }}>
                          <TextField
                            fullWidth
                            select
                            label="Company"
                            name="company"
                            value={state?.company}
                            onChange={handleChange}
                            type="text"
                          >
                            {companyData.map((option, key) => (
                              <MenuItem key={key} value={option._id}>
                                {option.companyName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Stack>
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%', marginBottom: '4px' }}>
                          <TextField
                            fullWidth
                            select
                            label="Category"
                            name="category"
                            value={state?.category}
                            onChange={handleChange}
                            type="text"
                          >
                            {/* {companyData.map((option, key) => ( */}
                            <MenuItem value={'profile'}>Profile</MenuItem>
                            <MenuItem value={'company'}>company details</MenuItem>
                            <MenuItem value={'services'}>Services </MenuItem>
                            <MenuItem value={'other'}>Other details</MenuItem>
                            {/* ))} */}
                          </TextField>
                        </Stack>
                      </Stack>

                      <CKEditor
                        className="cke_editable"
                        type="classic"
                        name="description"
                        initData={state.description}
                        onChange={onEditorChange}
                      />
                    </Stack>
                    {state.type === 'new' || role === 'admin' || !role === 'hr' ? (
                      <Button
                        className=" brand-buttons"
                        variant="contained"
                        color="primary"
                        onClick={createNews}
                        style={{ marginTop: '10px' }}
                      >
                        Submit
                      </Button>
                    ) : (
                      <>
                        <Box textAlign="end" sx={{ marginTop: 2 }}>
                          <Button
                            sx={{ marginRight: 2 }}
                            onClick={deleteNews}
                            color="error"
                            className="brand-buttons"
                            variant="contained"
                          >
                            Delete
                          </Button>
                          <Button
                            onClick={updateNews}
                            color="warning"
                            className="mr-4 brand-buttons"
                            variant="contained"
                          >
                            Update
                          </Button>
                        </Box>
                      </>
                    )}
                  </FormikProvider>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {loading ? <Loading /> : null}
    </Page>
  );
}
