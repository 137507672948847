import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import Modal from '@mui/material/Modal';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file for date range picker
import 'react-date-range/dist/theme/default.css';
import fileDownload from 'js-file-download';
import Iconify from '../../../components/Iconify';
import { getReq } from '../../../data/ApiReq';
import ApiUrl from '../../../data/ApiUrl';

export default function ReportModal() {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('attendance');
  const [error, setError] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const submitReq = async () => {
    const sDate = dateRange[0]?.startDate;
    const eDate = dateRange[0]?.endDate;

    const fromDate = `${sDate.getFullYear()}-${sDate.getMonth() + 1}-${sDate.getDate()}`;

    const toDate = `${eDate.getFullYear()}-${eDate.getMonth() + 1}-${eDate.getDate()}`;

    let url = `${ApiUrl.base_url}/attendance/report?fromDate=${fromDate}&toDate=${toDate}`;

    if (type === 'absentees')
      url = `${ApiUrl.base_url}/attendance/absenteesreport?fromDate=${fromDate}&toDate=${toDate}`;

    try {
      const fileName = `attendance_report_${fromDate}_${toDate}.csv`;
      const response = await getReq({ url });
      fileDownload(response, fileName);
    } catch (error) {
      setError(true);
    }
  };

  return (
    <>
      <div>
        <Button
          // onClick={() => setShowFilter(!showFilter)}
          onClick={handleOpen}
          color="inherit"
          size="large"
          variant="outlined"
        >
          Reports
          <Iconify icon="mdi:report-box" />
        </Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-parent">
          <div className="modal-child">
            <div>
              <Stack spacing={2} direction={'row'} justifyContent="space-between">
                <h2 id="modal-modal-title">
                  <Iconify
                    icon="mdi:report-box"
                    style={{
                      padding: '0',
                    }}
                  />
                  <span
                    style={{
                      padding: '5px',
                    }}
                  >
                    Attendance Reports
                  </span>
                </h2>
                <Button onClick={handleClose} color="error">
                  <Iconify
                    icon="mdi:close-circle"
                    style={{
                      fontSize: '2rem',
                    }}
                  />
                </Button>
              </Stack>
            </div>

            <div
              style={{
                padding: '10px',
                display: 'grid',
                placeItems: 'center',
                width: '100%',
                gap: '20px',
              }}
            >
              <div className="select-btn-wrap">
                <button
                  className={type === 'attendance' ? 'select-btn selected-btn' : 'select-btn '}
                  type="button"
                  onClick={() => setType('attendance')}
                >
                  <span className="select-btn-text">Attendance </span>
                </button>

                <button
                  className={type === 'absentees' ? 'select-btn selected-btn' : 'select-btn '}
                  type="button"
                  onClick={() => setType('absentees')}
                >
                  <span className="select-btn-text">Absentees </span>
                </button>
              </div>
              <DateRange
                editableDateInputs
                onChange={(item) => setDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                className="date-range"
              />
              <Button variant="contained" color="primary" onClick={submitReq}>
                Generate Report
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
