/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
// @mui

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { styled } from '@mui/material/styles';
import {
  Link,
  Stack,
  TextField,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  List,
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Loading from '../../components/loading';
import { getUserRole } from '../../data/userData';

import { getReq, patchReq } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';

export default function EmployeeOverView() {
  const today = new Date();
  const formattedDate = format(today, 'M-dd-yyyy');

  const { userId } = useParams();
  const [userData, setUserData] = useState({});
  const [uRoll, setRoll] = useState('employee');
  const [assetData, setAssetdata] = useState([]);
  const [leaveData, setleavedata] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [attendenceData, setAttendenceData] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [checkIn, setCheckIn] = useState([]);
  const [loading, setLoading] = useState(false);
  const [annualLeaveData, setAnnualLeaveData] = useState({
    noOfAvailableDays: 0,
    lastLeave: null,
    leaveIsInFuture: false,
  });
  const userRole = async () => {
    const role = await getUserRole();
    setRoll(role);
  };

  const getData = async () => {
    setLoading(true);

    const res = await getReq({ url: `/user/overview?id=${userId}` });

    const employeeId = res?.data?._id;
    if (!res.error) {
      setUserData(res.data);
      setAssetdata(res?.data?.assetData);
      setleavedata(res?.data?.leaveData);
      setAttendenceData(res?.data?.attendanceInsights);
      setLeaveRequests(res?.data?.leaveRequests);
    }

    const annualLeaveRes = await getReq({ url: `/leave/annual/${userId}` });
    if (!annualLeaveRes.error) {
      setAnnualLeaveData(annualLeaveRes.data);
    }

    const documentRes = await getReq({ url: `/document/user/${employeeId}` });
    if (!documentRes.error) {
      setDocumentData(documentRes.data);
    }

    setLoading(false);
  };

  const getSingleData = async () => {
    const singleRes = await getReq({ url: `/attendance/${formattedDate}/${userId}` });
    const checkIn = singleRes?.data?.checkInLocalTime ? singleRes?.data?.checkInLocalTime : 'ABSENT';
    setCheckIn(checkIn);
  };

  // console.log(leaveDetails.ticketReq);
  useEffect(() => {
    getData();
    getSingleData();
    userRole();
  }, [userId]);

  const initialState = {
    remark: '',
    state: '',
  };
  const [state, setState] = useState(initialState);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Page title={`${userData?.firstName} - Overview`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            {userData?.firstName} {userData?.lastName}
          </Typography>
          {uRoll === 'admin' || uRoll === 'hr' || uRoll === 'lineManager' ? (
            <Button
              variant="text"
              component={RouterLink}
              to={`/dashboard/employees`}
              startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
              color={'primary'}
            >
              Employee List
            </Button>
          ) : null}
        </Stack>

        <Grid container spacing={3} justifyContent="center" mb={3}>
          <Grid item xs={12} md={8}>
            <div className="dashbord-card">
              <div>
                <div className="dashboard-card-title">
                  {/* Attendence Icon */}
                  <Iconify icon="mdi:user" /> <span>Basic Details</span>
                </div>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center">
                  <Stack spacing={2}>
                    <div
                      className="user-img user-img-lg"
                      style={{
                        backgroundImage: `url(${API_URL.AWS_Bucket_url}/public/employee/${userData?.image?.ogUrl})`,
                      }}
                    />
                  </Stack>
                  <Stack spacing={2} style={{ width: '100%' }}>
                    <table className="emp-data-table">
                      <tr>
                        <td>Employee Id</td>
                        <td>
                          <Typography variant="subtitle1"> {userData?.employeeId}</Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>Designation</td>
                        <td>
                          {' '}
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.designation}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td> Email </td>
                        <td>
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.email}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td> Phone </td>
                        <td>
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.phone}
                          </Typography>
                        </td>
                      </tr>

                      <tr>
                        <td>Company</td>
                        <td>
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.company?.companyName}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>Office</td>
                        <td>
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.location?.office}
                          </Typography>
                        </td>
                      </tr>
                    </table>
                  </Stack>
                </Stack>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="dashbord-card">
              <div className="dashboard-card-title">
                {/* Attendence Icon */}
                <Iconify icon="wi:stars" /> <span>Insights</span>
              </div>
              <div>
                <div>
                  <ul className="insight-ul">
                    <li className="insight-li">
                      {/*  2.77778e-7  used for converting mili seconds to hour */}
                      {/* Average Working Time - {Math.round((attendenceData[0]?.avgWorkingMin * 100) / 60) / 100} Hr Daily */}
                      Check In Time - {checkIn}
                    </li>
                    <li className="insight-li">Available Annual Leave - {annualLeaveData?.noOfAvailableDays} Days</li>
                    {/* <li className="insight-li">
                      Average Check-in Time - {Math.round((attendenceData[0]?.avgCheckIn / 60) * 100) / 100}
                    </li>
                    <li className="insight-li">
                      Average Check-out Time - {Math.round((attendenceData[0]?.avgCheckOut / 60) * 100) / 100}
                    </li>
                    <li className="insight-li">Late Check In - {attendenceData[0]?.lateChecIn} Days</li>
                    <li className="insight-li">Early Check Out - {attendenceData[0]?.earlyCheckOut} Days</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={3} lg={2}>
              <div className="dashbord-card">
                <div className="dashboard-card-title">
                  {/* Notification icons */}
                  <Iconify icon="fluent:web-asset-20-filled" /> <span> Assets </span>
                </div>
                <div style={{ height: '150px', overflow: 'auto' }}>
                  <List>
                    {assetData?.map((item, key) => (
                      <div key={key}>
                        <ListItem disablePadding>
                          <ListItemButton>
                            {/* <ListItemIcon>
                              <Iconify icon="clarity:computer-line" />
                            </ListItemIcon> */}
                            <ListItemText primary={item?.asset?.deviceName} />
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                  </List>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              <div className="dashbord-card">
                <div className="dashboard-card-title">
                  {/* Notification icons */}
                  <Iconify icon="pepicons-pop:leave" /> <span> No of Documents </span>
                </div>
                <div style={{ height: '150px', overflow: 'auto' }}>
                  <List>
                    {documentData?.map((item, key) => (
                      <div key={key}>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <a
                              href={`${API_URL.img_url}/api/uploads/files/${item?.file?.url}`}
                              target="_blank"
                              rel="noreferrer"
                              style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                              <ListItemText primary={item?.title} />
                            </a>
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                  </List>
                </div>
              </div>
            </Grid>

            {/* <Grid item xs={12} md={4} lg={4}>
              <div className="dashbord-card">
                <div className="dashboard-card-title">
                  <Iconify icon="carbon:intent-request-active" /> <span> Requests </span>
                </div>
                <div>
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <Iconify icon="carbon:cost-total" />
                        </ListItemIcon>
                        <ListItemText primary="Cost Reimbursement" />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <Iconify icon="material-symbols:featured-play-list" />
                        </ListItemIcon>
                        <ListItemText primary="Payroll" />
                      </ListItemButton>
                    </ListItem>
                    <Divider />

                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <Iconify icon="mdi:application-edit-outline" />
                        </ListItemIcon>
                        <ListItemText primary="Apply Leave" />
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <Iconify icon="bi:cash-coin" />
                        </ListItemIcon>
                        <ListItemText primary="Finance Approvals" />
                      </ListItemButton>
                    </ListItem>

                    <Divider />
                  </List>
                </div>
              </div>
            </Grid> */}
            <Grid item xs={12} md={4} lg={3}>
              <div className="dashbord-card">
                <div className="dashboard-card-title">
                  {/* Notification icons */}
                  <Iconify icon="pepicons-pop:leave" /> <span> No of leaves </span>
                </div>
                <div style={{ height: '150px', overflow: 'auto' }}>
                  <List>
                    {leaveData?.map((item, key) => (
                      <div key={key}>
                        <ListItem disablePadding>
                          <ListItemButton>
                            {/* <ListItemIcon>
                              <Iconify icon="clarity:computer-line" />
                            </ListItemIcon> */}
                            <ListItemText primary={item?.type} /> {item?.totalDays}
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                  </List>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <div className="dashbord-card">
                <div className="dashboard-card-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {/* Notification icons */}
                  <div>
                    <Iconify icon="ic:baseline-pending-actions" /> <span> Pending Requests </span>
                  </div>
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={`/dashboard/attendance/request?status=pending&user=${userData?._id}`}
                    color={'warning'}
                  >
                    View All
                  </Button>
                </div>

                <div style={{ height: '150px', overflow: 'auto' }}>
                  <List>
                    {leaveRequests?.map((item, key) => (
                      <div key={key}>
                        <ListItem disablePadding>
                          <ListItemButton>
                            {/* <ListItemIcon>
                              <Iconify icon="clarity:computer-line" />
                            </ListItemIcon> */}
                            <ListItemText primary={item?.type} />{' '}
                            <p className="small-info-text ">
                              {item?.noOfDays === 1
                                ? new Date(item?.fromDate).toDateString()
                                : `${new Date(item?.fromDate).toDateString()} - ${new Date(
                                    item?.endDate
                                  ).toDateString()}`}
                            </p>
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                  </List>
                </div>
              </div>
            </Grid>
          </Grid>
          {/*  responsive Stack in mobile stack direction col and desktop its row */}

          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="center"
            alignItems="center"
            spacing={2}
            marginTop={4}
          >
            <Button
              variant="outlined"
              component={RouterLink}
              to={`/dashboard/employees/fullview/${userId}`}
              startIcon={<Iconify icon={'mdi:file-eye'} />}
              color={'primary'}
              size="large"
              fullWidth
            >
              View Full Details
            </Button>
            {/* {uRoll !== 'employee' && ( */}
            <Button
              variant="outlined"
              component={RouterLink}
              to={`/dashboard/employees/edit/${userId}`}
              startIcon={<Iconify icon={'bx:edit-alt'} />}
              color={'primary'}
              size="large"
              fullWidth
            >
              Edit Details
            </Button>
            {/* )} */}

            <Button
              variant="outlined"
              component={RouterLink}
              to={
                userId === 'employee'
                  ? `/dashboard/attendance/ur-attendance?userName=${userData?.firstName}`
                  : `/dashboard/attendance/ur-attendance?user=${userId}&userName=${userData?.firstName}`
              }
              color={'primary'}
              style={{ float: 'right' }}
              endIcon={<Iconify icon="simple-line-icons:calender" />}
              size="large"
              fullWidth
            >
              Attendance Report
            </Button>
          </Stack>
        </div>
      </Container>
    </Page>
  );
}
