import API_URL from '../data/ApiUrl';

const CompanyCardMd = ({ item }) => (
  <div className="company-card-lg">
    <div className="cc-md-title">{item.company}</div>
    <div className="cc-inner">
      <div className="cc-item">
        <div className="cc-item-main">{item.attendance} </div>
        <div className="cc-item-sub pile-green"> Present</div>
      </div>
      <div className="cc-item">
        <div className="cc-item-main">{item.absences} </div>
        <div className="cc-item-sub pile-red"> Absent</div>
      </div>
      <div className="cc-item">
        <div className="cc-item-main">{item.late} </div>
        <div className="cc-item-sub pile-yellow"> Late</div>
      </div>
    </div>
  </div>
);

export default CompanyCardMd;
