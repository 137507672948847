import React from 'react';
import { Grid } from 'react-virtualized';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

import '../../assets/heatmap.css';

function HeatMap({ data, handleClick }) {
  //   console.log(data);
  // Define the dimensions of each box and the grid container
  const boxWidth = 20;
  const boxHeight = 20;
  const containerHeight = 7 * boxWidth;
  const containerWidth = Math.ceil(365 / 7) * boxHeight;

  const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const dayArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const getDateString = ({ mm, dd, day, yyyy }) => {
    if (!mm || !dd || !yyyy) return 'No data';
    return `${dd} ${dayArr[day]}, ${monthArr[mm - 1]} ${yyyy}`;
  };

  // Define a function to render each box
  const renderBox = ({ columnIndex, rowIndex, style }) => {
    const dayIndex = rowIndex + columnIndex * 7;
    const tooltipText = getDateString({
      mm: data[dayIndex]?.dateObj?.mm,
      dd: data[dayIndex]?.dateObj?.dd,
      day: data[dayIndex]?.dateObj?.day,
      yyyy: data[dayIndex]?.dateObj?.yyyy,
    });
    const internalHandleClick = () => {
      console.log(`Clicked on day`, data[dayIndex]);
      handleClick(data[dayIndex]);
      // Do something else here, like update state
    };

    let cName = 'heatmap-box';

    if (data[dayIndex]?.status === 'present') {
      cName = 'heatmap-box hb-present';
    } else if (data[dayIndex]?.status === 'absent') {
      cName = 'heatmap-box hb-absent';
    } else if (data[dayIndex]?.status === 'late') {
      cName = 'heatmap-box hb-late';
    } else if (data[dayIndex]?.status === 'sunday') {
      cName = 'heatmap-box hb-sunday';
    }

    if (!data[dayIndex]?.date) {
      cName = 'heatmap-box';
    }

    if (data[dayIndex]?.totalTime > 550 && data[dayIndex]?.status === 'late') {
      cName = 'heatmap-box hb-present yellow-border';
    }

    if (data[dayIndex]?.totalTime > 600 && data[dayIndex]?.totalTime < 660) {
      cName = 'heatmap-box hb-present-2 ';
    }

    if (data[dayIndex]?.totalTime > 660) {
      cName = 'heatmap-box hb-present-3 ';
    }

    if (data[dayIndex]?.totalTime > 720) {
      cName = 'heatmap-box hb-present-4 ';
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div key={dayIndex} className={cName} style={style} onClick={internalHandleClick} data-tip={tooltipText} />
    );
  };

  return (
    <div className="overflow-auto">
      <h2 className="heatmap-desc"> Your {data[0]?.dateObj?.yyyy} attendance </h2>

      <div className="heat-map">
        <ReactTooltip />
        <div className="day-text">
          <div className="day-text-item">S</div>
          <div className="day-text-item">M</div>
          <div className="day-text-item">T</div>
          <div className="day-text-item">W</div>
          <div className="day-text-item">T</div>
          <div className="day-text-item">F</div>
          <div className="day-text-item">S</div>
        </div>
        <Grid
          cellRenderer={renderBox}
          columnCount={Math.ceil(365 / 7)}
          columnWidth={boxWidth}
          height={containerHeight}
          rowCount={7}
          rowHeight={boxHeight}
          width={containerWidth}
          gap={2}
        />
      </div>
      <div className="month-text">
        <div className="month-text-item">Jan</div>
        <div className="month-text-item">Feb</div>
        <div className="month-text-item">Mar</div>
        <div className="month-text-item">Apr</div>
        <div className="month-text-item">May</div>
        <div className="month-text-item">Jun</div>
        <div className="month-text-item">Jul</div>
        <div className="month-text-item">Aug</div>
        <div className="month-text-item">Sep</div>
        <div className="month-text-item">Oct</div>
        <div className="month-text-item">Nov</div>
        <div className="month-text-item">Dec</div>
      </div>
      <div className="heatmap-legend">
        <div className="legend-item">
          <div className="legend-box hb-present" />
          <div className="legend-text">Present</div>
        </div>
        <div className="legend-item">
          <div className="legend-box hb-absent" />
          <div className="legend-text">Absent</div>
        </div>
        <div className="legend-item">
          <div className="legend-box hb-late" />
          <div className="legend-text">Late</div>
        </div>
        <div className="legend-item">
          <div className="legend-box hb-sunday" />
          <div className="legend-text">Sunday</div>
        </div>
      </div>
    </div>
  );
}

export default HeatMap;

HeatMap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  handleClick: PropTypes.func,
};

HeatMap.defaultProps = {
  data: [],
  handleClick: () => {},
};
