import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link as RouterLink, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
// @mui
import { styled } from '@mui/material/styles';

import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert,
  Container,
  Typography,
  ListItem,
  ListItemButton,
  Box,
  ListItemIcon,
  ListItemText,
  Divider,
  Card,
  List,
  CardActions,
  CardContent,
  Grid,
  MenuItem,
  Button,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { LoadingButton } from '@mui/lab';


// components
import Page from '../../components/Page';
import Loading from '../../components/loading';
import Iconify from '../../components/Iconify';
import { postReq, getReq, patchReq } from '../../data/ApiReq';
import { getUserRole } from '../../data/userData';
import ApiUrl from '../../data/ApiUrl';


// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function PasswordChange() {
  const userRole = getUserRole();

  const { userId } = useParams();
  const initialValues = {
    firstName: '',
    lastName: '',
    employeeId: '',
    designation: '',
    company: '',
    office: '',
    image: '',
    email: '',
    password: '',
    newPassword: '',
    currentPassword: '',
    confirmPassword: '',
    phone: '',
  };
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };
  const [userData, setUserData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [imgAddress, setImgAddress] = useState(null);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(initialValues);
  const [apiResult, setApiResult] = useState(initialResult);


  const getData = async () => {
    setLoading(true);

    const response = await getReq({ url: `/user/getsingleuser?id=${userId}` });
    if (!response.error) {
      setImgAddress(`${ApiUrl.img_url}/api/uploads/images/${response.data?.image?.ogUrl}`);
    }
    // console.log('response--->');
    // console.log(response);
    if (!response.data) return null;

    const { data } = response;

    setUserData(data);

    setLoading(false);
  };
  // console.log('imgAddress=========>', imgAddress);

  useEffect(() => {
    getData();
  }, []);

  const updatePassword = async (e, resetForm) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const data = {
      currentPassword: state.currentPassword,
      newPassword: state.newPassword,
      confirmPassword: state.confirmPassword,
    };

    data.id = userId;
    if (state.currentPassword) data.currentPassword = state.currentPassword;
    if (state.newPassword) data.newPassword = state.newPassword;
    if (state.confirmPassword) data.confirmPassword = state.confirmPassword;

    const response = await patchReq({
      url: `user/changepassword`,
      data,
    });

    if (!response.error) {
      toast.success(response.message);
      // resetForm();
      setApiResult({
        ...apiResult,
        error: false,
        message: response.message,
        alert: true,
        loading: false,
      });
      handleClose();
    } else {
      toast.error(response.message);
      setApiResult({
        ...apiResult,
        error: true,
        message: response.message,
        alert: true,
        loading: false,
      });
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      setState(initialValues);
    }, 3000);

    setLoading(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleClose = () => {
    setOpen(false);
    setState(initialValues);
  };

  const handleOpen = () => setOpen(true);

  if (loading) {
    return (
      <Page title="Add New Employee">
        <Loading />
      </Page>
    );
  }

  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value);
  };

  return (
    <Page title="Add New Employee">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {userData?.firstName} {userData?.lastName}
          </Typography>
        </Stack>

        <Grid container spacing={1} justifyContent="center" mb={1}>
          <Grid item xs={12} md={12}>
            <div className="dashbord-card">
              <div>
                <div className="dashboard-card-title">
                  {/* Attendence Icon */}
                  <Iconify icon="mdi:user" />{' '}
                  <span>
                    {userData?.firstName} {userData?.lastName}
                  </span>
                </div>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center">
                  <Stack spacing={2}>
                    <div
                      className="user-img user-img-lg"
                      style={{
                        backgroundImage: `url(${imgAddress})`,
                      }}
                    />
                  </Stack>
                  <Stack spacing={2} style={{ width: '100%' }}>
                    <table className="emp-data-table">
                      <tr>
                        <td>Employee Id</td>
                        <td>
                          <Typography variant="subtitle1"> {userData?.employeeId}</Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>Designation</td>
                        <td>
                          {' '}
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.designation}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td> Email </td>
                        <td>
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.email}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td> Phone </td>
                        <td>
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.phone}
                          </Typography>
                        </td>
                      </tr>

                      <tr>
                        <td>Company</td>
                        <td>
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.company?.companyName}
                          </Typography>
                        </td>
                      </tr>
                    </table>

                    <Stack justifyContent="end" alignItems="end">
                      <div>
                        <Button variant="contained" onClick={handleOpen}>
                          Change Password
                        </Button>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                            <div>
                              <div>
                                <TextField
                                  sx={{ marginBottom: 2 }}
                                  fullWidth
                                  label="Current Password"
                                  name="currentPassword"
                                  type={'password'}
                                  onChange={onChange}
                                  defaultValue={state.currentPassword}
                                  value={state.currentPassword}
                                />
                                <TextField
                                  sx={{ marginBottom: 2 }}
                                  fullWidth
                                  label="New Password"
                                  name="newPassword"
                                  type={'password'}
                                  onChange={onChange}
                                  defaultValue={state.newPassword}
                                  value={state.newPassword}
                                />
                                <TextField
                                  fullWidth
                                  label="Confirm Password"
                                  name="confirmPassword"
                                  type={'password'}
                                  onChange={onChange}
                                  defaultValue={state.confirmPassword}
                                  value={state.confirmPassword}
                                />
                              </div>
                              <Box sx={{ marginTop: 2 }}>
                                <Button sx={{ marginRight: 1 }} onClick={handleClose}>
                                  Cancel
                                </Button>
                                <Button onClick={updatePassword}>Submit</Button>
                              </Box>
                            </div>
                          </Box>
                        </Modal>
                      </div>
                    </Stack>
                  </Stack>
                </Stack>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <div className="dashbord-card">
              <div>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  justifyContent="center"
                  alignItems="center"
                  // margin={'4%'}
                  spacing={2}
                >
                  <div>
                    <Button variant="contained" onClick={handleOpen}>
                      Change Password
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <div >
                          <div >
                            <TextField
                              sx={{ marginBottom: 2 }}
                              fullWidth
                              label="Current Password"
                              name="currentPassword"
                              type={'password'}
                              onChange={onChange}
                              defaultValue={state.currentPassword}
                              value={state.currentPassword}
                            />
                            <TextField
                              sx={{ marginBottom: 2 }}
                              fullWidth
                              label="New Password"
                              name="newPassword"
                              type={'password'}
                              onChange={onChange}
                              defaultValue={state.newPassword}
                              value={state.newPassword}
                            />
                            <TextField
                              fullWidth
                              label="Confirm Password"
                              name="confirmPassword"
                              type={'password'}
                              onChange={onChange}
                              defaultValue={state.confirmPassword}
                              value={state.confirmPassword}
                            />
                          </div>
                          <Box sx={{ marginTop: 2 }}>
                            <Button sx={{ marginRight: 1 }} onClick={handleClose}>
                              Cancel
                            </Button>
                            <Button onClick={updatePassword}>Submit</Button>
                          </Box>
                        </div>
                      </Box>
                    </Modal>
                  </div>

                </Stack>
              </div>
            </div>
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
