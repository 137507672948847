import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import 'moment-timezone';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { Link, Stack, TextField, Container, Typography, Card, CardContent, Grid, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file for date range picker
import 'react-date-range/dist/theme/default.css'; // theme css file for date range picker
import Page from '../../components/Page';
import Loading from '../../components/loading';
import { postReq, getReq } from '../../data/ApiReq';
import { getUserData } from '../../data/userData';

export default function Leave() {
  const initialState = {
    type: '',
    reason: '',
    document: '',
    noOfDays: '',
    ticketReq: '',
    fromDate: '',
    endDate: '',
  };
  const [state, setState] = useState(initialState);
  const [checked, setChecked] = useState(false);
  const handleChange = () => setChecked((prev) => !prev);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [annualLeaveData, setAnnualLeaveData] = useState({
    noOfAvailableDays: 0,
    lastLeave: null,
    leaveIsInFuture: false,
  });

  // console.log("checkbox checked==================================>",checked);

  const userId = getUserData()._id;

  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value);
  };
  const submitLeave = async () => {
    setLoading(true);
    const data = {
      type: state.type,
      reason: state.reason,
      noOfDays: state.noOfDays,
      ticketReq: checked,
      fromDate: dateRange[0]?.startDate,
      endDate: dateRange[0]?.endDate,
    };

    if (state.document) {
      data.document = state.document;
    }

    const res = await postReq({ url: '/leave/submit', data });
    if (!res.error) {
      toast.success(' Leave Submited ');
      setState(initialState);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const fileUpload = async (e) => {
    // console.log(e.target.files[0]);
    const file = e.target.files[0];
    const data = new FormData();
    data.append('file', file);
    data.append('type', 'leave');

    const response = await postReq({
      url: 'file/upload',
      data,
    });
    // console.log('file response', response);
    // if check
    if (!response.error) {
      setState({
        ...state,
        document: response.data._id,
      });
      toast.success('Uploaded Medical Certificate');
    } else {
      toast.error(response.message);
    }
  };

  const getAnnualLeaves = async () => {
    const annualLeaveRes = await getReq({ url: `/leave/annual/${userId}` });
    if (!annualLeaveRes.error) {
      setAnnualLeaveData(annualLeaveRes.data);
    }
  };

  useEffect(() => {
    getAnnualLeaves();
  }, []);

  return (
    <Page title="Apply for Leave">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Apply For Leave
          </Typography>
        </Stack>
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={7}>
            <Card>
              <CardContent>
                <div>
                  <div>
                    <Stack spacing={3}>
                      <Typography variant="h5" gutterBottom>
                        Leave Details
                      </Typography>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                        <Stack spacing={5} style={{ width: '100%' }}>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">Type of leave</InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              autoWidth
                              label="Leave"
                              onChange={onChange}
                              name="type"
                              required
                            >
                              {/* <MenuItem value="Unpaid Leave">Unpaid Leave</MenuItem> */}
                              <MenuItem value="Sick Leave">Sick Leave</MenuItem>
                              <MenuItem value="Annual Leave">Annual Leave</MenuItem>
                              <MenuItem value="Maternity Leave">Maternity Leave</MenuItem>
                              <MenuItem value="compassionate Leave">Compassionate Leave</MenuItem>

                              <MenuItem value="Other">Other</MenuItem>
                            </Select>
                          </FormControl>

                          <TextField fullWidth label="Reason" name="reason" multiline rows={5} onChange={onChange} />
                          <div className="tkt-section-card">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox checked={checked} onChange={handleChange} />}
                                label="Need Flight Ticket ?"
                                name="ticketReq"
                              />
                            </FormGroup>
                            <div>
                              {checked ? (
                                <InputLabel id="demo-simple-select-standard-label">
                                  You will be reimbursed for the flight ticket after the leave is approved.
                                </InputLabel>
                              ) : null}
                            </div>
                          </div>
                          {state.type === 'Annual Leave' ? (
                            <div className="tkt-section-card">
                              <p>Available Annual Leaves</p>
                              <h3>{annualLeaveData?.noOfAvailableDays}</h3>
                            </div>
                          ) : null}
                          <h4>
                            Leave Dates <span style={{ color: 'red' }}>*</span>
                          </h4>
                          <div className="section-card">
                            <DateRange
                              editableDateInputs
                              onChange={(item) => setDateRange([item.selection])}
                              moveRangeOnFirstSelection={false}
                              ranges={dateRange}
                              className="date-range"
                            />
                            <InputLabel id="demo-simple-select-standard-label">
                              Both Dates mentioned below are inclusive of leaves <span style={{ color: 'red' }}>*</span>{' '}
                            </InputLabel>
                          </div>
                        </Stack>
                      </Stack>
                      {state.type === 'Sick Leave' ? (
                        <Button onChange={fileUpload} color="inherit" component="label" className="file-upload-btn">
                          Upload Medical Certificate
                          <input hidden type="file" />
                        </Button>
                      ) : null}
                      <LoadingButton fullWidth size="large" type="button" variant="contained" onClick={submitLeave}>
                        Submit
                      </LoadingButton>
                    </Stack>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {loading ? <Loading /> : null}
    </Page>
  );
}
