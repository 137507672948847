import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// Notification context
import { NotificationContext } from './context/notificationContext';

// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { onMessageListener } from './firebase/notification';
import 'react-toastify/dist/ReactToastify.css';
import 'mapbox-gl/dist/mapbox-gl.css';
// ----------------------------------------------------------------------

export default function App() {
  const [notification, setNotification] = useState({ title: '', body: '' });

  onMessageListener()
    .then((payload) => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log('failed: ', err));

  const notify = () => toast.info(notification.body);

  useEffect(() => {
    if (notification.title !== '') {
      notify();
    }
  }, [notification]);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <NotificationContext.Provider value={{ notification, setNotification }}>
        <Router />
      </NotificationContext.Provider>
      <ToastContainer />
    </ThemeProvider>
  );
}
