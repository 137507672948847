import { Link as RouterLink, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import { max } from 'lodash';
import Paper from '@mui/material/Paper';
import {
  Card,
  Stack,
  Avatar,
  Button,
  TextField,
  Container,
  Typography,
  Grid,
  MenuItem,
  Label,
  Item,
  Box,
  Link,
  Divider,
  CardHeader,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import API_URL from '../../data/ApiUrl';

import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';

import { getReq } from '../../data/ApiReq';
import Loading from '../../components/loading';
import { getUserData } from '../../data/userData';

export default function Announcements() {
  const initialState = {
    page: 1,
    limit: 20,
    totalCount: 0,
    totalPages: 0,
    hasNextPage: false,
    searchText: '',
    sort: '',
    qEdit: false,
  };

  const { userId } = useParams();
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [state, setState] = useState(initialState);
  const [filterState, setFilterState] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  const getfilterData = async () => {
    const locRes = await getReq({ url: `/location` });
    if (!locRes.error) {
      setLocationData(locRes.data);
    }

    const comRes = await getReq({ url: `/company` });
    if (!comRes.error) {
      setCompanyData(comRes.data);
    }
  };

  const getData = async () => {
    setLoading(true);

    const res = await getReq({ url: '/news/news' });
    if (!res.error) {
      setNewsList(res.data);
      console.log(res.data);
      setCompany(res?.data?.company?.companyName);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
    getDatas({ pg: state.page, lm: state.limit, isSearch: filterState.searchText, filter: filterState.query });
    getfilterData();
  }, []);

  const getDatas = async (pg, lm, isSearch, filter) => {
    // setLoading(true);

    const p = pg || state.page;
    const l = lm || state.limit;

    let query = `page=${p}&limit=${l}`;
    if (isSearch) {
      query = `page=${p}&limit=${l}&searchText=${isSearch}`;
      if (filter) {
        query += `&${filter}`;
      }
      setFilterState({ ...filterState, searchText: isSearch });
    }
    if (filter) {
      query = `page=${p}&limit=${l}&${filter}`;
      if (filterState.searchText) {
        query += `&searchText=${filterState.searchText}`;
      }
    }

    const response = await getReq({ url: `/news/news?${query}` });

    if (!response.error) {
      setNewsList(response.data);
      setState({
        ...state,
        page: response.pagination.page,
        limit: response.pagination.limit,
        totalCount: response.pagination.totalCount,
        totalPages: response.pagination.totalPages,
        hasNextPage: response.pagination.hasNextPage,
        qEdit: false,
      });
      // setLoading(false);
    }
  };

  const changePage = (event, value) => {
    setState({
      ...state,
      page: value,
    });
    getDatas(value, state.limit, filterState.searchText, filterState.query);
  };

  const getRole = getUserData();
  const role = getRole?.role;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  if (loading) {
    return (
      <Page title="News">
        <Loading />
      </Page>
    );
  }

  const filterOnChange = (e) => {
    const { name, value } = e.target;
    const [id, nameVal] = value.split('|');
    console.log('name', nameVal, value);
    let query = '';
    if (name === 'location') {
      if (id === 'all') {
        if (filterState.compQ && filterState.compQ !== 'all') {
          query += `&company=${filterState.compQ}`;
        }
        setFilterState({ ...filterState, locQ: 'all', locName: 'all', query });
        getDatas({ pg: 1, lm: state.limit, isSearch: filterState.searchText, filter: query });
        return;
      }
      query = `location=${id}`;
      if (filterState.compQ && filterState.compQ !== 'all') {
        query += `&company=${filterState.compQ}`;
      }
      setFilterState({ ...filterState, locQ: id, locName: nameVal, query });
    } else if (name === 'company') {
      if (id === 'all') {
        if (filterState.locQ && filterState.locQ !== 'all') {
          query += `&location=${filterState.locQ}`;
        }
        setFilterState({ ...filterState, compQ: 'all', compName: 'all', query });
        getDatas({ pg: 1, lm: state.limit, isSearch: filterState.searchText, filter: query });
        return;
      }
      query = `company=${id}`;
      if (filterState.locQ && filterState.locQ !== 'all') {
        query += `&location=${filterState.locQ}`;
      }
      setFilterState({ ...filterState, compQ: id, compName: nameVal, query });
    }

    // setFilterState({ ...filterState, query });

    getDatas({ pg: 1, lm: state.limit, isSearch: filterState.searchText, filter: query });
  };

  const FilterSec = () => {
    if (!showFilter) return null;
    return (
      <>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
            select
            label={'Company'}
            style={{ width: '200px' }}
            onChange={filterOnChange}
            name="company"
            value={filterState.compQ ? `${filterState.compQ}|${filterState.compName}` : 'all|all'}
          >
            <MenuItem value="all|all">
              <em>All</em>
            </MenuItem>
            {companyData.map((option, key) => (
              <MenuItem key={key} value={`${option._id}|${option.companyName}`}>
                {option.companyName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label={'Location'}
            style={{ width: '200px' }}
            onChange={filterOnChange}
            name="location"
            value={filterState.locQ ? `${filterState.locQ}|${filterState.locName}` : 'all|all'}
          >
            <MenuItem value="all|all">
              <em>All</em>
            </MenuItem>
            {locationData.map((option, key) => (
              <MenuItem key={key} value={`${option._id}|${option.office}`}>
                {option.office}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </>
    );
  };

  return (
    <Page title="News">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
          <Pagination count={state?.totalPages} color="primary" onChange={changePage} />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            News
          </Typography>
          {role === 'admin' || role === 'hr' ? (
            <>
              <Stack direction="row" alignItems="center">
                <Button
                  onClick={() => setShowFilter(!showFilter)}
                  variant={showFilter ? 'contained' : ''}
                  color="warning"
                  sx={{ mr: 2 }}
                >
                  Filters
                  <Iconify icon="eva:arrow-ios-downward-outline" />
                </Button>

                <TextField
                  sx={{ mb: 3, mt: 3, width: '100%' }}
                  id="outlined-basic"
                  label="Search"
                  size="small"
                  variant="outlined"
                  onChange={(e) =>
                    // setState({ ...state, searchText: e.target.value })
                    getDatas(1, state.limit, e.target.value)
                  }
                />
                <Button
                  sx={{ marginLeft: 2, width: '60%' }}
                  variant="contained"
                  component={RouterLink}
                  to="/dashboard/announcements/addannouncement"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Add News
                </Button>
              </Stack>
            </>
          ) : (
            <></>
          )}

          {role === 'employee' || role === 'it' || role === 'lineManager' || role === 'finance' ? (
            <>
              <Stack direction="row" alignItems="center">
                <Button
                  onClick={() => setShowFilter(!showFilter)}
                  variant={showFilter ? 'contained' : ''}
                  color="warning"
                  sx={{ mr: 2 }}
                >
                  Filters
                  <Iconify icon="eva:arrow-ios-downward-outline" />
                </Button>

                <TextField
                  sx={{ mb: 3, mt: 3, width: '100%' }}
                  id="outlined-basic"
                  label="Search"
                  size="small"
                  variant="outlined"
                  onChange={(e) =>
                    // setState({ ...state, searchText: e.target.value })
                    getDatas(1, state.limit, e.target.value)
                  }
                />
              </Stack>
            </>
          ) : (
            <></>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" mb={3}>
          <FilterSec />
        </Stack>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4 }}>
          {newsList.map((item, key) => (
            <Grid item xs={2} sm={4} md={4} key={key}>
              <Link
                style={{ textDecoration: 'none' }}
                component={RouterLink}
                to={`/dashboard/announcements/readMore/${item._id}?news=${item.title}`}
              >
                <Card sx={{ height: '100%' }}>
                  <Stack spacing={2} key={key}>
                    <Grid container spacing={2} m={2}>
                      <Grid item xs={12}>
                        <div
                          className="user-img2"
                          style={{
                            backgroundImage: `url(${API_URL.AWS_Bucket_url}/public/others/${item?.image?.ogUrl})`,
                          }}
                        />
                        <Typography variant="h5" sx={{ width: '98%' }} gutterTop>
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} textAlign={'start'}>
                        <Typography
                          sx={{ width: '90%' }}
                          dangerouslySetInnerHTML={{ __html: item.description.substring(0, 250) }}
                        />

                        <Box sx={{ mr: 12 }}>
                          {role === 'admin' || role === 'hr' ? (
                            <>
                              <Grid container columns={{ xs: 3 }}>
                                <Grid item xs={1}>
                                  <Button
                                    sx={{ marginTop: 2 }}
                                    variant="contained"
                                    component={RouterLink}
                                    to={`editAnnouncement/${item._id}?news=${item.title}`}
                                    startIcon={<Iconify icon="carbon:view" />}
                                    color="warning"
                                  >
                                    Edit
                                  </Button>
                                </Grid>

                                {item?.company?.companyName ? (
                                  <>
                                    <Grid item xs={1} sx={{ backgroundColor: '#e9f2f7', mt: '2px' }}>
                                      <Typography
                                        sx={{ textAlign: 'center', padding: '2px', fontSize: '13px', mr: 3 }}
                                        gutterTop
                                      >
                                        {item?.company?.companyName.slice(0, 20)}...
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={1} sx={{ backgroundColor: '#E2E2E2', mt: '2px' }}>
                                      <Typography
                                        sx={{ textAlign: 'center', padding: '2px', fontSize: '13px' }}
                                        gutterTop
                                      >
                                        {item?.category.slice(0, 20)}...
                                      </Typography>
                                    </Grid>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Stack>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}
