// component
import Iconify from '../components/Iconify';
import { getUserRole } from './userData';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navItems = () => {
  const adminRoutes = [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
      title: 'employees',
      path: '/dashboard/employees',
      icon: getIcon('eva:people-fill'),
    },
    // {
    //   title: 'Salary',
    //   path: '/dashboard/salary',
    //   icon: getIcon('eva:people-fill'),
    // },
    {
      title: 'Attendance & Leaves',

      path: '/dashboard/attendance/overview',
      icon: getIcon('pepicons:leave'),
    },
    {
      title: 'Asset',
      path: '/dashboard/asset',
      icon: getIcon('eva:shopping-bag-fill'),
    },
    // {
    //   title: 'News',
    //   path: '/dashboard/news',
    //   icon: getIcon('eva:file-text-fill'),
    // },
    // {
    //   title: 'Company Details',
    //   path: '/dashboard/companyDetails',
    //   icon: getIcon('eva:home-fill'),
    // },
    {
      title: 'settings',
      path: '/dashboard/settings',
      icon: getIcon('material-symbols:settings'),
    },
    {
      title: 'IT',
      path: '/dashboard/it',
      icon: getIcon('gridicons:computer'),
    },
    {
      title: 'Documents',
      path: '/dashboard/documents',
      icon: getIcon('mdi:file-document-plus-outline'),
    },
    {
      title: 'Announcements',
      path: '/dashboard/announcements',
      icon: getIcon('mdi:announcement'),
    },
    // {
    //   title: 'Requests',
    //   path: '/dashboard/requests',
    //   icon: getIcon('carbon:request-quote'),
    // },
  ];

  const hrRoutes = [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
      title: 'employees',
      path: '/dashboard/employees',
      icon: getIcon('eva:people-fill'),
    },
    // {
    //   title: 'Salary',
    //   path: '/dashboard/salary',
    //   icon: getIcon('eva:people-fill'),
    // },
    {
      title: 'Attendance & Leaves',

      path: '/dashboard/attendance/overview',

      icon: getIcon('pepicons:leave'),
    },
    {
      title: 'Asset',
      path: '/dashboard/asset',
      icon: getIcon('eva:shopping-bag-fill'),
    },
    // {
    //   title: 'News',
    //   path: '/dashboard/news',
    //   icon: getIcon('eva:file-text-fill'),
    // },
    // {
    //   title: 'Company Details',
    //   path: '/dashboard/companyDetails',
    //   icon: getIcon('eva:home-fill'),
    // },
    {
      title: 'settings',
      path: '/dashboard/settings',
      icon: getIcon('material-symbols:settings'),
    },
    {
      title: 'IT',
      path: '/dashboard/it',
      icon: getIcon('gridicons:computer'),
    },
    {
      title: 'Documents',
      path: '/dashboard/documents',
      icon: getIcon('mdi:file-document-plus-outline'),
    },
    {
      title: 'Announcements',
      path: '/dashboard/announcements',
      icon: getIcon('mdi:announcement'),
    },
    {
      title: 'Requests',
      path: '/dashboard/requests',
      icon: getIcon('carbon:request-quote'),
    },
  ];

  const managerRoutes = [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
      title: 'employees',
      path: '/dashboard/employees',
      icon: getIcon('eva:people-fill'),
    },
    {
      title: 'Attendance & Leaves',
      path: '/dashboard/attendance/overview',

      icon: getIcon('pepicons:leave'),
    },
    // {
    //   title: 'Salary',
    //   path: '/dashboard/salary',
    //   icon: getIcon('eva:people-fill'),
    // },

    // {
    //   title: 'News',
    //   path: '/dashboard/news',
    //   icon: getIcon('eva:file-text-fill'),
    // },
    // {
    //   title: 'Company Details',
    //   path: '/dashboard/companyDetails',
    //   icon: getIcon('eva:home-fill'),
    // },
    {
      title: 'IT',
      path: '/dashboard/it',
      icon: getIcon('gridicons:computer'),
    },
    {
      title: 'Documents',
      path: '/dashboard/documents',
      icon: getIcon('mdi:file-document-plus-outline'),
    },
    {
      title: 'Announcements',
      path: '/dashboard/announcements',
      icon: getIcon('mdi:announcement'),
    },
    // {
    //   title: 'Requests',
    //   path: '/dashboard/requests',
    //   icon: getIcon('carbon:request-quote'),
    // },
  ];

  const employeeRoutes = [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
      title: 'Profile',
      path: '/dashboard/profile/view/employee',
      icon: getIcon('bxs:user-circle'),
    },
    {
      title: 'Attendance & Leaves',
      path: '/dashboard/attendance/ur-attendance',

      icon: getIcon('pepicons:leave'),
    },
    // {
    //   title: 'Salary',
    //   path: '/dashboard/salary',
    //   icon: getIcon('bi:cash-coin'),
    // },
    // {
    //   title: 'News',
    //   path: '/dashboard/news',
    //   icon: getIcon('eva:file-text-fill'),
    // },
    // {
    //   title: 'Company Details',
    //   path: '/dashboard/companyDetails',
    //   icon: getIcon('eva:home-fill'),
    // },
    {
      title: 'IT',
      path: '/dashboard/it',
      icon: getIcon('gridicons:computer'),
    },
    {
      title: 'Documents',
      path: '/dashboard/documents',
      icon: getIcon('mdi:file-document-plus-outline'),
    },
    {
      title: 'Announcements',
      path: '/dashboard/announcements',
      icon: getIcon('mdi:announcement'),
    },
  ];

  const itRoutes = [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
      title: 'Leaves',
      path: '/dashboard/attendance/ur-attendance',

      icon: getIcon('pepicons:leave'),
    },

    {
      title: 'Profile',
      path: '/dashboard/profile/view/employee',
      icon: getIcon('bxs:user-circle'),
    },

    // {
    //   title: 'Salary',
    //   path: '/dashboard/salary',
    //   icon: getIcon('bi:cash-coin'),
    // },
    // {
    //   title: 'News',
    //   path: '/dashboard/news',
    //   icon: getIcon('eva:file-text-fill'),
    // },
    // {
    //   title: 'Company Details',
    //   path: '/dashboard/companyDetails',
    //   icon: getIcon('eva:home-fill'),
    // },
    {
      title: 'IT',
      path: '/dashboard/it',
      icon: getIcon('gridicons:computer'),
    },
    {
      title: 'Documents',
      path: '/dashboard/documents',
      icon: getIcon('mdi:file-document-plus-outline'),
    },
    {
      title: 'Announcements',
      path: '/dashboard/announcements',
      icon: getIcon('mdi:announcement'),
    },
  ];

  const finRoutes = [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: getIcon('eva:pie-chart-2-fill'),
    },

    {
      title: 'Attendance & Leaves',
      path: '/dashboard/attendance/ur-attendance',

      icon: getIcon('pepicons:leave'),
    },

    {
      title: 'Profile',
      path: '/dashboard/profile/view/employee',
      icon: getIcon('bxs:user-circle'),
    },

    // {
    //   title: 'Salary',
    //   path: '/dashboard/salary',
    //   icon: getIcon('bi:cash-coin'),
    // },

    {
      title: 'IT',
      path: '/dashboard/it',
      icon: getIcon('gridicons:computer'),
    },
    {
      title: 'Documents',
      path: '/dashboard/documents',
      icon: getIcon('mdi:file-document-plus-outline'),
    },
    {
      title: 'Announcements',
      path: '/dashboard/announcements',
      icon: getIcon('mdi:announcement'),
    },
  ];

  const officeAdminRoutes = [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
      title: 'Profile',
      path: '/dashboard/profile/view/employee',
      icon: getIcon('bxs:user-circle'),
    },
    {
      title: 'Attendance & Leaves',
      path: '/dashboard/attendance/overview',

      icon: getIcon('pepicons:leave'),
    },
    // {
    //   title: 'Salary',
    //   path: '/dashboard/salary',
    //   icon: getIcon('bi:cash-coin'),
    // },
    // {
    //   title: 'News',
    //   path: '/dashboard/news',
    //   icon: getIcon('eva:file-text-fill'),
    // },
    // {
    //   title: 'Company Details',
    //   path: '/dashboard/companyDetails',
    //   icon: getIcon('eva:home-fill'),
    // },
    {
      title: 'IT',
      path: '/dashboard/it',
      icon: getIcon('gridicons:computer'),
    },
    {
      title: 'Documents',
      path: '/dashboard/documents',
      icon: getIcon('mdi:file-document-plus-outline'),
    },
    {
      title: 'Announcements',
      path: '/dashboard/announcements',
      icon: getIcon('mdi:announcement'),
    },
  ];

  const role = getUserRole();
  if (role === 'admin') {
    return adminRoutes;
  }
  if (role === 'hr') {
    return hrRoutes;
  }

  if (role === 'lineManager') {
    return managerRoutes;
  }

  if (role === 'it') {
    return itRoutes;
  }

  if (role === 'finance') {
    return finRoutes;
  }

  if (role === 'officeAdmin') {
    return officeAdminRoutes;
  }

  return employeeRoutes;
};

export default navItems;
