import React, { useState, useEffect } from 'react';
import { Grid, TextField, Box, Button } from '@mui/material';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from '../../../components/Iconify';
import EventItem from './eventItems';
import { getReq, patchReq } from '../../../data/ApiReq';
import { getUserData } from '../../../data/userData';

export default function LateReason({ events, title, type, desc }) {
  const initialState = {
    reason: '',
  };
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };
  const params = useParams();
  const getRole = getUserData();
  const id = getRole?._id;

  const [loading, setLoading] = useState(false);
  const [apiResult, setApiResult] = useState(initialResult);
  const [state, setState] = useState(initialState);
  const [attendance, setAttendance] = useState('');
  const [checkIn, setCheckIn] =useState('');

  const getData = async () => {
    setLoading(true);
    const today = new Date();
    const dateParams = `${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`;

    const response = await getReq({ url: `/attendance/${dateParams}/${id}` });

    if (response.data) {
      setAttendance(response?.data?._id);
      setCheckIn(response?.data?.checkInMins);
      const { data } = response;

      setState(data);
    }
    // console.log(userData)

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const onChange = (e) => {
    setState({
      ...state,
      reason: e.target.value,
    });
    // console.log(e.target.value);
  };

  const updateReason = async (e, resetForm) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const data = {
      id: attendance,
      reason: state.reason,
      type:"request"
    };

    const response = await patchReq({
      url: `/attendance/laterequest/employee/${attendance}`,
      data,
    });

    if (!response.error) {
      toast.success(response.message);
      // resetForm();
      setApiResult({
        ...apiResult,
        error: false,
        message: response.message,
        alert: true,
        loading: false,
      });
    } else {
      toast.error(response.message);
      setApiResult({
        ...apiResult,
        error: true,
        message: response.message,
        alert: true,
        loading: false,
      });
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      setState(initialState);
    }, 3000);

    setLoading(false);
  };
  return (
    <>
      <div className="dashbord-card">
        <div className="dashboard-card-title">{/* <Iconify icon="fe:birthday-cake" /> <span> {title} </span> */}</div>

        <Grid container spacing={2} mb={3}>
          <p>
            You have arrived late today and are required to provide a reason for your attendance record. Please specify
            the reason for your tardiness.
          </p>
          <Grid item xs={12} md={9}>
            <div>
              <TextField
                fullWidth
                label="Reason"
                name="Reason"
                multiline
                rows={2}
                onChange={onChange}
                // defaultValue={state.description}
                // value={state.description}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div>
              <Box textAlign="center" sx={{ marginTop: 2 }} textCenter>
                <Button className=" brand-buttons" variant="contained" color="primary" onClick={updateReason}>
                  Submit
                </Button>
              </Box>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
