
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Grid, Link } from '@mui/material';

export default function Settings() {

  return (
    <div>
      <Grid container spacing={1} textAlign={'center'}>
   
          <Grid item xs={2}>
            <Link href="settings/getAllHolidays" underline="none" color={'black'}>
              <Button variant="outlined" sx={{ px: 4, py: 2 }}>
                <h3>Holidays</h3>
              </Button>
            </Link>
          </Grid>

          <Grid item xs={2}>
            <Link href="settings/getallcompany" underline="none" color={'black'}>
              <Button variant="outlined" sx={{ px: 4, py: 2 }}>
                <h3>Company</h3>
              </Button>
            </Link>
          </Grid>

          <Grid item xs={2}>
            <Link href="settings/getalllocation" underline="none" color={'black'}>
              <Button variant="outlined" sx={{ px: 4, py: 2 }}>
                <h3>Location</h3>
              </Button>
            </Link>
          </Grid>

          <Grid item xs={2}>
            <Link href="settings/getalldepartment" underline="none" color={'black'}>
              <Button variant="outlined" sx={{ px: 4, py: 2 }}>
                <h3>Department</h3>
              </Button>
            </Link>
          </Grid>

          <Grid item xs={2}>
            <Link href="settings/getallofficetimings" underline="none" color={'black'}>
              <Button variant="outlined" sx={{ px: 4, py: 2 }}>
                <h3>Office Timings</h3>
              </Button>
            </Link>
          </Grid>
    
      </Grid>
    </div>
  );
}
