import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  Box,
  TableHead,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Iconify from '../../components/Iconify';
// components
import Loading from '../../components/loading'

import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getReq } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';


export default function Holidays() {
  const [empList, setEmpList] = useState([]);
  const [loading, setLoading] = useState(false);


  const getData = async () => {
    setLoading(true);

    const res = await getReq({ url: '/company' });
    if (!res.error) {
      setEmpList(res.data);
    }

    setLoading(false);

  };
console.log(empList);
  useEffect(() => {
    getData();
  }, []);

  if(loading){
    return(
      <Page title="Holidays">
        <Loading/>
      </Page>
    )
  }

  return (
    <Page title="Holidays">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Company
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/company/new"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
           Add Company
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.</TableCell>
                    {/* <TableCell>Employee ID</TableCell> */}
                    <TableCell>Logo</TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {empList.map((row, index) => (
                    <TableRow hover key={index}>
                      <TableCell>{index + 1}</TableCell>
                      {/* <TableCell>{row.employeeId}</TableCell> */}
                      <TableCell>
                      <div className="product-data">
                        <div className="product-data-font">
                          {row.logo ? (
                            <img
                              style={{ height: '80px' }}
                              src={`${API_URL.img_url}/api/uploads/images/${row.logo.ogUrl}`}
                              alt={'product'}
                              className="order-product-img"
                            />
                          ) : (
                            <strong>No Logo</strong>
                          )}
                        </div>
                      </div>
                      </TableCell>
                      <TableCell>{row.companyName}</TableCell>
                      <TableCell>{row.address}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          component={RouterLink}
                          to={`/dashboard/company/single/${row._id}?company=${row.companyName}&address=${row.address}${
                            row.logo ? `&logo=${row.logo.ogUrl}` : ''
                          }`}
                          startIcon={<Iconify icon="carbon:view" />}
                          color="warning"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
