/* eslint-disable prefer-template */
/* eslint-disable no-restricted-syntax */
import * as React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  useNavigate,
  Route,
  Link,
  useParams,
  useSearchParams,
  Link as RouterLink,
} from 'react-router-dom';

import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import {
  Stack,
  Checkbox,
  Container,
  Grid,
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Tabs,
  Tab,
  CardMedia,
  Item,
  Button,
  MenuItem,
} from '@mui/material';

import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Iconify from '../../components/Iconify';
import ReportModal from './componets/reportModal';

// Context
import { DateContext } from '../../context/dateContext';

// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getReq } from '../../data/ApiReq';
import { getUserRole } from '../../data/userData';
import SmallCalender from '../../components/calenders/smallCalender';
import AttendanceOverview from './attendanceOverview';
import AttendanceViewSec from './attendanceViewSec';

export default function GetTodayAttendance() {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const today = new Date();
  const initDate = {
    month: today.getMonth(),
    day: today.getDate(),
    year: today.getFullYear(),
    isClicked: false,
  };

  const paginationInit = {
    page: 1,
    limit: 100,
    totalCount: 1,
    totalPages: 1,
    hasNextPage: false,
  };

  const initialState = {
    page: 1,
    limit: 100,
    totalCount: 0,
    totalPages: 0,
    hasNextPage: false,
    searchText: '',
    sort: '',
    qEdit: false,
  };
  const [uRoll, setRoll] = useState('not');
  const [selcetedDate, setSelcetedDate] = React.useState(initDate);
  // const formatedDate = `${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`;
  const formatedDate = `${selcetedDate.month + 1}-${selcetedDate.day}-${selcetedDate.year}`;
  const [customDate, setCustomDate] = React.useState(formatedDate);

  const [value, setValue] = React.useState(0);
  const [overviewData, setOverviewData] = useState([]);
  const [todaysOverview, setTodaysOverview] = useState({});
  const [attendanceList, setAttendanceList] = useState([]);
  const [absentList, setAbsentList] = useState([]);
  const [presentDataPagination, setPresentDataPagination] = useState(paginationInit);
  const [absentDataPagination, setAbsentDataPagination] = useState(paginationInit);
  const [filterState, setFilterState] = useState({});
  const [locationData, setLocationData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isClicked, setIsClicked] = useState('');
  const [state, setState] = useState(initialState);

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter the data based on the search term
  const filteredData = absentList.filter((item) => {
    return item.firstName && item.firstName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // Filter the data based on the search term
  const filteredDataList = attendanceList.filter((item) => {
    return item?.userId?.firstName && item?.userId?.firstName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      navigate('/dashboard/leaves?section=overview', { replace: true });
    }
    if (newValue === 1) {
      navigate('/dashboard/leaves?section=ur_attendence', { replace: true });
    } else if (newValue === 2) {
      navigate('/dashboard/leaves?section=requests', { replace: true });
    }
    setValue(newValue);
    return 1;
  };

  const handlesectionParams = async () => {
    const section = searchParams.get('section');
    const UsrRole = await userRole();
    if (section === 'overview') {
      setValue(0);
    } else if (section === 'ur_attendence') {
      if (UsrRole === 'employee') {
        setValue(0);
      } else {
        setValue(1);
      }
    } else if (section === 'requests') {
      setValue(2);
    }
    return 1;
  };

  useEffect(() => {
    // userRole();
    handlesectionParams();
  }, [searchParams]);

  // console.log('-----> test', selcetedDate);

  const userRole = async () => {
    const role = await getUserRole();

    setRoll(role);
    return role;
  };

  useEffect(() => {
    userRole();
    getPresentData({ cDate: customDate });
    getAbsentData({ cDate: customDate });
    getOverviewData();
    getfilterData();
  }, []);

  const getOverviewData = async (url) => {
    if (!url) url = `/attendance/overview/daily`;
    const overViewRes = await getReq({
      url,
    });
    // console.log('_0-0-0-0 Month Cahnge', overViewRes);

    if (!overViewRes.error) {
      setOverviewData(overViewRes.data.attendanceData);
      setTodaysOverview(overViewRes.data.todaysOverview);
    }
  };

  const getPresentData = async ({ cDate, pg, lm, filter }) => {
    let query = cDate || customDate;

    // console.log('query', query);

    const p = pg || presentDataPagination.page;
    const l = lm || presentDataPagination.limit;

    query = `${query}?page=${p}&limit=${l}`;

    if (filter) {
      query = `${query}?page=${p}&limit=${l}&${filter}`;
    }

    const res = await getReq({ url: `/attendance/present/${query}` });
    if (!res.error) {
      setAttendanceList(res.data);
      setPresentDataPagination(res.pagination);
    }
  };

  const getAbsentData = async ({ cDate, pg, lm, filter }) => {
    let query = cDate || customDate;

    // console.log('query', query);

    const p = pg || absentDataPagination.page;
    const l = lm || absentDataPagination.limit;

    query = `${query}?page=${p}&limit=${l}`;
    if (filter) {
      query = `${query}?page=${p}&limit=${l}&${filter}`;
    }
    const res = await getReq({ url: `/attendance/absent/${query}` });
    if (!res.error) {
      setAbsentList(res.data);
      setAbsentDataPagination(res.pagination);
    }
  };
  const islate = (checkInTime, attendanceStatus, cIn) => {
    if (attendanceStatus === 'notApplicable') {
      return false;
    }

    if (checkInTime > cIn + 10) return true;

    // if (min > 5) return true;

    return false;
  };

  const earlyLeave = (checkOutTime, attendanceStatus, cOut) => {
    if (attendanceStatus === 'notApplicable') {
      return false;
    }

    if (checkOutTime < cOut) return true;

    return false;
  };

  const presentDataPageChange = (event, value) => {
    setPresentDataPagination({
      ...presentDataPagination,
      page: value,
    });
    getPresentData({
      pg: value,
      lm: presentDataPagination.limit,
    });
  };

  const getfilterData = async () => {
    const locRes = await getReq({ url: `/location` });
    if (!locRes.error) {
      setLocationData(locRes.data);
    }

    const comRes = await getReq({ url: `/company` });
    if (!comRes.error) {
      setCompanyData(comRes.data);
    }
  };

  const filterOnChange = ({ name, value }) => {
    const [id, nameVal] = value.split('|');
    setIsClicked(id);

    // console.log('name', nameVal, value);
    let query = '';
    if (name === 'location') {
      if (id === 'all') {
        if (filterState.compQ && filterState.compQ !== 'all') {
          query += `&company=${filterState.compQ}`;
        }
        setFilterState({ query });
        getPresentData({
          pg: 1,
          lm: presentDataPagination.limit,
          filter: query,
        });
        getAbsentData({
          pg: 1,
          lm: presentDataPagination.limit,
          filter: query,
        });
        return;
      }
      query = `location=${id}`;
      if (filterState.compQ && filterState.compQ !== 'all') {
        query += `&company=${filterState.compQ}`;
      }
      setFilterState({ query });
    } else if (name === 'company') {
      if (id === 'all') {
        if (filterState.locQ && filterState.locQ !== 'all') {
          query += `&location=${filterState.locQ}`;
        }
        setFilterState({ query });
        // setFilterState({ ...filterState, compQ: 'all', compName: 'all', query });
        //  getData({ pg: 1, lm: state.limit, isSearch: filterState.searchText, filter: query });
        getPresentData({
          pg: 1,
          lm: presentDataPagination.limit,
          filter: query,
        });
        getAbsentData({
          pg: 1,
          lm: presentDataPagination.limit,
          filter: query,
        });
        return;
      }
      query = `company=${id}`;
      if (filterState.locQ && filterState.locQ !== 'all') {
        query += `&location=${filterState.locQ}`;
      }
      setFilterState({ query });
      // setFilterState({ ...filterState, compQ: id, compName: nameVal, query });
    }

    getPresentData({
      pg: 1,
      lm: presentDataPagination.limit,
      filter: query,
    });

    getAbsentData({
      pg: 1,
      lm: presentDataPagination.limit,
      filter: query,
    });
  };

  const AttendenceList = () => (
    <div>
      {(uRoll === 'admin' || uRoll === 'lineManager' || uRoll === 'hr' || uRoll === 'officeAdmin') && (
        <Box>
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              <Typography variant="h4" gutterBottom>
                Attendance Overview
              </Typography>
              <Stack direction="row" alignItems="center" justifyContent="flex-end">
                <ReportModal />

                {presentDataPagination.totalPages > 1 && (
                  <Pagination
                    count={presentDataPagination?.totalPages}
                    color="primary"
                    onChange={presentDataPageChange}
                    page={presentDataPagination?.page}
                  />
                )}
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" mb={1}>
              {/* <AttendanceFilter  /> */}

              <Grid container justifyContent="center" alignItems={'center'}>
                <Grid item xs={12} alignItems="center" justifyContent={'center'}>
                  <Stack
                    direction={{
                      xs: 'column',
                      sm: 'row',
                    }}
                  >
                    {companyData.map((option, key) => (
                      <div key={key} className="card-dxx-title-wrap">
                        <Button
                          color="primary"
                          variant={isClicked === option._id ? 'contained' : 'outlined'}
                          onClick={() =>
                            filterOnChange({ name: 'company', value: `${option._id}|${option.companyName}` })
                          }
                          name="company"
                          value={filterState.compQ ? `${filterState.compQ}|${filterState.compName}` : 'all|all'}
                          sx={{ height: '50px', width: '250px' }}
                        >
                          {option.companyName}
                        </Button>
                      </div>
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>

            <Stack direction="row" alignItems="center" mb={1}>
              {/* <AttendanceFilter  /> */}
              <Grid container justifyContent="center" alignItems={'center'}>
                <Grid item xs={12} alignItems="center" justifyContent={'center'}>
                  <Stack
                    direction={{
                      xs: 'column',
                      sm: 'row',
                    }}
                  >
                    {locationData.map((option, key) => (
                      <div key={key} className="card-dxx-title-wrap">
                        <Button
                          color="success"
                          variant={isClicked === option._id ? 'contained' : 'outlined'}
                          sx={{ height: '50px', width: '250px' }}
                          onClick={() => filterOnChange({ name: 'location', value: `${option._id}|${option.office}` })}
                          name="location"
                          value={filterState.locQ ? `${filterState.locQ}|${filterState.office}` : 'all|all'}
                        >
                          {option.office}
                        </Button>
                      </div>
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => filterOnChange({ name: 'company', value: 'all|all' })}
                name="company"
                value={filterState.compQ ? `${filterState.compQ}|${filterState.compName}` : 'all|all'}
              >
                Clear
              </Button>
            </Stack>
            <Card style={{ position: 'relative' }}>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Typography variant="h4" gutterBottom m={2}>
                    Absent List
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl.</TableCell>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Check In</TableCell>
                        <TableCell>Check Out</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {filteredData.map((row, index) => (
                        <TableRow hover key={index} className={islate(row.checkIntimeStamp) ? 'bg-waring' : ''}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{row.employeeId}</TableCell>
                          <TableCell>
                            {row.firstName} {row.lastName}
                          </TableCell>
                          <TableCell>Nill</TableCell>
                          <TableCell>Nill</TableCell>
                          <TableCell>Nill</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <Typography variant="h4" gutterBottom m={2}>
                    Present List
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl.</TableCell>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Check In</TableCell>
                        <TableCell>Check Out</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredDataList.map((row, index) => (
                        <TableRow hover key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{row?.userId?.employeeId}</TableCell>
                          <TableCell>
                            {row?.userId?.firstName} {row?.userId?.lastName}
                          </TableCell>
                          <TableCell>{row.office?.split(',')[0]}</TableCell>
                          <TableCell
                            className={
                              islate(row.checkInMins, row.attendanceStatus, row.sheduledInTime) ? 'bg-waring-2' : ''
                            }
                          >
                            {row.checkInLocalTime}
                          </TableCell>
                          <TableCell
                            className={
                              earlyLeave(row.checkOutMins, row.attendanceStatus, row.sheduledOutTime)
                                ? 'bg-waring-2'
                                : ''
                            }
                          >
                            {row.checkOutLocalTime}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Container>
        </Box>
      )}
    </div>
  );

  const RoleBasedView = () => {
    if (uRoll === 'admin' || uRoll === 'lineManager' || uRoll === 'hr' || uRoll === 'officeAdmin' || uRoll === 'it') {
      return (
        <Container>
          <AttendanceViewSec attendanceData={overviewData} todaysOverview={todaysOverview} />

          <AttendenceList />
        </Container>
      );
    }
    return (
      <Container>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Your Attendence" />
        </Tabs>
      </Container>
    );
  };

  const userName = searchParams.get('userName');

  // console.log('userName', userName);

  let pageTitle = 'Attendance';
  if (
    (uRoll === 'admin' || uRoll === 'lineManager' || uRoll === 'hr' || uRoll === 'officeAdmin' || uRoll === 'it') &&
    value === 0
  ) {
    pageTitle = ' ';
  } else if (
    (uRoll === 'admin' || uRoll === 'lineManager' || uRoll === 'hr' || uRoll === 'officeAdmin' || uRoll === 'it') &&
    value === 1
  )
    if (value === 1 && userName) {
      pageTitle = `${userName}'s Attendance`;
    }

  return (
    <Page title={pageTitle}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            {pageTitle}
          </Typography>
        </Stack>
      </Container>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" m={2}>
          <TextField
            sx={{ width: '30%', marginRight: '8px' }}
            id="hai"
            label="Search"
            size="small"
            autoComplete="off"
            type="text"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
          />
        </Stack>
      </Container>
      <RoleBasedView />
    </Page>
  );
}
