import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Link } from '@mui/material';

export default function VewsCard({ item }) {
  const desc = item?.description?.slice(0, 100);
  return (
    <div className="dashbord-news-card">
      <div className="dashboard-news-card-title">{item?.title}</div>
      <div className="dashboard-news-card-footer">
        <Link
          style={{ textDecoration: 'none' }}
          component={RouterLink}
          to={`/dashboard/announcements/readMore/${item._id}?news=${item.title}`}
        >
          Read More
        </Link>
        {item?.category && <div className="dashboard-news-card-cat">{item?.category}</div>}
      </div>
      {/* <div className="dashboard-card-content">{desc}</div> */}
    </div>
  );
}
