import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
// import ReactLoading from 'react-loading';
// @mui
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import 'moment-timezone';
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert,
  Container,
  Box,
  Typography,
  Card,
  CardActions,
  Button,
  CardContent,
  Grid,
} from '@mui/material';
import { formatDate } from '@fullcalendar/react';
import { postReq, getReq, patchReq, deleteRequest } from '../../data/ApiReq';

// components
import Page from '../../components/Page';
import Loading from '../../components/loading';

import Iconify from '../../components/Iconify';
import ApiUrl from '../../data/ApiUrl';

const queryString = require('query-string');

const moment = require('moment-timezone');

moment().format('YYYYMMDD');

// ----------------------------------------------------------------------

export default function AddAssets() {
  const initialState = {
    deviceName: '',
    serialNo: '',
    description: '',
    employee: '',
    dateOfAssigning: '',
    remarks: '',
    type: 'new',
  };
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };
  const params = useParams();
  const location = useLocation();

  const [brandData, setBrand] = useState([]);
  const [state, setState] = useState(initialState);
  const [apiResult, setApiResult] = useState(initialResult);
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value);
  };

  const handleChange = (event, values) => {
    setState({
      ...state,
      employee: values._id,
    });
  };

  const getData = async () => {
    if (!params.id) {
      return;
    }
    setLoading(true);

    setState({
      ...state,
      type: 'edit',
      deviceName: state.deviceName,
      serialNo: state.serialNo,
      description: state.description,
      employee: state.employee,
      dateOfAssigning: state.dateOfAssigning,
      remarks: state.remarks,
    });

    const response1 = await getReq({ url: `/user/getalluser` });
    if (!response1.error) {
      setEmployeeList(response1.data);
    }

    const response = await getReq({ url: `/asset/${params.id}` });

    // console.log('response--->');
    // console.log(response);

    if (response.data) {
      const { data } = response;
      // const today = new Date();

      // Formating the date for the date picker
      if (data.dateOfAssigning) data.dateOfAssigning = moment(new Date(data.dateOfAssigning)).format('YYYY-MM-DD');

      setState(data);
    }
    // console.log(userData)

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const AssetSchema = Yup.object().shape({
    deviceName: Yup.string().min(3, 'Please enter a device name').required('Device Name is required'),
    serialNo: Yup.string().min(3, 'Please enter Serial Number').required('Serial Number is required'),
    description: Yup.string().min(3, 'Please enter a description').required('Description is required'),
    employee: Yup.string().min(3, 'Please enter a employee').required('Employee is required'),
    dateOfAssigning: Yup.date('Please enter valid date').required('Date is required'),
    remarks: Yup.string().min(3, 'Please add remarks').required('Remarks is required'),
  });

  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    validationSchema: AssetSchema,
    // onChange: (e) => {
    //   console.log(e);
    // },
    onSubmit: (values, { resetForm }) => {
      console.log('onSubmit values===============>', values);

      updateBrand(values, resetForm);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const createAssets = async (e) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true, alert: true });
    e.preventDefault();
    const data = {
      deviceName: state.deviceName,
      serialNo: state.serialNo,
      // description: state.description,
      // employee: state.employee,
      // dateOfAssigning: state.dateOfAssigning,
      // remarks: state.remarks,
    };

    if (state.description) data.description = state.description;
    // if (state.employee) data.employee = state.employee;
    // if (state.dateOfAssigning) data.dateOfAssigning = state.dateOfAssigning;
    if (state.remarks) data.remarks = state.remarks;

    const response = await postReq({
      url: '/asset/submit',
      data,
    });

    if (!response.error) {
      toast.success(response.message);
      setState(initialState);
      setApiResult({
        ...apiResult,
        error: false,
        message: response.message,
        alert: true,
        loading: false,
      });
    } else {
      toast.error(response.message);
      setApiResult({
        ...apiResult,
        error: true,
        message: response.message,
        alert: true,
        loading: false,
      });
      console.log(response.message);
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      // window.location.reload(false);
    }, 3000);

    setLoading(false);
  };

  const updateBrand = async (e, resetForm) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const data = {
      deviceName: state.deviceName,
      serialNo: state.serialNo,
      description: state.description,
      employee: state.employee,
      dateOfAssigning: state.dateOfAssigning,
      remarks: state.remarks,
    };

    // data.id = userId;
    if (state.deviceName) data.deviceName = state.deviceName;
    if (state.serialNo) data.serialNo = state.serialNo;
    if (state.description) data.description = state.description;
    if (state.employee) data.employee = state.employee;
    if (state.dateOfAssigning) data.dateOfAssigning = state.dateOfAssigning;
    if (state.remarks) data.remarks = state.remarks;

    // only for making slug remove this
    data.slug = state.deviceName;

    const response = await patchReq({
      url: `/asset/respond/${params.id}`,
      data,
    });

    if (!response.error) {
      toast.success(response.message);
      // resetForm();
      setApiResult({
        ...apiResult,
        error: false,
        message: response.message,
        alert: true,
        loading: false,
      });
    } else {
      toast.error(response.message);
      setApiResult({
        ...apiResult,
        error: true,
        message: response.message,
        alert: true,
        loading: false,
      });
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      setState(initialState);
    }, 3000);

    setLoading(false);
  };

  const deleteAsset = async (e) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    console.log('id--', params.id);

    const response = await deleteRequest({ url: `asset/delete?id=${params.id}` });
    console.log(response.data.message);

    if (!response.error) {
      toast.success(response.data.message);
      setApiResult({ redirect: true });
    } else {
      toast.error(response.data.message);
      setApiResult({
        ...apiResult,
        error: true,
        alert: true,
        message: response.data.message,
        loading: false,
      });
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
    }, 3000);

    setLoading(false);
  };

  // const getObjectFromArr = (arr, id) => {
  //   arr.find((item) => item._id === id);
  // };

  // const formateDate = (date) => {
  //   const newDate = new Date(date);
  //   const day = newDate.getDate();
  //   const month = newDate.getMonth() + 1;
  //   const year = newDate.getFullYear();
  //   const time = newDate.toLocaleTimeString();
  //   return (
  //     <div>
  //       {month}-{day}-{year} <br />
  //     </div>
  //   );
  // };

  console.log(formatDate(state.dateOfAssigning));

  if (loading) {
    return (
      <Page title={params.id ? 'Edit Asset' : 'Add Asset'}>
        <Loading />
      </Page>
    );
  }

  return (
    <Page title={params.id ? 'Edit Asset' : 'Add Asset'}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography
            variant="h4"
            gutterBottom
            sm="4"
            title={params.id ? 'Edit Asset' : 'Add Asset'}
            subtitle=" "
            className="text-sm-left"
          >
            {params.id ? 'Edit Asset' : 'Add Asset'}
          </Typography>

          <Button
            variant="text"
            component={RouterLink}
            to={`/dashboard/asset`}
            startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
            color={'primary'}
          >
            Asset List
          </Button>
        </Stack>
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={7}>
            <Card>
              <CardContent>
                <div>
                  <FormikProvider>
                    {/* <Form autoComplete="off" noValidate > */}
                    <Stack spacing={3}>
                      <Typography variant="h5" gutterBottom>
                        Basic Details
                      </Typography>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={5} style={{ width: '100%' }}>
                          <TextField
                            fullWidth
                            label="Device Name"
                            name="deviceName"
                            onChange={onChange}
                            defaultValue={state.deviceName}
                            value={state.deviceName}
                          />

                          <TextField
                            fullWidth
                            label="Device ID"
                            name="serialNo"
                            onChange={onChange}
                            defaultValue={state.serialNo}
                            value={state.serialNo}
                          />

                          <TextField
                            fullWidth
                            label="Description"
                            name="description"
                            multiline
                            rows={6}
                            onChange={onChange}
                            defaultValue={state.description}
                            value={state.description}
                          />

                          {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <Autocomplete
                              InputLabelProps={{ shrink: true }}
                              // disablePortal
                              id="combo-box-demo"
                              options={employeeList}
                              // getOptionLabel={(option) => option.firstName ? option.firstName : ""}
                              isOptionEqualToValue={(option, value) => option.firstName === value.firstName}
                              value={getObjectFromArr(employeeList, state.employee)}
                              name="employee"
                              sx={{ width: '100% ' }}
                              onChange={handleChange}
                              renderInput={(params) => <TextField {...params} label="Employee" />}
                              {...getFieldProps('employee')}
                              // defaultValue={state.employee}
                              //   value={state.description}
                              // error={Boolean(touched.employee && errors.employee)}
                              // helperText={touched.employee && errors.employee}
                            />
                          </Stack> */}

                          {/* <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            select
                            label="Employee"
                            name="employee"
                            onChange={onChange}
                            type="text"
                            defaultValue={state.employee}
                            value={state.employee}
                          >
                            {employeeList.map((option, key) => (
                              <MenuItem key={key} value={option._id}>
                                {option.firstName} {option.lastName}
                              </MenuItem>
                            ))}
                          </TextField> */}
                          {/* 
                          <TextField
                            id="outlined-number"
                            label="Date Of Assigning"
                            type="date"
                            name="dateOfAssigning"
                            onChange={onChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            defaultValue={state.dateOfAssigning}
                            value={state.dateOfAssigning}
                          /> */}
                          <TextField
                            fullWidth
                            label="Remarks"
                            name="remarks"
                            onChange={onChange}
                            defaultValue={state.remarks}
                            value={state.remarks}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                    {state.type === 'new' ? (
                      <Box textAlign="center" sx={{ marginTop: 2 }}>
                        <Button className=" brand-buttons" variant="contained" color="primary" onClick={createAssets}>
                          Submit
                        </Button>
                      </Box>
                    ) : (
                      <>
                        <Box textAlign="end" sx={{ marginTop: 2 }}>
                          <Button
                            sx={{ marginRight: 2 }}
                            onClick={deleteAsset}
                            color="error"
                            className="mr-4 brand-buttons"
                            variant="contained"
                          >
                            Delete
                          </Button>

                          <Button
                            onClick={updateBrand}
                            color="warning"
                            className="mr-4 brand-buttons"
                            variant="contained"
                          >
                            Update
                          </Button>
                        </Box>
                      </>
                    )}
                    {/* </Form> */}
                  </FormikProvider>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
