import React, { useState, useEffect } from 'react';
import { Grid, Typography, Link } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import AttendanceOverview from './componets/attendanceOverview';
import EventCard from './componets/eventCard';
import { postReq, getReq } from '../../data/ApiReq';
import Iconify from '../../components/Iconify';
import Newscard from './componets/newsCard';
import LateReason from './componets/LateReason';
import { getUserData } from '../../data/userData';

export default function OfficeAdminDashboard() {
  const [loading, setLoading] = useState(false);
  const [checkIn, setCheckIn] = useState('');

  const [events, setEvents] = useState({
    eIdValidity: [],
    passportValidity: [],
    birthdays: [],
    anniversaries: [],
  });
  const [newsList, setNewsList] = useState([]);

  const getEventsData = async () => {
    setLoading(true);

    const res = await getReq({ url: `/events` });
    if (!res.error) {
      setEvents(res.data);
    }

    console.log('events', res.data);
    setLoading(false);
  };
  const getNews = async () => {
    setLoading(true);

    const res = await getReq({ url: '/news/news?limit=6' });
    if (!res.error) {
      setNewsList(res.data);
      // console.log('news', res.data);
    }
    setLoading(false);
  };
  const getRole = getUserData();
  const id = getRole?._id;

  const getCheckIn = async () => {
    setLoading(true);
    const today = new Date();
    const dateParams = `${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`;

    const res = await getReq({ url: `/attendance/${dateParams}/${id}` });
    if (!res.error) {
      setCheckIn(res?.data?.checkInMins);
      // console.log('news', res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEventsData();
    getNews();
    getCheckIn();
  }, []);

  return (
    <>
      <section>
        <AttendanceOverview />
      </section>
      {checkIn > 550 ? (
        <section>
          <Typography variant="h6" gutterBottom>
            Late Arrival Explanation - Requesting Attendance
          </Typography>

          <Grid container spacing={2} mb={3}>
            <Grid item xs={12} md={12}>
              <LateReason />
            </Grid>
          </Grid>
        </section>
      ) : (
        <> </>
      )}
      <section>
        <Typography variant="h6" gutterBottom>
          Events & Notifications
        </Typography>
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} md={6}>
            <EventCard events={events.eIdValidity} title="Emirates ID Expiry" type="eid" desc="No data fond" />
          </Grid>
          <Grid item xs={12} md={6}>
            <EventCard events={events.passportValidity} title="Passport Expiry" type="visa" desc="No Data" />
          </Grid>
          <Grid item xs={12} md={6}>
            <EventCard events={events.birthdays} title="Birthdays" type="birthday" desc="No birthdays fond" />
          </Grid>
          <Grid item xs={12} md={6}>
            <EventCard events={events.anniversaries} title="Work Anniversaries" type="anniversary" desc="No Data" />
          </Grid>
        </Grid>
      </section>
      <section>
        {/* Recent Announcement */}

        <div style={{ marginTop: '30px' }}>
          <Typography variant="h6" gutterBottom>
            <Iconify icon="mdi:announcement" />
            Recent Announcements
          </Typography>

          <Grid container spacing={3}>
            {newsList?.map((item, key) => (
              <Grid item xs={12} md={6} lg={4} key={key}>
                <Newscard item={item} />
              </Grid>
            ))}
          </Grid>
          <div className="news-view-more">
            <Link style={{ textDecoration: 'none' }} component={RouterLink} to={`/dashboard/announcements`}>
              View More
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
