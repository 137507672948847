// import "./App.css";
import * as React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Stack, Typography, Container, Button, Box, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import { blue } from '@mui/material/colors';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { getUserData } from '../../data/userData';

import Loading from '../../components/loading';
import { getReq, deleteRequest } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';

const getIcon = (name) => <Iconify icon={name} width={62} height={62} />;
const viewIcon = (name) => <Iconify icon={name} width={26} height={26} />;

function Document() {
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };
  const [documentList, setDocumentList] = useState([]);
  const [folderList, setFolderList] = useState([]);
  const [selcetedFolder, setSelcetedFolder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [fileId, setFileId] = useState('');
  const navigate = useNavigate();

  const [apiResult, setApiResult] = useState(initialResult);

  const options = ['Delete', 'View'];
  function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };

    const deleteDocument = async (e) => {
      const response = await deleteRequest({ url: `document/delete?id=${e}` });

      if (!response.error) {
        toast.success(response.data.message);
        navigate(0);
      } else {
        toast.error(response.data.message);
        setApiResult({
          ...apiResult,
          error: true,
          alert: true,
          message: response.data.message,
          loading: false,
        });
      }
    };

    return (
      <Dialog onClose={handleClose} open={open}>
        <List sx={{ pt: 0, width: '300px' }}>
          <ListItem disableGutters>
            <ListItemButton onClick={() => setShowModal(true)}>
              <ListItemText onClick={() => setIsHidden(!isHidden)} style={{ display: isHidden ? 'none' : 'block' }}>
                Delete
              </ListItemText>
              {showModal && (
                <List sx={{ pt: 0, width: '500px' }}>
                  <ListItemText>Are you sure you want to delete?</ListItemText>
                  <Button
                    sx={{ margin: 2 }}
                    onClick={() => deleteDocument(fileId)}
                    color="error"
                    className="mr-4 brand-buttons"
                    variant="contained"
                  >
                    Yes
                  </Button>
                  <Button
                    sx={{ margin: 2 }}
                    onClick={() => navigate(0)}
                    color="warning"
                    className="mr-4 brand-buttons"
                    variant="contained"
                  >
                    No
                  </Button>
                </List>
              )}
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
    );
  }

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(options[1]);

  const getRole = getUserData();
  const role = getRole?.role;

  const handleClickOpen = (id) => {
    setOpen(true);
    setFileId(id);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  const getFolders = async () => {
    const res = await getReq({ url: '/document/category/getallcategory' });
    if (!res.error) {
      setFolderList(res.data);
    }
    setLoading(false);
  };
  const getData = async () => {
    setLoading(true);

    const res = await getReq({ url: '/document' });
    if (!res.error) {
      setDocumentList(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
    getFolders();
  }, []);

  if (loading) {
    return (
      <Page title="Documents">
        <Loading />
      </Page>
    );
  }

  // console.log(folderList, documentList);

  const FileList = () => (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={2} sm={4} md={2} sx={{ marginBottom: 4 }}>
          <button type="button" className="folder-wrap" onClick={() => setSelcetedFolder(null)}>
            {getIcon('material-symbols:arrow-back')}
            <Typography variant="body2" color="text.secondary">
              Back
            </Typography>
          </button>
        </Grid>
        {documentList.map((item, key) => (
          <Grid item xs={2} sm={4} md={3} key={key} sx={{ marginBottom: 4 }}>
            <div style={{ marginLeft: '45px' }}>
              <a
                href="#"
                onClick={() => handleClickOpen(item._id)}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {viewIcon('mdi:dots-vertical')}
              </a>
            </div>

            <a
              href={`${API_URL.img_url}/api/uploads/files/${item?.file?.url}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {getIcon('mdi:file-document')}
              <Typography variant="body2" color="text.secondary">
                {item.title}
              </Typography>
            </a>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const FolderList = () => (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {folderList.map((item, key) => (
          <Grid item xs={2} sm={4} md={2} key={key} sx={{ marginBottom: 4 }}>
            <button type="button" className="folder-wrap" onClick={() => setSelcetedFolder(item._id)}>
              {getIcon('material-symbols:folder-open-rounded')}
              <Typography variant="body2" color="text.secondary">
                {item.title}
              </Typography>
            </button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <Page title="Documents">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Documents
          </Typography>

          {role === 'hr' || role === 'admin' ? (
            <>
              <Stack direction="row" alignItems="center">
                <Button
                  variant="contained"
                  sx={{ mr: 3 }}
                  component={RouterLink}
                  to="/dashboard/documents/adddocumentcategory"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Upload Document Category
                </Button>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to="/dashboard/document/adddocument"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Upload Document
                </Button>
              </Stack>
            </>
          ) : (
            <></>
          )}

          {/* <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/document/adddocument"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Upload Document
          </Button> */}
        </Stack>

        {/* <FileList /> */}

        {selcetedFolder ? <FileList /> : <FolderList />}

        <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />

        {/* {documentList.map((item, key) => (
          <div key={key} style={{backgroundColor:'red',width:'auto'}}>
            {getIcon('mdi:file-document')}
            <Typography variant="body2" color="text.secondary">
              {item.title}
            </Typography>

          </div>
        ))} */}
      </Container>
    </Page>
  );
}

export default Document;
