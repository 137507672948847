/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Moment from 'react-moment';

import { toast } from 'react-toastify';
// @mui

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { styled } from '@mui/material/styles';
import {
  Link,
  Stack,
  TextField,
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Loading from '../../components/loading';
import { getUserRole, getUserData } from '../../data/userData';
import { getReq, patchReq, postReq } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';

export default function RequestSingle() {
  const params = useParams();

  const initialState = {
    remark: '',
    state: '',
    status: '',
  };
  const initialResult = {
    error: false,
    message: '',
    alert: false,
    loading: false,
    dataFetched: false,
    redirect: false,
  };

  const [loading, setLoading] = useState(false);
  const [apiResult, setApiResult] = useState(initialResult);
  const [state, setState] = useState(initialState);
  const [status, setstatus] = useState('');

  const [requestDetails, setRequestDetails] = useState({});


  const getData = async () => {
    setLoading(true);

    const res = await getReq({ url: `/request/${params.id}` });
    console.log(res);
    if (!res.error) {
      setRequestDetails(res.data);
    }
    setLoading(false);
  };

  // console.log(requestDetails.ticketReq);
  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    setstatus(e.target.value);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const leaveState = requestDetails.state;
  const isAccepted = leaveState === 'accepted';
  const isRejected = leaveState === 'rejected';
  const isPending = leaveState === 'pending';

  const requestUpdateSubmit = async (e, resetForm) => {
    setLoading(true);

    setApiResult({ ...apiResult, loading: true });
    e.preventDefault();
    const data = {
      remark: state.remark,
      state: state.state,
      status: state.status,
    };

    // data.id = userId;
    if (state.remark) data.remark = state.remark;
    if (state.state) data.state = state.state;
    if (state.status) data.status = state.status;

    // only for making slug remove this
    data.slug = state.remark;

    const response = await patchReq({
      url: `/request/respond/${params.id}`,
      data,
    });

    if (!response.error) {
      toast.success(response.message);
      // resetForm();
      setApiResult({
        ...apiResult,
        error: false,
        message: response.message,
        alert: true,
        loading: false,
      });
    } else {
      toast.error(response.message);
      setApiResult({
        ...apiResult,
        error: true,
        message: response.message,
        alert: true,
        loading: false,
      });
    }
    setTimeout(() => {
      setApiResult({ ...apiResult, alert: false });
      setState(initialState);
    }, 3000);

    setLoading(false);
  };

  return (
    <Page title="IT Request">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            IT Request Details
          </Typography>
          <Stack direction="row" gap={2}>
            {isAccepted && <div className="badge badge-gr">Approved</div>}
            {isRejected && <div className="badge badge-red">Rejected</div>}
            {isPending && <div className="badge badge-yl">Pending</div>}
          </Stack>
        </Stack>

        <Grid container spacing={1} justifyContent="center" mb={1}>
          <Grid item xs={12} md={12}>
            <div className="dashbord-card">
              <div>
                <div className="dashboard-card-title">
                  {/* Attendence Icon */}
                  <Iconify icon="mdi:user" /> <span>{requestDetails?.subject}</span>
                </div>

                {leaveState === 'accepted' ? (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center">
                    <Stack style={{ width: '100%' }}>
                      <table className="req-data-table">
                        <tr>
                          <td>Subject</td>
                          <td>
                            <Typography variant="subtitle1"> {requestDetails?.subject}</Typography>
                          </td>
                        </tr>

                        <tr>
                          <td> Reason </td>
                          <td>
                            <Typography variant="subtitle1" gutterBottom>
                              {requestDetails?.reason}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td> Remark </td>
                          <td>
                            <Typography variant="subtitle1" gutterBottom>
                              {requestDetails?.remark}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td> State </td>
                          <td>
                            <Typography variant="subtitle1" gutterBottom>
                              {leaveState}
                            </Typography>
                          </td>
                        </tr>
                      </table>
                    </Stack>
                  </Stack>
                ) : (
                  <>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center">
                      <Stack style={{ width: '100%' }}>
                        <table className="req-data-table">
                          <tr>
                            <td>Subject</td>
                            <td>
                              <Typography variant="subtitle1"> {requestDetails?.subject}</Typography>
                            </td>
                          </tr>

                          <tr>
                            <td> Reason </td>
                            <td>
                              <Typography variant="subtitle1" gutterBottom>
                                {requestDetails?.reason}
                              </Typography>
                            </td>
                          </tr>
                        </table>
                      </Stack>
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <Stack spacing={3} style={{ width: '50%', marginTop: '20px' }}>
                        <TextField
                          fullWidth
                          label="Remark"
                          name="remark"
                          onChange={handleChange}
                          value={state.remark}
                        />
                      </Stack>
                      <Stack spacing={3} style={{ width: '50%', marginTop: '20px' }}>
                        <TextField
                          fullWidth
                          select
                          label="State"
                          name="state"
                          value={status}
                          onChange={handleChange}
                          type="text"
                        >
                          <MenuItem value={'accepted'}>Accepted</MenuItem>
                          <MenuItem value={'rejected'}>Rejected</MenuItem>
                          <MenuItem value={'pending'}>Pending </MenuItem>
                        </TextField>
                      </Stack>
                    </Stack>

                    <Box textAlign="center" sx={{ marginTop: 2 }}>
                      <Button
                        className=" brand-buttons"
                        variant="contained"
                        color="primary"
                        onClick={requestUpdateSubmit}
                      >
                        Submit
                      </Button>
                    </Box>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
