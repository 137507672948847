/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Moment from 'react-moment';

import { toast } from 'react-toastify';
// @mui

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { styled } from '@mui/material/styles';
import {
  Link,
  Stack,
  TextField,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Loading from '../../components/loading';
import { getUserRole, getUserData } from '../../data/userData';
import { getReq, patchReq, postReq } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';

const LeaveResponse = ({
  submitLeave,
  handleChange,
  uRoll,
  status,
  state,
  userId,
  manager,
  handleCheckBox,
  checked,
  leaveUserId,
}) => {
  if (leaveUserId === userId && status === 'rejected') {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <div className="dashbord-card">
            <div className="dashboard-card-title">
              {/* Attendence Icon */}
              <Iconify icon="material-symbols:move-up" /> <span>Raise an Appeal</span>
            </div>
            <Stack spacing={3}>
              <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                <TextField fullWidth label="Request note" name="remark" onChange={handleChange} multiline rows={4} />
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="button"
                onClick={() => submitLeave(true)}
                variant="contained"
                color="warning"
              >
                Submit
              </LoadingButton>
            </Stack>
          </div>
        </Grid>
      </Grid>
    );
  }
  if (
    (uRoll === 'lineManager' || userId === manager) &&
    (status === 'pending' || status === 'accepted' || status === 'appeal')
  ) {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <div className="dashbord-card">
            <Stack spacing={3}>
              <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                <TextField fullWidth label="Remark" name="remark" onChange={handleChange} />
                <FormControl fullWidth size="large" type="button">
                  <InputLabel id="demo-simple-select-autowidth-label">State</InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    name="state"
                    onChange={handleChange}
                    autoWidth
                    label="State"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="accepted">Accepted</MenuItem>
                    <MenuItem value="rejected">Rejected</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                  </Select>
                </FormControl>
              </Stack>

              {state === 'accepted' && (
                <div className="tkt-section-card">
                  <FormControlLabel
                    control={<Checkbox checked={checked} onChange={handleCheckBox} />}
                    label="LOSS OF PAY"
                    name="lop"
                  />

                  <div>
                    {checked ? (
                      <InputLabel id="demo-simple-select-standard-label">
                        Single day waige will be deducted from salary
                      </InputLabel>
                    ) : null}
                  </div>
                </div>
              )}
              <LoadingButton
                // fullwidth
                size="large"
                type="button"
                onClick={() => submitLeave(false)}
                variant="contained"
              >
                Submit
              </LoadingButton>
            </Stack>
          </div>
        </Grid>
      </Grid>
    );
  }

  return null;
};

export default function LeaveSingle() {
  const { id } = useParams();

  const [leaveDetails, setLeaveDetails] = useState({});
  const [userData, setUserData] = useState({});
  const [uRoll, setRoll] = useState('employee');
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const initialState = {
    remark: '',
    state: '',
  };
  const [state, setState] = useState(initialState);

  const handleCheckBox = () => setChecked((prev) => !prev);

  const getData = async () => {
    setLoading(true);

    const res = await getReq({ url: `/leave/${id}` });
    // console.log(res);
    if (!res.error) {
      setLeaveDetails(res.data);
      setUserData(res.data?.userId);
      setChecked(res.data?.isLop);
    }
    setLoading(false);
  };

  const userRole = async () => {
    const role = await getUserRole();
    const user = await getUserData();

    setUserId(user._id);
    setRoll(role);
  };

  // console.log(leaveDetails.ticketReq);
  useEffect(() => {
    getData();
    userRole();
  }, [id]);

  const fileUpload = async (e) => {
    // console.log(e.target.files[0]);
    const file = e.target.files[0];
    const data = new FormData();
    data.append('file', file);
    data.append('type', 'leave');

    const response = await postReq({
      url: 'file/upload',
      data,
    });
    // console.log('file response', response);
    // if check
    if (!response.error) {
      toast.success('Uploaded Medical Certificate');
      const data = {
        document: response.data._id,
      };

      const res = await patchReq({ url: `/leave/respond/${id}`, data });
      if (!res.error) {
        toast.success(' Medical Certificate Submitted ');
        getData();
      }
    } else {
      toast.error(response.message);
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const submitLeave = async (isAppeal) => {
    setLoading(true);

    const data = {
      remark: state.remark,
      state: state.state,
      isLop: checked,
      document: state.document,
    };
    if (isAppeal) {
      data.state = 'appeal';
    }

    console.log(data, isAppeal);
    console.log('appeal-->', isAppeal);

    if (uRoll === 'lineManager' && leaveDetails.state === 'appeal' && state.state === 'rejected') {
      data.state = 'closed';
    }
    const res = await patchReq({ url: `/leave/respond/${id}`, data });
    if (!res.error) {
      toast.success(' Leave Submited ');
      getData();
      setState(initialState);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const dateFormator = (dString) => {
    const date = new Date(dString);
    const invalidDate = Number.isNaN(date.getTime());
    if (invalidDate) {
      return 'Invalid date';
    }
    return `${date.toLocaleTimeString()} ${date.toLocaleDateString()}`;
  };

  const leaveState = leaveDetails.state;
  const isAccepted = leaveState === 'accepted';
  const isRejected = leaveState === 'rejected';
  const isPending = leaveState === 'pending';
  const isAppeal = leaveState === 'appeal';
  const isClosed = leaveState === 'closed';
  const isCancelled = leaveState === 'cancelled';

  return (
    <Page title="Leave Form">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Leave Form
          </Typography>
          <Stack direction="row" gap={2}>
            {leaveDetails.isLop && <div className="badge badge-red">LOS OF PAY</div>}

            {isAccepted && <div className="badge badge-gr">Approved</div>}
            {isRejected && <div className="badge badge-red">Rejected</div>}
            {isPending && <div className="badge badge-yl">Pending</div>}
            {isAppeal && <div className="badge badge-yl">Request For Appeal</div>}
            {isClosed && <div className="badge badge-red"> Appeal Rejected </div>}
            {isCancelled && <div className="badge badge-red">Cancelled</div>}
          </Stack>
        </Stack>

        <Grid container spacing={1} justifyContent="center" mb={1}>
          <Grid item xs={12} md={8}>
            <div className="dashbord-card">
              <div>
                <div className="dashboard-card-title">
                  {/* Attendence Icon */}
                  <Iconify icon="mdi:user" />{' '}
                  <span>
                    {userData?.firstName} {userData?.lastName}
                  </span>
                </div>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center">
                  <Stack spacing={2}>
                    <div
                      className="user-img user-img-lg"
                      style={{
                        backgroundImage: `url(${API_URL.AWS_Bucket_url}/public/employee/${userData?.image?.ogUrl})`,
                      }}
                    />
                  </Stack>
                  <Stack spacing={2} style={{ width: '100%' }}>
                    <table className="emp-data-table">
                      <tr>
                        <td>Employee Id</td>
                        <td>
                          <Typography variant="subtitle1"> {userData?.employeeId}</Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>Designation</td>
                        <td>
                          {' '}
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.designation}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td> Email </td>
                        <td>
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.email}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td> Phone </td>
                        <td>
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.phone}
                          </Typography>
                        </td>
                      </tr>

                      <tr>
                        <td>Company</td>
                        <td>
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.company?.companyName}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>Office</td>
                        <td>
                          <Typography variant="subtitle1" gutterBottom>
                            {userData?.location?.office}
                          </Typography>
                        </td>
                      </tr>
                    </table>
                  </Stack>
                </Stack>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <div className="dashbord-card">
              <div className="dashboard-card-title">
                <Iconify icon="uil:calender" /> <span>Previous Leaves</span>
              </div>
              <div>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <table className="emp-data-table">
                    <tr>
                      <td>Last Leave</td>
                      <td>2023-01-02</td>
                    </tr>
                    <tr>
                      <td>Current Month</td>
                      <td>
                        2 Leaves <br />
                        76%
                      </td>
                    </tr>
                    <tr>
                      <td> Current Year </td>
                      <td>
                        2 Leaves
                        <br />
                        76%
                      </td>
                    </tr>
                    <tr>
                      <td> Remaining </td>
                      <td>22 Leaves Left</td>
                    </tr>
                  </table>
                </Stack>
              </div>
            </div>
          </Grid> */}
        </Grid>

        <Grid container spacing={1} mb={1} justifyContent="center">
          <Grid item xs={12} md={8}>
            <div className="dashbord-card">
              <div className="dashboard-card-title">
                {/* Attendence Icon */}
                <Iconify icon="clarity:details-line" /> <span>Details</span>
              </div>
              <div>
                <div>
                  <div>
                    <Stack spacing={3}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Stack spacing={2} style={{ width: '100%' }}>
                          <table className="emp-data-table">
                            <tr>
                              <td>Leave Type</td>
                              <td>
                                <h4> {leaveDetails.type}</h4>
                              </td>
                            </tr>
                            <tr>
                              <td>Reason </td>
                              <td>
                                <h4>{leaveDetails.reason}</h4>
                              </td>
                            </tr>
                            <tr>
                              <td>Response from manager </td>
                              <td>
                                <h4>{leaveDetails.remark}</h4>
                              </td>
                            </tr>
                            <tr>
                              <td> Number of days </td>
                              <td>
                                <h4> {leaveDetails.noOfDays}</h4>
                              </td>
                            </tr>
                            <tr>
                              <td> Date</td>
                              <td>
                                <h4>
                                  {leaveDetails.noOfDays === 1 ? (
                                    ''
                                  ) : (
                                    <>
                                      <Moment format="YYYY/MM/DD">{leaveDetails.fromDate}</Moment> -
                                    </>
                                  )}
                                  <Moment format="YYYY/MM/DD">{leaveDetails.endDate}</Moment>{' '}
                                  <span className="small-info-text">YYYY/MM/DD</span>
                                </h4>
                              </td>
                            </tr>

                            <tr>
                              <td>Ticket Required </td>
                              <td>
                                <h4> {leaveDetails.ticketReq ? 'Yes' : 'No'} </h4>
                              </td>
                            </tr>
                            <tr>
                              <td> Medical Certificate</td>
                              <td>
                                <h4>
                                  {leaveDetails.document?.url ? (
                                    <a
                                      href={`${API_URL.img_url}/api/uploads/files/${leaveDetails.document?.url}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Download Medical Certificate Here
                                    </a>
                                  ) : (
                                    <div>
                                      No Medical Certificate
                                      {userData?._id === userId && (
                                        <Button
                                          onChange={fileUpload}
                                          color="inherit"
                                          component="label"
                                          className="sm-file-upload-btn"
                                        >
                                          Submit Medical Certificate
                                          <input hidden type="file" />
                                        </Button>
                                      )}
                                    </div>
                                  )}
                                </h4>
                              </td>
                            </tr>
                          </table>
                        </Stack>
                      </Stack>
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <div className="dashbord-card">
              <div className="dashboard-card-title">
                <Iconify icon="wi:stars" /> <span>Insights</span>
              </div>
              <div>
                <div>
                  <ul className="insight-ul">
                    <li className="insight-li">2 Other employees leave on these days</li>
                    <li className="insight-li">Average Working Time - 8.3 Hr Daily</li>
                    <li className="insight-li">Average Check-in Time - 9.01 AM</li>
                    <li className="insight-li">Average Check-out Time - 6.11 AM</li>
                    <li className="insight-li">Late Check In - 4 Days</li>
                  </ul>
                </div>
              </div>
            </div>
          </Grid> */}
        </Grid>

        {leaveDetails?.type === 'Annual Leave' && (
          <Grid container spacing={0} mb={1}>
            <Grid item xs={12} md={12}>
              <div className="dashbord-card ">
                <div className="dashboard-card-title ">
                  {/* Attendence Icon */}
                  <Iconify icon="material-symbols:fact-check-outline-sharp" /> <span>Leave Requrement</span>
                </div>
                <div className="">
                  <Stack spacing={2} justifyContent="space-around">
                    {leaveDetails?.statusCheck?.map((item, key) => (
                      <div
                        key={key}
                        className={item.error ? 'status-check-item bg-red-gr' : 'status-check-item bg-green-gr'}
                      >
                        {item.error ? (
                          <Iconify icon="carbon:warning-alt" />
                        ) : (
                          <Iconify icon="material-symbols:check-box-outline" />
                        )}

                        <div>{item?.title}</div>
                      </div>
                    ))}
                  </Stack>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={0} mb={1}>
          <Grid item xs={12} md={12}>
            <div className="dashbord-card ">
              <div className="dashboard-card-title ">
                {/* Attendence Icon */}
                <Iconify icon="material-symbols:history" /> <span>Status</span>
              </div>
              <div className="timeline-wrap hr-overflow-bar">
                <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} justifyContent="space-around">
                  {leaveDetails?.statusHistory?.map((item, key) => (
                    <div key={key} className="timeline-item">
                      {item?.title}
                      <p>{dateFormator(item?.time)}</p>
                    </div>
                  ))}
                </Stack>
              </div>
            </div>
          </Grid>
        </Grid>
        <LeaveResponse
          submitLeave={submitLeave}
          handleChange={handleChange}
          uRoll={uRoll}
          manager={leaveDetails.manager}
          leaveUserId={leaveDetails?.userId?._id}
          userId={userId}
          status={leaveDetails.state}
          handleCheckBox={handleCheckBox}
          checked={checked}
          state={state.state}
        />
      </Container>
    </Page>
  );
}
