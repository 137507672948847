import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
// @mui
import { useReactToPrint } from 'react-to-print';
import { styled } from '@mui/material/styles';
import {
  Link,
  Stack,
  TextField,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Box,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Fade,
  Modal,
  Backdrop,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Paper from '@mui/material/Paper';
// components
import Page from '../../components/Page';
import Loading from '../../components/loading'
import { getReq } from '../../data/ApiReq';
import { getUserData } from '../../data/userData';
import API_URL from '../../data/ApiUrl';

const writtenNumber = require('written-number');

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Payroll() {
  const [imgAddress, setImgAddress] = useState('https://nanopixelmea.com/images/logo.svg');
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOpen = (type) => {
    setOpen(true);
    setModalType(type);
  };
  const handleClose = () => setOpen(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [salaryDetails, setSalaryDetails] = useState({});
  const [LOPDetails, setLOPDetails] = useState({});

  const getData = async () => {
    setLoading(true);

    const userData = await getUserData();

    // console.log(userData._id);
    // console.log(userData.firstName);
    const res = await getReq({ url: `/user/getsingleuser?id=${userData._id}` });

    if (!res.error) {
      setSalaryDetails(res.data);
      const imgAddress = `${API_URL.img_url}/api/uploads/images/${res.data?.company?.logo?.ogUrl}`;
      setImgAddress(imgAddress);
    }
    console.log(res);

    const res2 = await getReq({ url: `/attendance/lop/${userData._id}/11/2022` });
    // console.log(res2);

    if (!res2.error) {
      setLOPDetails(res2.data);
    }

    setLoading(false);

  };

  useEffect(() => {
    getData();
  }, []);

  const date = new Date(new Date(LOPDetails.date).setDate(0));
  function numberWithCommas(x) {
    if (!x) return 0;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const unPaidLeaves = Math.round((LOPDetails?.lopDays?.length * LOPDetails.perDaySalary + Number.EPSILON) * 100) / 100;
  const lateLeaves = Math.round((LOPDetails?.lateDayOff * LOPDetails.perDaySalary + Number.EPSILON) * 100) / 100;

  const ModalSec = () => {
    if (modalType === 'lop') {
      return (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography id="transition-modal-title" variant="h6" component="h2">
                Unpaid Leaves
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                {LOPDetails?.lopDays?.join()} of {date.toLocaleString('default', { month: 'long' })}{' '}
              </Typography>
            </Box>
          </Fade>
        </Modal>
      );
    }
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Late Leaves
            </Typography>
            {LOPDetails?.lateDays?.map((item, key) => (
              <Typography id="transition-modal-description" sx={{ mt: 2 }} key={key}>
                {item}
              </Typography>
            ))}
          </Box>
        </Fade>
      </Modal>
    );
  };

  if(loading){
    return(
      <Page title="Payroll">
        <Loading/>
      </Page>
    )
  }

  return (
    <Page title="Payroll">
      <Container>
        <ModalSec />

        <componentToPrint ref={componentRef}>
          <Grid container spacing={0} alignItems="center" justifyContent="center" className="to-print">
            <Grid item xs={12} md={10}>
              <Card>
                <CardContent>
                  <div>
                    <div>
                      <Stack spacing={3}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                          <div
                            className="img-logo"
                            style={{
                              backgroundImage: `url(${imgAddress})`,
                            }}
                          />
                          <div className="company-address">
                            <h3> {salaryDetails?.company?.companyName} </h3>
                            <p> {salaryDetails?.company?.address} </p> <br />
                          </div>
                        </Stack>
                        <div className="hr-line" />
                        <Typography variant="h4" gutterBottom>
                          Payslip for the month of {date.toLocaleString('default', { month: 'long', year: 'numeric' })}
                        </Typography>
                        <Grid container spacing={1} justifyContent="center">
                          <Grid item xs={7}>
                            <Stack>
                              <Typography variant="h6" className="text-blue" gutterBottom>
                                Employee pay summery
                              </Typography>
                              <table>
                                <tr>
                                  <td>Employee Name</td>
                                  <td>
                                    <Typography variant="subtitle1">
                                      {salaryDetails.firstName} {salaryDetails.lastName}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Employee No</td>
                                  <td>
                                    <Typography variant="subtitle1">{salaryDetails.employeeId}</Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Designation </td>
                                  <td>
                                    <Typography variant="subtitle1">{salaryDetails.designation}</Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Date of Joining </td>
                                  <td>
                                    <Typography variant="subtitle1">
                                      {new Date(salaryDetails.doj).toLocaleString('default', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                      })}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td> Pay Period</td>
                                  <td>
                                    <Typography variant="subtitle1">
                                      {date.toLocaleString('default', { month: 'long', year: 'numeric' })}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Pay Date </td>
                                  <td>
                                    <Typography variant="subtitle1">
                                      {new Date(new Date().setDate(1)).toLocaleString('default', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                      })}
                                    </Typography>
                                  </td>
                                </tr>
                              </table>
                            </Stack>
                          </Grid>
                          <Grid item xs={5}>
                            <Stack>
                              <Typography variant="h6" className="text-blue" gutterBottom>
                                Employee Bank Details
                              </Typography>
                              <table>
                                <tr>
                                  <td>Bank Name</td>
                                  <td>
                                    <Typography variant="subtitle1">{salaryDetails.bankName}</Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Account No</td>
                                  <td>
                                    <Typography variant="subtitle1">{salaryDetails.bankAccNo}</Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>IBAN No </td>
                                  <td>
                                    <Typography variant="subtitle1">{salaryDetails.ibanNo}</Typography>
                                  </td>
                                </tr>
                              </table>
                            </Stack>
                          </Grid>
                        </Grid>
                        <div className="hr-line" />
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <TableContainer component={Paper}>
                              <Table aria-label="simple table" style={{ height: '460px' }}>
                                <TableHead className="bg-blue-gr">
                                  <TableRow>
                                    <TableCell>
                                      <Typography className="text-blue" variant="subtitle1">
                                        Earnings
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography className="text-blue" variant="subtitle1">
                                        Amount
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">Basic</Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">
                                        AED {numberWithCommas(salaryDetails.basic)}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">House Rent Allowance</Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">
                                        AED {numberWithCommas(salaryDetails.hra)}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">Telephone Allowance</Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">
                                        AED {numberWithCommas(salaryDetails.tellA)}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2"> Travel Allowance</Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">
                                        AED {numberWithCommas(salaryDetails.travA)}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">Transportation Allowance</Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">
                                        AED {numberWithCommas(salaryDetails.transportA)}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2"> Other Allowance</Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">
                                        AED {numberWithCommas(salaryDetails.otherA)}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>

                                  <TableRow className="bg-green">
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle1">Gross Earnings</Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle1">
                                        AED {numberWithCommas(salaryDetails.gross)}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                          <Grid item xs={6}>
                            <TableContainer component={Paper}>
                              <Table aria-label="simple table" style={{ height: '460px' }}>
                                <TableHead className="bg-blue-gr">
                                  <TableRow>
                                    <TableCell>
                                      <Typography className="text-blue" variant="subtitle1">
                                        Deductions
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography className="text-blue" variant="subtitle1">
                                        Amount (-)
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">Unpaid Leave </Typography>
                                      <Typography variant="caption">
                                        <button
                                          type="button"
                                          onClick={() => handleOpen('lop')}
                                          className="unstyle-button"
                                        >
                                          {LOPDetails?.lopDays?.length} Days{' '}
                                        </button>
                                      </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">AED {unPaidLeaves}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">Late arrival </Typography>

                                      <Typography variant="caption">
                                        <button
                                          type="button"
                                          onClick={() => handleOpen('late')}
                                          className="unstyle-button"
                                        >
                                          {LOPDetails?.lateDays?.length} Days
                                        </button>
                                      </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle2">AED {lateLeaves}</Typography>
                                    </TableCell>
                                  </TableRow>

                                  <TableRow style={{ height: '190px' }}> </TableRow>
                                  <TableRow className="bg-red">
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle1">Total Deductions </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Typography variant="subtitle1">AED {LOPDetails.deduction}</Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                        <Table sx={{ minWidth: 650, marginTop: '20px' }} aria-label="simple table">
                          <TableHead className="bg-blue-gr">
                            <TableRow>
                              <TableCell>
                                <Typography className="text-blue" variant="subtitle1">
                                  NET Pay (Gross earnings - Total deductions)
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography className="text-blue" variant="subtitle1">
                                  AED {numberWithCommas(LOPDetails.totalSalary)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>

                        <div className="total-salary">
                          NET Salary AED {numberWithCommas(LOPDetails?.totalSalary)} /-
                          <p className="salary-word">{writtenNumber(LOPDetails?.totalSalary - 200)} Dirham </p>
                          <Typography variant="subtitle2" gutterBottom>
                            Paid Days : {LOPDetails.totalDays} | LOP Days : {LOPDetails.totalOffDays}
                          </Typography>
                        </div>
                      </Stack>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </componentToPrint>
      </Container>
      <div>
        <Box mr={15} mt={3} display="flex" justifyContent="flex-end" alignItems="flex-end">
          <Button size="large" type="button" onClick={handlePrint} variant="contained">
            Print{' '}
          </Button>
        </Box>
      </div>
    </Page>
  );
}
