import axios from './axios';
import ApiUrl from './ApiUrl';
import { getUserToken } from './userData';

export const getReq = async ({ url }) => {
  try {
    if (!url) return 0;

    const token = getUserToken();
    const config = {
      headers: {
        'x-access-token': token,
      },
    };
    const response = await axios.get(url, config).catch((error) => {
      return {
        data: null,
        error: true,
      };
    });

    // console.log(response);
    if (response.data) {
      return response.data;
    }

    return {
      error: true,
      data: null,
      message: 'Something went wrong',
      statusCode: '500',
    };
  } catch (error) {
    return {
      error: true,
      data: null,
      message: 'Something went wrong',
      statusCode: '500',
    };
  }
};

export const postReq = async ({ url, data }) => {
  try {
    console.log(data);
    if (!url) return 0;

    const token = getUserToken();
    const config = {
      headers: {
        'x-access-token': token,
      },
    };
    const response = await axios.post(url, data, config).catch((error) => {
      return {
        data: null,
        error: true,
      };
    });

    // console.log('====================================>',response.data);
    if (response.data) {
      return response.data;
    }

    return {
      error: true,
      data: null,
      message: 'Something went wrong',
      statusCode: '500',
    };
  } catch (error) {
    return {
      error: true,
      data: null,
      message: 'Something went wrong',
      statusCode: '500',
    };
  }
};

export const patchReq = async ({ url, data }) => {
  try {
    console.log(data);
    if (!url) return 0;

    const token = getUserToken();
    const config = {
      headers: {
        'x-access-token': token,
      },
    };
    const response = await axios.patch(url, data, config).catch((error) => {
      return {
        data: null,
        error: true,
      };
    });

    // console.log(response);
    if (response.data) {
      return response.data;
    }

    return {
      error: true,
      data: null,
      message: 'Something went wrong',
      statusCode: '500',
    };
  } catch (error) {
    return {
      error: true,
      data: null,
      message: 'Something went wrong',
      statusCode: '500',
    };
  }
};

export const deleteRequest = async ({ url }) => {
  const token = getUserToken();
  const config = {
    headers: {
      'x-access-token': token,
    },
  };
  if (token === null) {
    return {
      auth: false,
      msg: 'No Token Found',
      error: true,
    };
  }
  const response = await axios.delete(`${ApiUrl.base_url}/${url}`, config).catch((error) => {
    console.log(error);
    return {
      error: true,
      data: error,
    };
  });

  try {
    if (response) {
      return {
        error: false,
        data: response.data,
      };
    }
    return {
      error: true,
      data: null,
    };
  } catch (error) {
    return {
      error: true,
      data: null,
    };
  }
};
