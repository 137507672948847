import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import {
  Link,
  Stack,
  TextField,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  List,
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Loading from '../../components/loading';

import { getReq, patchReq } from '../../data/ApiReq';
import API_URL from '../../data/ApiUrl';
import { getUserData } from '../../data/userData';

export default function EmployeeFullDetails() {
  const { userId } = useParams();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});

  const getData = async () => {
    setLoading(true);

    const res = await getReq({ url: `/user/getsingleuser?id=${userId}` });

    if (!res.error) {
      setUserData(res.data);
    }
    setLoading(false);
  };
  // console.log(leaveDetails.ticketReq);
  useEffect(() => {
    getData();
  }, []);

  const getRole = getUserData();
  const role = getRole?.role;

  /* 'firstName',
      'lastName',
        'employeeId',
      'email',
      'phone',
      'designation',
        'company.companyName',
        'location.office',
          */
  const basicDetails = [
    {
      label: 'First Name',
      value: userData?.firstName,
    },
    {
      label: 'Last Name',
      value: userData?.lastName,
    },
    {
      label: 'Employee ID',
      value: userData?.employeeId,
    },
    {
      label: 'Email',
      value: userData?.email,
    },
    {
      label: 'Phone',
      value: userData?.phone,
    },
    // {
    //   label: 'Designation',
    //   value: userData?.designation,
    // },
    {
      label: 'Company',
      value: userData?.company?.companyName,
    },
    {
      label: 'Location',
      value: userData?.location?.office,
    },
    {
      label: 'Manager',
      value: `${userData?.lineManager?.firstName} ${userData?.lineManager?.lastName}`,
    },
    {
      label: 'Department',
      value: userData?.department?.title,
    },
    {
      label: 'Role',
      value: userData?.role,
    },
    {
      label: 'Access ID',
      value: userData?.accessId,
    },
    {
      label: 'UAE Address',
      value: userData?.uaeAddress,
    },
  ];

  const otherDetails = [
    {
      label: 'Date Of Joining',
      value: <Moment format="YYYY/MM/DD">{userData?.doj}</Moment>,
    },
    {
      label: 'Designation',
      value: userData?.designation,
    },
    {
      label: 'Functions (Job Description)',
      value: userData?.functions,
    },
    {
      label: 'Office Timings',
      value: userData?.officeTiming?.title,
    },
    {
      label: 'Nationality',
      value: userData?.nationality,
    },
    {
      label: 'Educational Attainment',
      value: userData?.educationalAttainment,
    },
    {
      label: 'Languages Spoken',
      value: userData?.languagesSpoken,
    },
    {
      label: 'Landline Number',
      value: userData?.landlineNumber,
    },
    {
      label: 'Landline (Home Country)',
      value: userData?.landlineHome,
    },
    {
      label: 'Mobile (Home Country)',
      value: userData?.mobileHome,
    },
  ];

  const personalDetails = [
    {
      label: 'Date Of Birth',
      value: <Moment format="YYYY/MM/DD">{userData?.dob}</Moment>,
    },
    {
      label: 'Gender',
      value: userData?.gender,
    },
    {
      label: 'Maritial Status',
      value: userData?.matStatus,
    },
    {
      label: 'Personal Email ID',
      value: userData?.persEmail,
    },
    {
      label: 'Address (Home Country)',
      value: userData?.homeCountryAddress,
    },
    {
      label: 'Place Of Birth',
      value: userData?.placeOfBirth,
    },
    {
      label: 'Religion/Belief',
      value: userData?.religion,
    },
    {
      label: 'Father`s Name',
      value: userData?.father,
    },
    {
      label: 'Mother`s Name',
      value: userData?.mother,
    },
    {
      label: 'Name Of Spouse',
      value: userData?.spouse,
    },
    {
      label: 'No Of Children',
      value: userData?.noOfChildren,
    },
  ];

  const documentDetails = [
    {
      label: 'Passport No',
      value: userData?.passportNo,
    },
    {
      label: 'Passport Validity',
      value: userData?.passportValidity ? <Moment format="YYYY/MM/DD">{userData?.passportValidity}</Moment> : 'N/A',
    },
    {
      label: 'Emirates ID No.',
      value: userData?.EIdNo,
    },
    {
      label: 'Emirates ID Validity',
      value: userData?.EIdValidity ? <Moment format="YYYY/MM/DD">{userData?.EIdValidity}</Moment> : 'N/A',
    },
    {
      label: 'Driving Licence Validity',
      value: userData?.drivingLicenceValidity ? (
        <Moment format="YYYY/MM/DD">{userData?.drivingLicenceValidity}</Moment>
      ) : (
        'N/A'
      ),
    },
    {
      label: 'Bank Name',
      value: userData?.bankName,
    },
    {
      label: 'Bank Account No.',
      value: userData?.bankAccNo,
    },
    {
      label: 'IBAN No.',
      value: userData?.ibanNo,
    },
    {
      label: 'Medical Insurance',
      value: userData?.medical,
    },
    {
      label: 'Visa UID',
      value: userData?.visaUID,
    },
    {
      label: 'Visa Sponsor (RN/Self)',
      value: userData?.visaSponsor,
    },
    {
      label: 'Copy Provide',
      value: userData?.copyProvide,
    },
  ];

  const emergencyUAEDetails = [
    {
      label: 'Contact Person Name',
      value: userData?.contactNameUae,
    },
    {
      label: 'Relationship',
      value: userData?.relationShipUae,
    },
    {
      label: 'Phone No.',
      value: userData?.contactUaeNo,
    },
    {
      label: 'Address',
      value: userData?.contactUaeAddress,
    },
  ];

  const emergencyHomeDetails = [
    {
      label: 'Contact Person Name',
      value: userData?.contactNameHome,
    },
    {
      label: 'Relationship',
      value: userData?.relationShipHome,
    },
    {
      label: 'Phone No.',
      value: userData?.contactHomeNo,
    },
    {
      label: 'Address',
      value: userData?.contactHomeAddress,
    },
  ];

  const commonDetails = [
    {
      label: 'Do you consider yourself to have a disability?',
      value: userData?.disability,
    },
    {
      label: 'Are you on any medication or medical supervision?',
      value: userData?.medicalSupervision,
    },
    {
      label: 'Offer Letter Date',
      value: <Moment format="YYYY/MM/DD">{userData?.offerLetterDate}</Moment>,
    },
  ];

  const EmpImgSecOld = () => (
    <Stack direction="column" alignItems="center" justifyContent="space-between" mb={3} gap={0}>
      <img
        src={`${API_URL.AWS_Bucket_url}/public/employee/${userData?.image?.ogUrl}`}
        alt="user"
        className="user-img-round"
      />
      <Typography variant="h3" gutterBottom marginTop={3}>
        {userData?.firstName} {userData?.lastName}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {userData?.designation}
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="center" mt={1} gap={5}>
        <Stack direction="row" alignItems={'center'} gap={1}>
          <Iconify icon={'mdi:email'} />
          <p>{userData?.email}</p>
        </Stack>

        <Stack direction="row" alignItems={'center'} gap={1}>
          <Iconify icon={'mdi:phone'} />
          <p>{userData?.phone}</p>
        </Stack>
        <Stack direction="row" alignItems={'center'} gap={1}>
          <Iconify icon={'mdi:company'} />
          <p>{userData?.company?.companyName}</p>
        </Stack>
      </Stack>
    </Stack>
  );

  const EmpImgSec = () => (
    <Stack
      direction={{
        xs: 'column',
        md: 'row',
      }}
      alignItems="center"
      justifyContent="center"
      mb={3}
      gap={5}
    >
      <img
        src={`${API_URL.AWS_Bucket_url}/public/employee/${userData?.image?.ogUrl}`}
        alt="user"
        className="user-img-round"
      />
      <Stack direction="column" alignItems="start" justifyContent="space-between" mb={3} gap={0}>
        <Typography variant="h3" gutterBottom marginTop={3}>
          {userData?.firstName} {userData?.lastName}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {userData?.designation}
        </Typography>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <Stack direction="row" alignItems={'center'} gap={1}>
            <Iconify icon={'mdi:email'} />
            <p>{userData?.email}</p>
          </Stack>

          <Stack direction="row" alignItems={'center'} gap={1}>
            <Iconify icon={'mdi:phone'} />
            <p>{userData?.phone}</p>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems={'center'} gap={1} mt={1}>
          <Iconify icon={'mdi:company'} />
          <p>{userData?.company?.companyName}</p>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <>
      <Page title={`${userData?.firstName} - Full Details`}>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="end" mb={1}>
            {role === 'admin' || role === 'hr' || role === 'lineManager' ? (
            <>
             <Button
              variant="text"
              component={RouterLink}
              to={`/dashboard/employees/view/${userId}`}
              startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
              color={'primary'}
            >
              Employee Overview
            </Button>
            </>
          ) : (
            <>
            <Button
              variant="text"
              component={RouterLink}
              to={`/dashboard/profile/view/${userId}`}
              startIcon={<Iconify icon={'material-symbols:arrow-back-rounded'} />}
              color={'primary'}
            >
              Employee Overview
            </Button></>
          )}

          </Stack>
          <EmpImgSec />

          <div className="dashbord-card">
            <table className="user-table">
              <tbody>
                <h2>Basic Details</h2>
                {basicDetails.map((item, index) => (
                  <tr key={index}>
                    <td>{item.label}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
                <h2>Other Details</h2>
                {otherDetails.map((item, index) => (
                  <tr key={index}>
                    <td>{item.label}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
                <h2>Personal Details</h2>
                {personalDetails.map((item, index) => (
                  <tr key={index}>
                    <td>{item.label}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
                <h2>Document Details</h2>
                {documentDetails.map((item, index) => (
                  <tr key={index}>
                    <td>{item.label}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
                <h2>Emergency Contact - UAE</h2>
                {emergencyUAEDetails.map((item, index) => (
                  <tr key={index}>
                    <td>{item.label}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
                <h2>Emergency Contact - Home Country</h2>
                {emergencyHomeDetails.map((item, index) => (
                  <tr key={index}>
                    <td>{item.label}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
                {commonDetails.map((item, index) => (
                  <tr key={index}>
                    <td>{item.label}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Container>
      </Page>
    </>
  );
}
