import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DateContext } from '../../context/dateContext';
import '../../assets/vCalender.css';

export default function SmallCalender({ events, calDate, onDateClick, onMonthChange, defaultDate }) {
  const { date, setDate } = useContext(DateContext);

  console.log('events in small calender', events);
  // const sDate = new Date(date.year, date.month, date.day);

  const dateNow = new Date();
  const [currentDate, SetcurrentDate] = useState(defaultDate || dateNow);
  const [selcetedDate, SelectDate] = useState({
    day: currentDate.getDate(),
    month: currentDate.getMonth(),
    year: currentDate.getFullYear(),
  });
  const [clickedDate, setClickedDate] = useState({
    day: currentDate.getDate(),
    month: currentDate.getMonth(),
    year: currentDate.getFullYear(),
    isClicked: false,
  });
  const [viewType, setViewType] = useState('cal');
  const [eventObj, setEventObj] = useState({
    yellow: [],
    red: [],
    blue: [],
  });

  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const dateString = currentDate.toDateString();
  const totalDaysInThisMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const startOfTheMonth = new Date(currentYear, currentMonth).getDay();

  // console.log(currentDay, currentMonth, currentYear, totalDaysInThisMonth, startOfTheMonth);

  // Events

  const manageEvents = (eventArr) => {
    const yellow = [];
    const red = [];
    const blue = [];
    for (let i = 0; i < eventArr?.length; i += 1) {
      const eventsdates = eventArr[i]?.date?.split('/');
      if (Number(eventsdates[0]) === selcetedDate.year && Number(eventsdates[1]) === selcetedDate.month + 1) {
        if (eventArr[i]?.theame === 'yellow') {
          yellow.push(Number(eventsdates[2]));
        } else if (eventArr[i]?.theame === 'red') {
          red.push(Number(eventsdates[2]));
        } else if (eventArr[i]?.theame === 'blue') {
          blue.push(Number(eventsdates[2]));
        }
      }
    }

    setEventObj({
      yellow,
      red,
      blue,
    });
    // console.log(eventObj);
  };

  useEffect(() => {
    if (events?.length > 0) {
      manageEvents(events);
    }
    // console.log('___$!#@$#@_$#');
  }, []);

  const handleDayBtnClick = (day) => {
    const dateData = {
      day,
      month: selcetedDate.month,
      year: selcetedDate.year,
      isClicked: true,
    };
    // setDate(dateData);
    onDateClick(dateData);
    setClickedDate(dateData);
  };

  const DayButton = ({ day, isEvent, color, k }) => {
    let classNames = 'cal-day-btn ';

    if (day === clickedDate.day && selcetedDate.month === clickedDate.month && selcetedDate.year === clickedDate.year) {
      classNames = 'cal-day-btn cal-day-green';
    }

    if (
      day === dateNow.getDate() &&
      selcetedDate.month === dateNow.getMonth() &&
      selcetedDate.year === dateNow.getFullYear()
    ) {
      classNames += ' cal-day-now';
    } else if (color === 'yellow') {
      classNames += ' cal-day-yl';
    } else if (color === 'red') {
      classNames += ' cal-day-red';
    } else if (color === 'blue') {
      classNames += ' cal-day-blue';
    }
    if (k % 7 === 0) classNames = 'cal-day-btn cal-day-sun';

    if (day) {
      return (
        <div>
          <button type="button" className={classNames} onClick={() => handleDayBtnClick(day)}>
            {day}
          </button>
        </div>
      );
    }
    return <div key={k} />;
  };

  const getMonthName = (no) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthNames[no];
  };

  const toggleCalView = () => {
    if (viewType === 'cal') {
      setViewType('month');
    } else if (viewType === 'month') {
      setViewType('year');
    } else {
      SelectDate({
        day: dateNow.getDate(),
        month: dateNow.getMonth(),
        year: dateNow.getFullYear(),
      });
      setViewType('cal');
      SetcurrentDate(new Date());
    }
    // if (events?.length > 0) {
    // manageEvents(events);
    // }
  };

  let buttonTitle = `${selcetedDate.day} ${getMonthName(selcetedDate.month)} ${selcetedDate.year}`;

  if (viewType === 'cal') {
    buttonTitle = `${selcetedDate.day} ${getMonthName(selcetedDate.month)} ${selcetedDate.year}`;
  } else if (viewType === 'month') {
    buttonTitle = `${selcetedDate.year}`;
  } else {
    buttonTitle = `Go back`;
  }

  const handleMonthChange = (month) => {
    SelectDate({
      ...selcetedDate,
      month,
    });
    manageEvents(events);

    setViewType('cal');
    SetcurrentDate(new Date(selcetedDate.year, month, 1));
    onMonthChange({ month: month + 1, year: selcetedDate.year });
  };

  const handleYearChange = (year) => {
    SelectDate({
      ...selcetedDate,
      year,
    });
    setViewType('month');
    SetcurrentDate(new Date(year, 0, 1));
  };

  const CalDaysView = () => {
    const daysBtn = [];
    // const mnthDaysArr = Array(totalDaysInThisMonth).fill(1);
    let d = 1;
    for (let i = 0; i < 42; i += 1) {
      if (i < startOfTheMonth || d > totalDaysInThisMonth) {
        daysBtn.push('');
      } else {
        daysBtn.push(d);
        d += 1;
      }
    }
    return (
      <>
        <div className="cal-day-bar">
          <div className="cal-dn">S</div>
          <div className="cal-dn">M</div>
          <div className="cal-dn">T</div>
          <div className="cal-dn">W</div>
          <div className="cal-dn">T</div>
          <div className="cal-dn">F</div>
          <div className="cal-dn">S</div>
        </div>
        <div className="cal-days">
          {daysBtn.map((item, key) => {
            // console.log('item -->', item);
            let eventColor;
            if (eventObj.yellow.includes(item)) {
              eventColor = 'yellow';
            } else if (eventObj.red.includes(item)) {
              eventColor = 'red';
            } else if (eventObj.blue.includes(item)) {
              eventColor = 'blue';
            } else {
              eventColor = '';
            }
            return <DayButton day={item} key={key} k={key} color={eventColor} />;
          })}
        </div>
      </>
    );
  };

  const MonthView = () => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (
      <div className="cal-month-wrap">
        {monthNames.map((m, key) => (
          <button type="button" key={key} className="cal-month-btns" onClick={() => handleMonthChange(key)}>
            {m}
          </button>
        ))}
      </div>
    );
  };

  const YearView = () => {
    const yearArr = Array(20).fill(1);
    return (
      <div className="cal-year-wrap">
        {yearArr.map((m, key) => (
          <button type="button" key={key} className="cal-month-btns" onClick={() => handleYearChange(2020 + key)}>
            {2020 + key}
          </button>
        ))}
      </div>
    );
  };

  const CalMainView = () => {
    if (viewType === 'cal') {
      return <CalDaysView />;
    }
    if (viewType === 'month') {
      return <MonthView />;
    }

    return <YearView />;
  };

  return (
    <>
      <div className="cal-wrap">
        <div className="cal-head">
          <button type="button" onClick={toggleCalView} className="cal-title-btn">
            {buttonTitle}
          </button>
        </div>
        <CalMainView />
      </div>
    </>
  );
}

SmallCalender.propTypes = {
  events: PropTypes.array,
  onDateClick: PropTypes.func,
  onMonthChange: PropTypes.func,
  defaultDate: PropTypes.any,
};
SmallCalender.defaultProps = {
  onDateClick: () => null,
  onMonthChange: ({ month, year }) => null,
  defaultDate: new Date(),
};
