import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { formatDate } from '@fullcalendar/react';

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableHead,
  Link,
  TableCell,
  MenuItem,
  Container,
  Typography,
  TextField,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getReq } from '../../data/ApiReq';
import 'moment-timezone';
import Loading from '../../components/loading';

export default function Requests() {
  const initialState = {
    page: 1,
    limit: 20,
    totalCount: 0,
    totalPages: 0,
    hasNextPage: false,
    searchText: '',
    sort: '',
    qEdit: false,
  };

  // const [assetList, setAssetList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(initialState);
  const [filterState, setFilterState] = useState({});
  const [attendanceData, setAttendanceData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const [selectedValue, setSelectedValue] = useState('all');
  const [filteredData, setFilteredData] = useState([]);

  const getData = async (pg, lm, isSearch, filter) => {
    // setLoading(true);

    const p = pg || state.page;
    const l = lm || state.limit;

    let query = `page=${p}&limit=${l}`;
    if (isSearch) {
      query = `page=${p}&limit=${l}&searchText=${isSearch}`;
      if (filter) {
        query += `&${filter}`;
      }
      setFilterState({ ...filterState, searchText: isSearch });
    }
    if (filter) {
      query = `page=${p}&limit=${l}&${filter}`;
      if (filterState.searchText) {
        query += `&searchText=${filterState.searchText}`;
      }
    }

    const response = await getReq({ url: `/attendance/list?${query}` });
    if (!response.error) {
      setAttendanceData(response.data);
      setState({
        ...state,
        page: response.pagination.page,
        limit: response.pagination.limit,
        totalCount: response.pagination.totalCount,
        totalPages: response.pagination.totalPages,
        hasNextPage: response.pagination.hasNextPage,
        qEdit: false,
      });
      // setLoading(false);
    }
  };

  const handleDropdownChange = (e) => {
    setSelectedValue(e.target.value);

    if (e.target.value === 'all') {
      setFilteredData(attendanceData);
    } else {
      const filtered = attendanceData.filter((item) => item.approvedByManager === e.target.value);
      setFilteredData(filtered);
    }
  };

  const changePage = (event, value) => {
    setState({
      ...state,
      page: value,
    });
    getData(value, state.limit, filterState.searchText, filterState.query);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (selectedValue === 'all') {
      setFilteredData(attendanceData);
    } 
  }, [selectedValue, attendanceData]);


  if (loading) {
    return (
      <Page title="Request">
        <Loading />
      </Page>
    );
  }

  const FilterSec = () => {
    if (showFilter) return null;
    return (
      <>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
            select
            label={'Status'}
            style={{ width: '200px' }}
            onChange={handleDropdownChange}
            name="approvedByManager"
            value={selectedValue}
          >
            <MenuItem value="all">
              <em>All</em>
            </MenuItem>

            <MenuItem value="accepted">Accepted</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
          </TextField>
        </Stack>
      </>
    );
  };

  return (
    <Page title="Requests">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
          <Pagination count={state?.totalPages} color="primary" onChange={changePage} />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Requests
          </Typography>
          <FilterSec />
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.</TableCell>
                    <TableCell>Employee</TableCell>
                    <TableCell>Reason</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row, index) => (
                    <TableRow hover key={index}>
                      <TableCell>
                        {' '}
                        <Link
                          style={{ textDecoration: 'none' }}
                          component={RouterLink}
                          to={`/dashboard/attendance/singlelateattendance/${row._id}`}
                        >
                          {state.limit * state.page + index + 1 - state.limit}
                        </Link>
                      </TableCell>

                      <Link
                        style={{ textDecoration: 'none' }}
                        component={RouterLink}
                        to={`/dashboard/attendance/singlelateattendance/${row._id}`}
                      >
                        {' '}
                        <TableCell>
                          {row?.userId?.firstName} {row?.userId?.lastName}
                        </TableCell>
                      </Link>

                      <TableCell>{row.reason}</TableCell>

                      <TableCell>
                        <Button
                          component={RouterLink}
                          to={`/dashboard/attendance/singlelateattendance/${row._id}`}
                          className={`text-${row?.approvedByManager} text-pile`}
                        >
                          {row.approvedByManager.toUpperCase()}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
