/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Navigate } from 'react-router-dom';

//
import { getUserToken, getUserRole } from '../data/userData';
import Blog from '../pages/Blog';
import Employees from '../pages/Employee/Employees';
import NewEmployees from '../pages/Employee/addEmployee';
import EditEmployees from '../pages/Employee/editEmployee';
import FullEmpDetails from '../pages/Employee/fullView';
import ViewEmployees from '../pages/Employee/singleEmployeDetais';
import EmployeeSalary from '../pages/Employee/salary';
import Products from '../pages/Products';
import DashboardApp from '../pages/Dashboard';

import ReimburseCost from '../pages/Salary/reimburseCost';
import GetAllReimburse from '../pages/Salary/getallReimburse';
import GetReimburse from '../pages/Salary/getReimburse';
import Certificate from '../pages/Salary/certificate';
import Payroll from '../pages/Salary/payroll';
import Salary from '../pages/Salary/index';
import Asset from '../pages/asset/asset';
import AddAsset from '../pages/asset/addAsset';
import SingleAsset from '../pages/asset/singleAsset';
import News from '../pages/news/news';
import AddNews from '../pages/news/addNews';
import ReadMoreNews from '../pages/news/readMoreNews';
import CompanyDetails from '../pages/companyDetails/companyDetails';
import AddCompanyDetails from '../pages/companyDetails/addCompanyDetails';
import Settings from '../pages/settings/settings';
import GetAllHolidays from '../pages/settings/getAllHolidays';
import NewHoliday from '../pages/settings/addHoliday';
import GetAllCompany from '../pages/settings/getAllCompany';
// import SingleCompany from '../pages/settings/editCompany';
import GetAllLocations from '../pages/settings/getAllLocations';
import ReadMoreDetails from '../pages/companyDetails/readMoreDetails';
// import SingleLocation from '../pages/settings/editLocation';
import AddCompany from '../pages/settings/addCompany';
import AddLocation from '../pages/settings/addLocation';
import GetAllDepartment from '../pages/settings/getAlldepartment';
import AddDepartment from '../pages/settings/addDepartment';
import GetAllOfficeTimings from '../pages/settings/getAllOfficeTimings';
import AddOfficeTiming from '../pages/settings/addOfficeTiming';

// Leave Section & Attendance
import AttendanceOverview from '../pages/Attendance/index';
import LeaveRequest from '../pages/Attendance/LeaveRequests';
import UrAttendance from '../pages/Attendance/yourAttendence';

import FullAttendance from '../pages/Attendance/fullDetails';
import ApplyLeave from '../pages/Attendance/applyLeave';
import AllAttendance from '../pages/Attendance/allAttendance';
import LeaveSingle from '../pages/Attendance/singleLeave';

import PasswordChange from '../pages/Employee/passwordChange';

// IT section
import IT from '../pages/IT/index';
import RequestSingle from '../pages/IT/singleRequest';

// Document section
// import Document from '../pages/Document/index';
import AddDocument from '../pages/Document/addDocument';

// Document Category
import Documents from '../pages/Document/index';
import AddDocumentCategory from '../pages/DocumentCategory/addDocumentCategory';

// Announcements section
import Announcements from '../pages/Announcements/announcement';
import AddAnnouncement from '../pages/Announcements/addAnnouncement';
import ReadMoreAnnouncement from '../pages/Announcements/readMoreAnnouncement';

// Requests section
import Requests from '../pages/Requests/Requests';
import SingleLateAttendance from '../pages/Requests/EditRequest';

export default function Routes() {
  const token = getUserToken();
  // const userData = getUserData()
  const role = getUserRole();

  const loginRoute = [{ path: '*', element: <Navigate to="/login" /> }];

  //   console.log(role, userData, token); http://localhost:3001/dashboard/attendance/ur-attendance

  const commonRoutes = [
    { path: 'app', element: <DashboardApp /> },
    { path: 'employees/fullview/:userId', element: <FullEmpDetails /> },
    { path: 'employees/edit/:userId', element: <EditEmployees /> },
    { path: 'attendance/request', element: <LeaveRequest /> },
    { path: 'attendance/ur-attendance', element: <UrAttendance /> },
    { path: 'leaves/apply', element: <ApplyLeave /> },
    { path: 'leaves/all/:id', element: <AllAttendance /> },
    { path: 'leaves/single/:id', element: <LeaveSingle /> },
    { path: 'leaves/list', element: <EditEmployees /> },
    { path: 'salary/reimburse', element: <ReimburseCost /> },
    { path: 'salary/getallreimburse', element: <GetAllReimburse /> },
    { path: 'salary/reimburse/:id', element: <GetReimburse /> },
    { path: 'salary/certificate', element: <Certificate /> },
    { path: 'salary/payroll', element: <Payroll /> },
    { path: 'salary', element: <Salary /> },
    { path: 'asset', element: <Asset /> },
    { path: 'asset/single/:id', element: <AddAsset /> },
    { path: 'asset/addasset', element: <AddAsset /> },
    { path: 'news', element: <News /> },
    { path: 'news/readMore/:id', element: <ReadMoreNews /> },
    { path: 'companyDetails', element: <CompanyDetails /> },
    { path: 'companyDetails/readMore/:id', element: <ReadMoreDetails /> },
    { path: 'settings', element: <Settings /> },
    { path: 'settings/getallholidays', element: <GetAllHolidays /> },
    { path: 'holiday/addHoliday', element: <NewHoliday /> },
    { path: 'holiday/editHoliday/:id', element: <NewHoliday /> },
    { path: 'location/addlocation', element: <AddLocation /> },
    { path: 'location/editLocation/:id', element: <AddLocation /> },

    { path: 'employees/passwordChange/:userId', element: <PasswordChange /> },
    // IT section
    { path: 'IT', element: <IT /> },

    // Document Category section
    { path: 'documents', element: <Documents /> },

    // Announcements section
    { path: 'announcements', element: <Announcements /> },
    { path: 'announcements/readMore/:id', element: <ReadMoreAnnouncement /> },
  ];

  // Role Wice ROUTES 👇👇⬇️

  const adminRoutes = [
    { path: 'employees', element: <Employees /> },
    { path: 'employees/new', element: <NewEmployees /> },
    { path: 'employees/view/:userId', element: <ViewEmployees /> },
    { path: 'employees/salary/:userId/:salaryId', element: <EmployeeSalary /> },

    // Attendance Section

    { path: 'attendance/overview', element: <AttendanceOverview /> },

    { path: 'leaves/fulldetails', element: <FullAttendance /> },

    { path: 'asset/singleasset/:id', element: <SingleAsset /> },

    { path: 'news/addnews', element: <AddNews /> },
    { path: 'news/editNews/:id', element: <AddNews /> },
    { path: 'companyDetails/addCompanyDetails', element: <AddCompanyDetails /> },
    { path: 'companyDetails/editcompanyDetails/:id', element: <AddCompanyDetails /> },

    { path: 'employees/passwordChange/:userId', element: <PasswordChange /> },

    { path: 'settings/getallcompany', element: <GetAllCompany /> },

    { path: 'settings/getallofficetimings', element: <GetAllOfficeTimings /> },
    { path: 'officetimings/single/:id', element: <AddOfficeTiming /> },
    { path: 'officetimings/new', element: <AddOfficeTiming /> },

    { path: 'company/new', element: <AddCompany /> },
    { path: 'company/single/:id', element: <AddCompany /> },
    { path: 'settings/getalllocation', element: <GetAllLocations /> },

    { path: 'settings/getalldepartment', element: <GetAllDepartment /> },
    { path: 'department/new', element: <AddDepartment /> },
    { path: 'department/single/:id', element: <AddDepartment /> },

    // Document section
    { path: 'document/adddocument', element: <AddDocument /> },

    // Document Category section
    { path: 'documents', element: <Documents /> },
    { path: 'documents/adddocumentcategory', element: <AddDocumentCategory /> },

    // Announcements section
    { path: 'announcements', element: <Announcements /> },
    { path: 'announcements/addannouncement', element: <AddAnnouncement /> },
    { path: 'announcements/editAnnouncement/:id', element: <AddAnnouncement /> },
    { path: 'announcements/readMore/:id', element: <ReadMoreAnnouncement /> },

    // timing section
    { path: 'settings/getallofficetimings', element: <GetAllOfficeTimings /> },
    { path: 'officetimings/single/:id', element: <AddOfficeTiming /> },
    { path: 'officetimings/new', element: <AddOfficeTiming /> },

    // Requests section
    { path: 'requests', element: <Requests /> },
    { path: 'attendance/singlelateattendance/:id', element: <SingleLateAttendance /> },
  ];

  const lineManagerRoutes = [
    { path: 'employees', element: <Employees /> },
    { path: 'employees/new', element: <NewEmployees /> },
    { path: 'employees/view/:userId', element: <ViewEmployees /> },

    // Requests section
    { path: 'requests', element: <Requests /> },
    { path: 'attendance/singlelateattendance/:id', element: <SingleLateAttendance /> },
    { path: 'attendance/overview', element: <AttendanceOverview /> },

    { path: 'leaves/fulldetails', element: <FullAttendance /> },
  ];

  const hrRoutes = [
    { path: 'employees', element: <Employees /> },
    { path: 'employees/new', element: <NewEmployees /> },
    { path: 'employees/salary/:userId/:salaryId', element: <EmployeeSalary /> },
    { path: 'employees/view/:userId', element: <ViewEmployees /> },
    { path: 'attendance/overview', element: <AttendanceOverview /> },

    { path: 'asset/singleasset/:id', element: <SingleAsset /> },

    { path: 'news/addnews', element: <AddNews /> },
    { path: 'news/editNews/:id', element: <AddNews /> },
    { path: 'companyDetails/addCompanyDetails', element: <AddCompanyDetails /> },
    { path: 'companyDetails/editcompanyDetails/:id', element: <AddCompanyDetails /> },

    { path: 'settings/getallcompany', element: <GetAllCompany /> },
    { path: 'settings/getalldepartment', element: <GetAllDepartment /> },
    { path: 'settings/getallofficetimings', element: <GetAllOfficeTimings /> },
    { path: 'officetimings/single/:id', element: <AddOfficeTiming /> },
    { path: 'officetimings/new', element: <AddOfficeTiming /> },
    { path: 'department/new', element: <AddDepartment /> },
    { path: 'department/single/:id', element: <AddDepartment /> },
    { path: 'company/new', element: <AddCompany /> },
    { path: 'company/single/:id', element: <AddCompany /> },
    { path: 'settings/getalllocation', element: <GetAllLocations /> },
    { path: 'location/addLocation', element: <AddLocation /> },
    { path: 'location/editLocation/:id', element: <AddLocation /> },
    { path: 'employees/passwordChange/:userId', element: <PasswordChange /> },

    // Document section
    { path: 'document/adddocument', element: <AddDocument /> },

    // Document Category section
    { path: 'documents/adddocumentcategory', element: <AddDocumentCategory /> },

    // Announcements section
    { path: 'announcements/addannouncement', element: <AddAnnouncement /> },
    { path: 'announcements/editAnnouncement/:id', element: <AddAnnouncement /> },

    // Requests section
    { path: 'requests', element: <Requests /> },
    { path: 'attendance/singlelateattendance/:id', element: <SingleLateAttendance /> },

    // Requests section
    { path: 'requests', element: <Requests /> },
    { path: 'attendance/singlelateattendance/:id', element: <SingleLateAttendance /> },

    { path: 'leaves/fulldetails', element: <FullAttendance /> },
  ];

  const finRoutes = [
    { path: 'employees', element: <Employees /> },
    { path: 'employees/new', element: <NewEmployees /> },
    { path: 'profile/view/:userId', element: <ViewEmployees /> },
    { path: 'asset/singleasset/:id', element: <SingleAsset /> },
  ];

  const employeeRoutes = [{ path: 'profile/view/:userId', element: <ViewEmployees /> }];

  const itRoutes = [
    { path: 'profile/view/:userId', element: <ViewEmployees /> },
    { path: 'profile/edit', element: <EditEmployees /> },

    // IT section
    { path: 'IT/single/:id', element: <RequestSingle /> },
  ];

  const officeAdminRoutes = [
    { path: 'profile/view/:userId', element: <ViewEmployees /> },
    { path: 'attendance/overview', element: <AttendanceOverview /> },

    { path: 'leaves/fulldetails', element: <FullAttendance /> },
  ];

  if (!token) return loginRoute;
  if (role === 'admin') {
    return commonRoutes.concat(adminRoutes);
  }
  if (role === 'hr') {
    return commonRoutes.concat(hrRoutes);
  }
  if (role === 'lineManager') {
    return commonRoutes.concat(lineManagerRoutes);
  }
  if (role === 'finance') {
    return commonRoutes.concat(finRoutes);
  }
  if (role === 'employee') {
    return commonRoutes.concat(employeeRoutes);
  }

  if (role === 'officeAdmin') {
    return commonRoutes.concat(officeAdminRoutes);
  }

  if (role === 'it') {
    return commonRoutes.concat(itRoutes);
  }
  return loginRoute;
}
