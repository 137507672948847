import React, { useState, useEffect, useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  useNavigate,
  Route,
  Link,
  useParams,
  useSearchParams,
  Link as RouterLink,
} from 'react-router-dom';
import {
  Container,
  Typography,
  Stack,
  Button,
  Grid,
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  Tabs,
  Tab,
  CardMedia,
  Item,
  MenuItem,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
// import AttendanceOverview from './attendanceOverview';
import { getReq } from '../../data/ApiReq';
import Scrollbar from '../../components/Scrollbar';
import ReportModal from './componets/reportModal';
import CompanyCardLg from '../../components/companyAttendance';
import { getUserData } from '../../data/userData';

export default function AttendanceOverviewPage() {
  const absentSectionRef = useRef(null);
  const presentSectionRef = useRef(null);

  const getRole = getUserData();
  const role = getRole?.role;

  const today = new Date();
  const initDate = {
    month: today.getMonth(),
    day: today.getDate(),
    year: today.getFullYear(),
    isClicked: false,
  };
  const paginationInit = {
    page: 1,
    limit: 20,
    totalCount: 1,
    totalPages: 1,
    hasNextPage: false,
  };

  const initialState = {
    page: 1,
    limit: 20,
    totalCount: 0,
    totalPages: 0,
    hasNextPage: false,
    searchText: '',
    sort: '',
    qEdit: false,
  };
  const [overviewData, setOverviewData] = useState([]);
  const [todaysOverview, setTodaysOverview] = useState({});
  const [presentDataPagination, setPresentDataPagination] = useState(paginationInit);
  const [absentDataPagination, setAbsentDataPagination] = useState(paginationInit);
  const [absentList, setAbsentList] = useState([]);

  const [attendanceList, setAttendanceList] = useState([]);
  const [selcetedDate, setSelcetedDate] = React.useState(initDate);
  const [filterState, setFilterState] = useState({});

  const formatedDate = `${selcetedDate.month + 1}-${selcetedDate.day}-${selcetedDate.year}`;

  const [customDate, setCustomDate] = React.useState(formatedDate);
  const [showFilter, setShowFilter] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [state, setState] = useState(initialState);

  const handleDateChange = (d) => {
    // console.log('date-->', d);
    const date = new Date(d);

    const formatedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
    getPresentData({ cDate: formatedDate });
    getAbsentData({ cDate: formatedDate });
    const overviewUrl = `year=${date.getFullYear()}&month=${date.getMonth() + 1}&day=${date.getDate()}`;
    getOverviewData(`/attendance/overview/daily?${overviewUrl}`);

    setCustomDate(formatedDate);
  };

  const DateDiv = () => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date"
        value={customDate}
        onChange={handleDateChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );

  const getOverviewData = async (url) => {
    if (!url) url = `/attendance/overview/daily`;
    const overViewRes = await getReq({
      url,
    });
    // console.log('_0-0-0-0 Month Cahnge', overViewRes);

    if (!overViewRes.error) {
      setOverviewData(overViewRes.data.attendanceData);
      setTodaysOverview(overViewRes.data.todaysOverview);
    }
  };

  const getPresentData = async ({ cDate, pg, lm, filter }) => {
    let query = cDate || customDate;

    // console.log('query', query);

    const p = pg || presentDataPagination.page;
    const l = lm || presentDataPagination.limit;

    query = `${query}?page=${p}&limit=${l}`;

    if (filter) {
      query = `${query}?page=${p}&limit=${l}&${filter}`;
    }

    const res = await getReq({ url: `/attendance/present/${query}` });
    // console.log('res', res);
    if (!res.error) {
      setAttendanceList(res.data);
      setPresentDataPagination(res.pagination);
      setState({
        ...state,
        page: res.pagination.page,
        limit: res.pagination.limit,
        totalCount: res.pagination.totalCount,
        totalPages: res.pagination.totalPages,
        hasNextPage: res.pagination.hasNextPage,
        qEdit: false,
      });
    }
  };

  const getAbsentData = async ({ cDate, pg, lm, filter }) => {
    let query = cDate || customDate;

    // console.log('query', query);

    const p = pg || absentDataPagination.page;
    const l = lm || absentDataPagination.limit;

    query = `${query}?page=${p}&limit=${l}`;
    if (filter) {
      query = `${query}?page=${p}&limit=${l}&${filter}`;
    }

    const res = await getReq({ url: `/attendance/absent/${query}` });
    // console.log(res);
    if (!res.error) {
      setAbsentList(res.data);
      setAbsentDataPagination(res.pagination);
      setState({
        ...state,
        page: res.pagination.page,
        limit: res.pagination.limit,
        totalCount: res.pagination.totalCount,
        totalPages: res.pagination.totalPages,
        hasNextPage: res.pagination.hasNextPage,
        qEdit: false,
      });
    }
  };

  const islate = (checkInTime, attendanceStatus, cIn) => {
    if (attendanceStatus === 'notApplicable') {
      return false;
    }

    if (checkInTime > cIn + 10) return true;

    // if (min > 5) return true;

    return false;
  };

  const earlyLeave = (checkOutTime, attendanceStatus, cOut) => {
    if (attendanceStatus === 'notApplicable') {
      return false;
    }

    if (checkOutTime < cOut) return true;
    // const min = date.getMinutes();
    // if (min < 58) return true;

    return false;
  };

  const presentDataPageChange = (event, value) => {
    setPresentDataPagination({
      ...presentDataPagination,
      page: value,
    });
    getPresentData({
      pg: value,
      lm: presentDataPagination.limit,
    });
  };

  const getfilterData = async () => {
    const locRes = await getReq({ url: `/location` });
    if (!locRes.error) {
      setLocationData(locRes.data);
    }

    const comRes = await getReq({ url: `/company` });
    if (!comRes.error) {
      setCompanyData(comRes.data);
    }
  };

  const filterOnChange = (e) => {
    const { name, value } = e.target;
    const [id, nameVal] = value.split('|');
    console.log('name', nameVal, value);
    let query = '';
    if (name === 'location') {
      if (id === 'all') {
        if (filterState.compQ && filterState.compQ !== 'all') {
          query += `&company=${filterState.compQ}`;
        }
        setFilterState({ ...filterState, locQ: 'all', locName: 'all', query });
        getPresentData({
          pg: 1,
          lm: presentDataPagination.limit,
          filter: query,
        });
        getAbsentData({
          pg: 1,
          lm: presentDataPagination.limit,
          filter: query,
        });
        return;
      }
      query = `location=${id}`;
      if (filterState.compQ && filterState.compQ !== 'all') {
        query += `&company=${filterState.compQ}`;
      }
      setFilterState({ ...filterState, locQ: id, locName: nameVal, query });
    } else if (name === 'company') {
      if (id === 'all') {
        if (filterState.locQ && filterState.locQ !== 'all') {
          query += `&location=${filterState.locQ}`;
        }
        setFilterState({ ...filterState, compQ: 'all', compName: 'all', query });
        //  getData({ pg: 1, lm: state.limit, isSearch: filterState.searchText, filter: query });
        getPresentData({
          pg: 1,
          lm: presentDataPagination.limit,
          filter: query,
        });
        getAbsentData({
          pg: 1,
          lm: presentDataPagination.limit,
          filter: query,
        });
        return;
      }
      query = `company=${id}`;
      if (filterState.locQ && filterState.locQ !== 'all') {
        query += `&location=${filterState.locQ}`;
      }
      setFilterState({ ...filterState, compQ: id, compName: nameVal, query });
    }

    // setFilterState({ ...filterState, query });

    //  getData({ pg: 1, lm: state.limit, isSearch: filterState.searchText, filter: query });
    getPresentData({
      pg: 1,
      lm: presentDataPagination.limit,
      filter: query,
    });

    getAbsentData({
      pg: 1,
      lm: presentDataPagination.limit,
      filter: query,
    });
  };

  const FilterSec = () => (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'end'}>
        <DateDiv />
        {role === 'lineManager' ? (
          <></>
        ) : (
          <TextField
            select
            label={'Company'}
            style={{ width: '200px' }}
            onChange={filterOnChange}
            name="company"
            value={filterState.compQ ? `${filterState.compQ}|${filterState.compName}` : 'all|all'}
          >
            <MenuItem value="all|all">
              <em>All</em>
            </MenuItem>
            {companyData.map((option, key) => (
              <MenuItem key={key} value={`${option._id}|${option.companyName}`}>
                {option.companyName}
              </MenuItem>
            ))}
          </TextField>
        )}
        {role === 'lineManager' ? (
          <></>
        ) : (
          <TextField
            select
            label={'Location'}
            style={{ width: '200px' }}
            onChange={filterOnChange}
            name="location"
            value={filterState.locQ ? `${filterState.locQ}|${filterState.locName}` : 'all|all'}
          >
            <MenuItem value="all|all">
              <em>All</em>
            </MenuItem>
            {locationData.map((option, key) => (
              <MenuItem key={key} value={`${option._id}|${option.office}`}>
                {option.office}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Stack>
    </>
  );

  const AttendenceList = () => (
    <div>
      <Box>
        <Container>
          <Stack direction="row" alignItems="center" mb={3} gap={2} justifyContent="space-between">
            <FilterSec />
            <Stack direction="row" alignItems="center" gap={2}>
              {role === 'lineManager' ? (
                <></>
              ) : (
                <Button
                  endIcon={<Iconify icon="bxs:report" />}
                  color="inherit"
                  component={RouterLink}
                  to="/dashboard/leaves/fulldetails"
                  size="large"
                  variant="outlined"
                >
                  Full Data
                </Button>
              )}
              <ReportModal />
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1} ref={presentSectionRef}>
            <Typography variant="h4" gutterBottom>
              Attendance List
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
              {presentDataPagination.totalPages > 1 && (
                <Pagination
                  count={presentDataPagination?.totalPages}
                  color="primary"
                  onChange={presentDataPageChange}
                  page={presentDataPagination?.page}
                />
              )}
            </Stack>
          </Stack>

          <Card style={{ position: 'relative' }}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl.</TableCell>
                      <TableCell>Id</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Check In</TableCell>
                      <TableCell>Check Out</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attendanceList.map((row, index) => (
                      <TableRow
                        hover
                        key={index}
                        // className={totalHoursClassname(row.totalTime, row.attendanceStatus)}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row?.userId?.employeeId}</TableCell>
                        <TableCell>
                          <Link
                            style={{ textDecoration: 'none',color:'black' }}
                            component={RouterLink}
                            to={`/dashboard/employees/view/${row?.userId?._id}`}
                          >
                            {row?.userId?.firstName} {row?.userId?.lastName}
                          </Link>
                          {/* <br />
                          {row.reason} - {row.approvedByManager} */}
                        </TableCell>
                        <TableCell>
                          {row.office?.split(',')[0]}
                          {/* <a
                                 href={`https://www.google.com/maps/@${row.checkInLat},${row.checkInLng},15z`}
                                href={`https://www.openstreetmap.org/#map=19/${row.checkInLat}/${row.checkInLng}&layers=G`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                View
                              </a> */}
                        </TableCell>
                        <TableCell
                          className={
                            islate(row.checkInMins, row.attendanceStatus, row.sheduledInTime) ? 'bg-waring-2' : ''
                          }
                        >
                          {row.checkInLocalTime}
                        </TableCell>
                        <TableCell
                          className={
                            earlyLeave(row.checkOutMins, row.attendanceStatus, row.sheduledOutTime) ? 'bg-waring-2' : ''
                          }
                        >
                          {row.checkOutLocalTime}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Typography variant="h4" gutterBottom m={2}>
                  Absent List
                </Typography>
                <Table ref={absentSectionRef}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl.</TableCell>
                      <TableCell>Id</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Check In</TableCell>
                      <TableCell>Check Out</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {absentList.map((row, index) => (
                      <TableRow hover key={index} className={islate(row.checkIntimeStamp) ? 'bg-waring' : ''}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.employeeId}</TableCell>
                        <TableCell>
                        <Link
                            style={{ textDecoration: 'none',color:'black' }}
                            component={RouterLink}
                            to={`/dashboard/employees/view/${row?._id}`}
                          >
                            {row.firstName} {row.lastName}
                          </Link>
                        </TableCell>
                        <TableCell>Nill</TableCell>
                        <TableCell>Nill</TableCell>
                        <TableCell>Nill</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      </Box>
    </div>
  );

  function AttendanceOverview({ attendanceData, todaysOverview, DateDiv }) {
    // const overAllAttendance = (todaysData?.present / userCount) * 100;

    let attendancePercentage =
      Math.round((todaysOverview?.totalAttendance / todaysOverview?.totalEmployees) * 100 * 10) / 10;

    if (Number.isNaN(attendancePercentage)) {
      attendancePercentage = 0;
    }

    return (
      <>
        <Container>
          <Grid container spacing={3} mb={5} justifyContent="center" alignItems={'center'}>
            <Grid item xs={12}>
              <Grid container spacing={3} mb={1} justifyContent="center" alignItems={'center'}>
                {attendanceData.map((item, key) => (
                  <Grid item xs={12} md={6} key={key}>
                    <CompanyCardLg item={item} key={key} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} alignItems="center" justifyContent={'center'}>
              <Stack
                // spacing={1}
                direction={{
                  xs: 'column',
                  sm: 'row',
                }}
              >
                {/* <Stack spacing={1}>{DateDiv}</Stack> */}

                <div className="card-dx-title-wrap  bg-gray-gr">
                  <h4 className="card-dx-sub-title">Overall </h4>
                  <h2 className="card-dx-title">{attendancePercentage}% </h2>
                </div>

                <button
                  type="button"
                  className="card-dx-title-wrap  bg-green-gr"
                  style={{ border: 'none', cursor: 'pointer' }}
                  onClick={() => presentSectionRef.current.scrollIntoView({ behavior: 'smooth' })}
                >
                  <h4 className="card-dx-sub-title">Present </h4>
                  <h2 className="card-dx-title"> {todaysOverview?.totalAttendance}</h2>
                </button>

                <button
                  type="button"
                  className="card-dx-title-wrap bg-red-gr"
                  style={{ border: 'none', cursor: 'pointer' }}
                  onClick={() => absentSectionRef.current.scrollIntoView({ behavior: 'smooth' })}
                >
                  <h4 className="card-dx-sub-title ">Absent </h4>
                  <h2 className="card-dx-title"> {todaysOverview?.absence}</h2>
                </button>

                <div className="card-dx-title-wrap  bg-yellow-gr">
                  <h4 className="card-dx-sub-title">Late </h4>
                  <h2 className="card-dx-title"> {todaysOverview?.totalLate}</h2>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }

  useEffect(() => {
    getOverviewData();
    getPresentData({ cDate: customDate });
    getAbsentData({ cDate: customDate });
    getfilterData();
  }, []);

  return (
    <>
      <Page title="Attendance Overview">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              Attendance Overview
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-around" gap={2}>
              <Button
                endIcon={<Iconify icon="material-symbols:calendar-month" />}
                color="primary"
                size="large"
                variant="outlined"
                component={RouterLink}
                to="/dashboard/attendance/ur-attendance"
              >
                Your Attendance
              </Button>
              <Button
                endIcon={<Iconify icon="mdi:document-sign" />}
                color="primary"
                size="large"
                variant="outlined"
                component={RouterLink}
                to="/dashboard/attendance/request"
              >
                Leave Requests
              </Button>
            </Stack>
          </Stack>
          <Stack mt={5} mb={5}>
            {role === 'lineManager' ? (
              <></>
            ) : (
              <AttendanceOverview attendanceData={overviewData} todaysOverview={todaysOverview} />
            )}
            <AttendenceList />
          </Stack>
        </Container>
      </Page>
    </>
  );
}
