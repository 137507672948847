/* eslint-disable import/no-mutable-exports */
const isDev = false;

let API_URL;

if (isDev) {
  API_URL = {
    base_url: 'http://localhost:4000/api',
    socket_url: 'ws://localhost:4000',
    img_url: 'http://localhost:4000',
    AWS_Bucket_url: 'https://ninjaresourcesfiles.s3.ap-south-1.amazonaws.com',
  };
} else {
  API_URL = {
    base_url: 'https://api.origindss.com/api',
    socket_url: 'wss://api.origindss.com',
    img_url: 'https://api.origindss.com',
    AWS_Bucket_url: 'https://ninjaresourcesfiles.s3.ap-south-1.amazonaws.com',
  };
}

export default API_URL;
