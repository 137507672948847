import { Link as RouterLink } from 'react-router-dom';
import { useState, useEffect } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Iconify from '../../components/Iconify';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getReq } from '../../data/ApiReq';
import Loading from '../../components/loading'


export default function Holidays() {
  const [empList, setEmpList] = useState([]);
  const [loading, setLoading] = useState(false);


  const getData = async () => {
    setLoading(true);

    const res = await getReq({ url: '/holiday' });
    if (!res.error) {
      setEmpList(res.data);
    }
    setLoading(false);

  };
// console.log(empList);
  useEffect(() => {
    getData();
  }, []);

  const formateDate = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const time = newDate.toLocaleTimeString();
    return (
      <div>
        {day}-{month}-{year}
      </div>
    );
  };

  if(loading){
    return(
      <Page title="Holidays">
        <Loading/>
      </Page>
    )
  }

  return (
    <Page title="Holidays">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Holidays
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/holiday/addHoliday"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
           Add Holiday
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.</TableCell>
                    {/* <TableCell>Employee ID</TableCell> */}
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {empList.map((row, index) => (
                    <TableRow hover key={index}>
                      <TableCell>{index + 1}</TableCell>
                      {/* <TableCell>{row.employeeId}</TableCell> */}
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>{formateDate(row.date)}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          component={RouterLink}
                          to={`/dashboard/holiday/editHoliday/${row._id}?holiday=${row.title}&description=${row.description}&date=${row.date}`}
                          startIcon={<Iconify icon="carbon:view" />}
                          color="warning"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
